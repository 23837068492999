import React, { Component } from 'react'
import SenseiCoin from '../common/SenseiCoin'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import { Typography, Grid, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'

class ActivationListElement extends Component {
  state = {
    cancelActivationOpen: false,
    isCanceling: false
  }

  render() {

    const { activation } = this.props

    let value = activation.last_result ? activation.last_result.rakeback_value / 100 : 0

    if (value > 0) {
      if (activation.last_result.discount) {
        value -= (activation.last_result.discount / 100)
      }
    }

    return (
      <Link to={'/activation/' + activation._id} style={{ textDecoration: 'none' }}>
        <Box display={{ xs: 'none', sm: 'block' }} style={{ padding: '8px 0', borderBottom: '1px solid #c7c7c7' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}><Typography style={{ fontFamily: 'Merriweather', fontSize: 16, color: '#4D4D4D' }} variant="caption">{activation.benefit.partner.name}</Typography></Grid>
            <Grid item xs={3}><Typography style={{ color: '#4D4D4D' }} variant="caption">"{activation.identifier_needed_value}"</Typography></Grid>
            <Grid item xs={2}><Typography style={{ color: '#4D4D4D' }} variant="caption">{activation.last_result ? moment(activation.last_result.registry_date).utc().format('MM/YYYY') : 'n/a'} </Typography></Grid>
            <Grid item xs={2}>
              <Typography style={{ fontWeight: 600, color: '#4D4D4D' }} variant="caption">
                <SenseiCoin margin='0 8px 0 0' />
                <NumberFormat
                  value={value}
                  displayType={'text'}
                  decimalScale={2}
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale="true"
                />
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}>
              <Typography style={{ fontWeight: 600, color: '#4D4D4D' }} variant="caption">
                <SenseiCoin margin='0 8px 0 0' />
                <NumberFormat
                  value={activation.totalReceived ? activation.totalReceived / 100 : 0}
                  displayType={'text'}
                  decimalScale={2}
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale="true"
                />
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box display={{ xs: 'block', sm: 'none' }} style={{ padding: '8px 0', borderBottom: '1px solid #c7c7c7' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}><Typography style={{ fontFamily: 'Merriweather', fontSize: 12, fontWeight: '600', color: '#4D4D4D' }} variant="caption">{activation.benefit.partner.name}</Typography></Grid>
            <Grid item xs={3}><Typography style={{ color: '#4D4D4D' }} variant="caption">"{activation.identifier_needed_value}"</Typography></Grid>
            <Grid item xs={2}><Typography style={{ color: '#4D4D4D' }} variant="caption">{activation.last_result ? moment(activation.last_result.registry_date).utc().format('MM/YYYY') : 'n/a'} </Typography></Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              <Typography style={{ fontSize: 15, fontWeight: 600, color: '#4D4D4D' }} variant="caption">
                <SenseiCoin width="11px" margin='0 8px 0 0' />
                <NumberFormat
                  value={value}
                  displayType={'text'}
                  decimalScale={2}
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale="true"
                />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Link>
    )
  }
}


export default ActivationListElement