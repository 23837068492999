import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BottomButton from '../../common/BottomButton'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import { Auth } from 'aws-amplify';
import GreenDivider from '../../common/GreenDivider'
import GreenTitle from '../../common/GreenTitle';
import BasicButton from '../../common/BasicButton';
import { Dialog, CircularProgress } from '@material-ui/core';
import StyledParagraph from '../../common/StyledParagraph'
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles'
import translateCognitoErrorMessage from '../../../utils/translateCognitoErrorMessage';

const styles = theme => ({
  main: {
    color: "#CCC",
    height: '15px'
  },
  label: {
    fontSize: '12px'
  },
  input: {
    fontSize: '15px'
  }
})

class SignInForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    username: PropTypes.string,
  }

  state = {
    username: this.props.username || '',
    password: '',
    emailToResetPassword: '',
    resetPasswordCode: '',
    newPassword: '',
    snackbarOpen: false,
    snackMessage: '',
    forgotPasswordOpen: false,
    insertCodeOpen: false,
    resetSuccessOpen: false,
    forgotPasswordFetching: false,
    horizontal: 'center',
    vertical: 'bottom'
  }

  reset() {
    this.setState((state) => ({
      username: '',
      password: '',
    }))
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  handleSubmit = () => {
    this.props.handleSubmit(this.state, () => this.reset())
  }

  handleSnackBarClose = () => {
    this.setState({snackbarOpen: false})
  }

  handleOpenModalForgotPassword = () => {
    this.setState({forgotPasswordOpen: true})
  }

  handleCloseModalForgotPassword = () => {
    this.setState({forgotPasswordOpen: false})
  }

  handleOpenModalInsertResetPasswordCode = () => {
    this.setState({insertCodeOpen: true})
  }

  handleCloseModalInsertResetPasswordCode = () => {
    this.setState({insertCodeOpen: false})
  }

  handleOpenModalResetSuccess = () => {
    this.setState({resetSuccessOpen: true})
  }

  handleCloseModalResetSuccess = () => {
    this.setState({resetSuccessOpen: false})
  }

  sendResetPasswordCode = () => {
    this.setState({
      forgotPasswordFetching: true
    })

    Auth.forgotPassword(this.state.emailToResetPassword)
    .then((res)=>{
      this.setState({
        forgotPasswordFetching: false
      })
      this.handleCloseModalForgotPassword()
      this.handleOpenModalInsertResetPasswordCode()
    })
    .catch(err=>{
      this.setState({
        forgotPasswordFetching: false,
        snackbarOpen: true,
        snackMessage: err.message
      })
    })
  }

  submitNewPassword = () => {
    this.setState({
      forgotPasswordFetching: true
    })

    Auth.forgotPasswordSubmit(this.state.emailToResetPassword, this.state.resetPasswordCode, this.state.newPassword)
    .then((res)=>{
      this.setState({
        forgotPasswordFetching: false
      })
      this.handleCloseModalInsertResetPasswordCode()
      this.handleOpenModalResetSuccess()
    })
    .catch(err=>{
      this.setState({
        forgotPasswordFetching: false,
        snackbarOpen: true,
        snackMessage: translateCognitoErrorMessage(err)
      })
    })
  }

  render() {
    const { username, password, vertical, horizontal } = this.state
    const { isFetching, classes } = this.props

    return (
      <div>

        <ValidatorForm
          ref="form"
          onSubmit={this.handleSubmit}
        >
          <div style={{padding: '20px'}}>
            <TextValidator
              fullWidth
              label="E-mail"
              onChange={this.handleChange}
              name="username"
              value={username}
              validators={['required']}
              errorMessages={['Preencha esse campo']}
              margin="dense"
              InputProps={{ classes: { root: classes.input } }}
              InputLabelProps={{
                classes: {
                  root: classes.label
                }
              }}
            />
            <TextValidator
              fullWidth
              label="Senha"
              type='password'
              onChange={this.handleChange}
              name="password"
              value={password}
              validators={['required']}
              errorMessages={['Preencha esse campo']}
              margin="dense"
              InputProps={{ classes: { root: classes.input } }}
              InputLabelProps={{
                classes: {
                  root: classes.label
                }
              }}
            />
            <div style={{textDecoration: 'underline', color: '#444', marginTop: '15px', display: 'block', cursor: 'pointer'}} onClick={this.handleOpenModalForgotPassword}><span>Esqueci minha senha</span></div>
          </div>
          <BottomButton onClick={this.handleSubmit}>
            {isFetching && <span><CircularProgress size={20} /></span>} <span>entrar</span>
          </BottomButton>
  
        </ValidatorForm>
        
        {/* MODAIS DE REDEFINIÇÃO DE SENHA */}
        <Dialog open={this.state.forgotPasswordOpen} onClose={this.handleCloseModalForgotPassword} style={{margin: 'auto', width: '400px', padding:'30px'}}>
          <GreenTitle padding="20px 20px 0 20px" textAlign='center'>
              INFORME SEU E-MAIL
            </GreenTitle>
          <GreenDivider marginLeft='auto' marginRight='auto'/>
          <StyledParagraph textAlign="center" padding='0 20px'>Enviaremos um código para o seu e-mail para que você possa redefinir sua senha</StyledParagraph>
          <ValidatorForm
            style={{
              margin: 'auto'
            }}
            ref="form"
            onSubmit={()=>this.sendResetPasswordCode()}
          >
            <TextValidator
            fullWidth
            value={this.state.emailToResetPassword}
            label="E-mail"
            type='text'
            onChange={this.handleChange}
            name="emailToResetPassword"
            validators={['required']}
            errorMessages={['preencha o email']}
            margin="dense"
            InputProps={{ classes: { root: classes.input } }}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
            />
            <BasicButton margin='32px 0' onClick={()=>this.sendResetPasswordCode()}>{this.state.forgotPasswordFetching && <CircularProgress size={20} />}ENVIAR CÓDIGO</BasicButton>
          </ValidatorForm>
        </Dialog>
        <Dialog open={this.state.insertCodeOpen} onClose={this.handleCloseModalInsertResetPasswordCode} style={{margin: 'auto', width: '400px', padding:'30px'}}>
          <GreenTitle padding="20px 20px 0 20px" textAlign='center'>
              INFORME O CÓDIGO
            </GreenTitle>
          <GreenDivider marginLeft='auto' marginRight='auto'/>
          <StyledParagraph textAlign="center" padding='0 20px'>Informe aqui o código que te enviamos no email <strong>{this.state.emailToResetPassword}</strong> e resete sua senha </StyledParagraph>
          <ValidatorForm
            style={{
              margin: 'auto'
            }}
            ref="form"
            onSubmit={()=>this.submitNewPassword()}
          >
            <TextValidator
            fullWidth
            value={this.state.resetPasswordCode}
            label="Código"
            type='text'
            onChange={this.handleChange}
            name="resetPasswordCode"
            validators={['required']}
            errorMessages={['Preencha esse campo']}
            margin="dense"
            InputProps={{ classes: { root: classes.input } }}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
            />
            <TextValidator
            fullWidth
            value={this.state.newPassword}
            label="Nova Senha"
            type='password'
            onChange={this.handleChange}
            name="newPassword"
            validators={['required']}
            errorMessages={['Preencha esse campo']}
            margin="dense"
            InputProps={{ classes: { root: classes.input } }}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
            />
            <BasicButton margin='32px 0' onClick={()=>this.submitNewPassword()}>{this.state.forgotPasswordFetching && <CircularProgress size={20} />}RESETAR SENHA</BasicButton>
          </ValidatorForm>
        </Dialog>
        <Dialog open={this.state.resetSuccessOpen} onClose={this.handleCloseModalResetSuccess} style={{margin: 'auto', width: '400px', padding:'30px'}}>
          <GreenTitle padding="20px 20px 0 20px" textAlign='center'>
              SUA SENHA FOI RESETADA
            </GreenTitle>
          <GreenDivider marginLeft='auto' marginRight='auto'/>
          <StyledParagraph textAlign="center" padding='0 20px'>Já pode fazer login com sua nova senha para acessar o sistema </StyledParagraph>
        </Dialog>
  
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`}
          open={this.state.snackbarOpen}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.snackMessage}</span>}
        />
      </div>
    )
  }
}

export default withStyles(styles)(SignInForm)