import React, { Component } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import SenseiCoin from '../common/SenseiCoin'
import NumberFormat from 'react-number-format';
import AffiliatedActivationListElement from './AffiliatedActivationListElement';

class AffiliatedActivationsList extends Component {

  render() {
    const { docs = [], totalAffiliatedResults } = this.props;

    return (
      <div>
        <Box display={{ xs: 'none', sm: 'block' }}>
          <Grid container spacing={2} alignItems="center" style={{ marginTop: 16, marginBottom: 8, borderBottom: '1px solid #dedede' }}>
            <Grid item xs={3}><Typography variant="caption">Plataforma</Typography></Grid>
            <Grid item xs={1}><Typography variant="caption">Status</Typography></Grid>
            <Grid item xs={2}><Typography variant="caption">Identificador</Typography></Grid>
            <Grid item xs={2}><Typography variant="caption">Data do Último Resultado </Typography></Grid>
            <Grid item xs={2}><Typography variant="caption"><SenseiCoin /> Último Resultado </Typography></Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}><Typography variant="caption"><SenseiCoin /> Resultados Totais
            <br />( {'Total = '}
              <NumberFormat
                value={totalAffiliatedResults / 100}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale="true"
              />
              )
              </Typography></Grid>
          </Grid>
        </Box>
        <Box display={{ xs: 'block', sm: 'none' }}>
          <Grid container spacing={3} alignItems="center" style={{ marginTop: 16, marginBottom: 8, borderBottom: '1px solid #dedede' }}>
            <Grid item xs={3}><Typography variant="caption">Plataforma</Typography></Grid>
            <Grid item xs={2}><Typography variant="caption">Status</Typography></Grid>
            <Grid item xs={3}><Typography variant="caption">Id</Typography></Grid>
            <Grid item xs={2}><Typography variant="caption">Data do Último Resultado </Typography></Grid>
            <Grid item xs={2}><Typography variant="caption"><SenseiCoin /> Valor Último Resultado</Typography></Grid>
          </Grid>
        </Box>

        {
          docs.map(a => {
            return (
              a.status !== "CANCELED" ? <AffiliatedActivationListElement key={a._id} activation={a} /> : null
            )
          })
        }
      </div>
    )
  }
}

export default AffiliatedActivationsList