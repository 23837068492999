import React from 'react'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import {Grid}  from '@material-ui/core'
import { Link } from 'react-router-dom';


const BasicWarning = (props) => {
  return(
    <Link style={{textDecoration:'none'}} to={props.link} onClick={props.onClick ? props.onClick : null}>
      <div style={{background:`${props.background ? props.background : 'orange'}`, borderRadius: 5, padding:16, height:32, marginBottom:32, display: 'flex', alignItems:'center', justifyContent:'center', color:`${props.color ? props.color : 'white'}`}}>
        <Grid container>
          <Grid item xs={2} style={{display:'flex', alignItems:'center'}}>
            <ReportProblemOutlinedIcon/>
          </Grid>
          <Grid item xs={10}>
            <p variant='body1'>{props.children}</p>
          </Grid>
        </Grid>
      </div>
    </Link>
  )
}

export default BasicWarning