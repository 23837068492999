import React from 'react'
import { Typography, Grid, Container } from '@material-ui/core';

export default function WhiteCard(props) {
    return (
            <Container style={{ width: "230px", height: "320px", background: "white", borderRadius: "20px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", marginTop: "32px", marginBottom: "32px", paddingTop: "16px" }}>
                <Grid container alignItems="center" >
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <img src={props.img} alt={props.alt}></img>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ textTransform: 'uppercase', textAlign: 'center', fontSize: "22px", fontWeight: 700, color: "#A30908" }} >{props.action}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ textAlign: 'center', fontSize: "15px", color: "#A30908", marginTop: "16px" }} >{props.text}</Typography>
                    </Grid>
                </Grid>
            </Container>
    );
  }