import { CircularProgress, Grid, Paper, Snackbar, Typography } from '@material-ui/core'
import React, {Component} from 'react'
import BackButton from '../common/BackButton'
import BasicDialog from '../common/BasicDialog'
import BasicPageLayout from '../common/BasicPageLayout'
import BasicTitle from '../common/BasicTitle'
import PurchaseButton from '../common/PurchaseButton'
import QuantitySelector from '../common/QuantitySelector'
import {getProduct} from '../store/actions'
import ConfirmPurchaseDialog from '../store/ConfirmPurchaseDialog'
import {sendPurchaseConfirmationCode, createOrderFetchData} from '../store/actions'
import {connect} from 'react-redux'

class ProductDetails extends Component{
  state={
    product: {},
    purchase_amount: 1,
    productPlatform: null,
    isFetching: false,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center',
    confirmPurchseOpen: false,
    createErrorMessage: null,
    isSending: false,
    isCreating: false,
    orderCreatedOpen: false,
  }

  handleIncreaseAmount = () =>{
    const {purchase_amount} = this.state

    //the maximum to purchase is the quantity available of the product
    if(purchase_amount+1 <= this.state.product.quantity){
      this.setState({
        purchase_amount: purchase_amount+1
      })
    } else {
      alert('Produto não disponível na quantidade desejada')
    }
  }

  handleDecreaseAmount = () =>{
    const {purchase_amount} = this.state

    //only decrease purchase_amount untill it's equal to one
    if(purchase_amount-1 >= 1){
      this.setState({
        purchase_amount: purchase_amount-1
      })
    }
  }
  
  componentDidMount(){
    this.setState({
      isFetching: true
    })

    getProduct(this.props.match.params.id)
    .then(r=>{
      this.setState({
        product: r.data,
        isFetching: false
      })
    })
    .catch(err=>{
      this.setState({
        snackbarOpen: true,
        snackMessage: err.message,
        isFetching: false
      })
    })
  }

  handleOpenConfirmPurchase = () => {

    if(this.state.product.is_financial){
      let platform = this.props.platforms.find(p=>p._id === this.state.product.platform)
     
      this.setState({productPlatform: platform})
    }

    this.setState({isSending: true})
    sendPurchaseConfirmationCode()
    .then(r=>{
      this.setState({
        isSending: false,
        confirmPurchseOpen: true
      })
    })
    .catch(err=>{
      this.setState({
        isSending: false,
        snackbarOpen: true,
        snackMessage: err.message
      })
    })
  }

  handleCloseConfirmPurchase = () => {
    this.setState({
      confirmPurchseOpen: false
    })
  }

  handleCreateOrder = (data) => {
    this.setState({isCreating: true})
    createOrderFetchData(data)
    .then(r=>{
      this.setState({
        isCreating: false,
        confirmPurchseOpen: false,
        orderCreatedOpen: true
      })
    })
    .catch(err=>{
      this.setState({
        isCreating: false,
        createErrorMessage: err.message
      })
    })
  }

  render(){
    const {vertical, horizontal, snackMessage, snackbarOpen, isFetching, product, purchase_amount, createErrorMessage, confirmPurchseOpen, orderCreatedOpen, isCreating, isSending, productPlatform} = this.state

    return(
      <BasicPageLayout selectedMenu='store'>
        {
          isFetching ? (
            <Typography variant='body1'>Carregando...</Typography>
          ) : (
            <div>
              <BasicTitle>Loja do Sensei</BasicTitle>
              <Typography style={{margin: '8px 0'}} variant='body1'>Diversas formas de resgatar suas Sensei Coins</Typography>
              <BackButton/>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={5} md={3}>
                  <Paper elevation={3}>
                    <div style={{background: 'black', width: '100%', height: '140px', borderRadius: '5px 5px 0 0', backgroundImage:`url(${product.image_path})`, backgroundPosition:'center', backgroundSize: 'cover', boxShadow: '0px 2px 3px #ccc' }}>
                    </div>
                    <div style={{padding: '16px 8px 8px'}}>
                      <Grid alignItems='center' container spacing={2}>
                        <Grid item xs={12}>
                          <Typography style={{color: '#444', fontSize: 15, fontWeight: 'bold', marginBottom:8}} variant='body1'>
                            {product.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div>
                    <Typography style={{fontSize:20, color:'#444', fontWeight:'bold', marginBottom:21}} variant='body1'>Descrição:</Typography>
                    <Typography variant='body1'>{product.description}</Typography>
                    <Grid style={{marginTop:'32px'}} container>
                      <Grid item xs={12} md={8} lg={5}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <QuantitySelector handleIncrease={this.handleIncreaseAmount} handleDecrease={this.handleDecreaseAmount} amount={purchase_amount}/>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                          {isSending ? <CircularProgress/> : <PurchaseButton handleClick={this.handleOpenConfirmPurchase} value={product.price/100 * product.price_index}/>}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          )
        }
        <ConfirmPurchaseDialog createErrorMessage={createErrorMessage} open={confirmPurchseOpen} product={product} platform={productPlatform} amount={purchase_amount} isCreating={isCreating} createOrder={this.handleCreateOrder} cancel={this.handleCloseConfirmPurchase}/>
        <BasicDialog open={orderCreatedOpen} severity='success' handleOk={()=>this.props.history.push('/store/my-orders')}>Seu pedido foi criado com sucesso! Em até 24h, te entregaremos sua compra</BasicDialog>
        <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={snackbarOpen}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{snackMessage}</span>}
        />
      </BasicPageLayout>
    )
  }
}

const mapStateToProps = ({platforms, auth})=>{
  return({
    platforms,
    auth
  })
}


export default connect(mapStateToProps)(ProductDetails)