import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  main: {
    backgroundColor: '#3C9A68',
    color: "white",
    fontWeight: 700,
    height: 35,
    fontSize: 12,
    "&:hover": {
      boxShadow: '0px 4px 9px #444',
      backgroundColor: '#3C9A68'
    }
  },
  yellow: {
    backgroundColor: '#deaa0d',
    color: "white",
    fontWeight: 700,
    height: 35,
    fontSize: 12,
    "&:hover": {
      boxShadow: '0px 4px 9px #4444447d',
      backgroundColor: '#deaa0d'
    }
  },
  blue: {
    backgroundColor: '#125E95',
    color: "white",
    fontWeight: 700,
    height: 35,
    fontSize: 12,
    "&:hover": {
      boxShadow: '0px 4px 9px #4444447d',
      backgroundColor: '#125E95'
    }
  },
  gray: {
    backgroundColor: '#ccc',
    color: "black",
    fontWeight: 700,
    height: 35,
    fontSize: 12,
    "&:hover": {
      boxShadow: '0px 4px 9px #4444447d',
      backgroundColor: '#ccc'
    }
  },
  red: {
    backgroundColor: '#d15249',
    color: "white",
    fontWeight: 700,
    height: 35,
    fontSize: 12,
    "&:hover": {
      backgroundColor: '#d15249',
      boxShadow: '0px 4px 9px #4444447d'
    }
  }
})

const BasicButton = ({classes, children, handleClick, type, disabled, styleType, margin, width, noFullWidth}) => {
    return (
      <Button
        onClick={handleClick}
        className={ styleType ? classes[styleType] : classes.main }
        variant="contained"
        disabled={disabled}
        style={{ margin: margin }}
        fullWidth
        type={type ? type : "submit"}
        >
          {children}
      </Button>
    )
}

export default withStyles(styles)(BasicButton);