import React, { Component } from 'react'
import { Dialog, Typography, Grid, Button, CircularProgress } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'


class TokenSignin extends Component {

  state = {
    answer: ''
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  render() {
    const { isOpen, handleClose, handleSubmit, isFetching, user } = this.props
    const { answer } = this.state

    return (
      <Dialog PaperProps={{ style: { padding: 16 } }} open={isOpen} onClose={handleClose}>
        <Typography variant='body1' padding="20px 20px 0 20px" style={{ textAlign: "center", marginBottom: 16 }}>
          Insira seu código do Google Authenticator
        </Typography >
        <Grid container>
          <Grid item xs={12}>
            <ValidatorForm
              ref="form"
              onSubmit={() => handleSubmit(user, answer)}
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    label={'Código'}
                    variant="filled"
                    onChange={this.handleChange}
                    name="answer"
                    value={answer}
                    validators={['required']}
                    errorMessages={['Obrigatório']}
                  />
                </Grid>
                <Grid item xs={12} style={{ padding: '8px 16px' }}>
                    
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    type="submit"
                    disabled={isFetching}
                    style={{
                        backgroundColor: '#3C9A68',
                        color: "white",
                        fontWeight: 700,
                        margin: '8px 0',
                        height: 55,
                        fontSize: 12,
                        "&:hover": {
                          boxShadow: '0px 4px 9px #444',
                          backgroundColor: '#3C9A68'
                        }
                      }}
                  >
                    {isFetching && <CircularProgress size={20} />} Entrar
                  </Button>
                </Grid>
              </Grid>

            </ValidatorForm>

          </Grid>

        </Grid>
      </Dialog>
    )
  }
}

export default TokenSignin
