import React, { Component } from 'react'
import BasicPageLayout from '../common/BasicPageLayout';
import BasicTitle from '../common/BasicTitle';
import {getCategories, getProducts} from '../store/actions';
import {connect} from 'react-redux'
import { Grid, Snackbar, Typography } from '@material-ui/core';
import GreenTitle from '../common/GreenTitle';
import GreenDivider from '../common/GreenDivider';
import ProductElement from '../store/ProductElement';
import { getTransfersAccount } from '../auth/actions'
import SenseiCoin from '../common/SenseiCoin';
import NumberFormat from 'react-number-format';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

class Store extends Component {
  state={
    isFetchingCategories: false,
    isFetchingProducts: false,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center',
  }

  async componentDidMount(){
    this.setState({
      isFetchingCategories: true,
      isFetchingProducts: true
    })

    await this.props.getBalance()

    this.props.getAllCategories()
    .then(r=>{
      this.setState({
        isFetchingCategories: false
      })
    })
    .catch(err=>{
      this.setState({
        isFetchingCategories: false
      })
    })
    
    this.props.getAllProducts()
    .then(r=>{
      this.setState({
        isFetchingProducts: false
      })
    })
    .catch(err=>{
      this.setState({
        isFetchingProducts: false
      })
    })
  }

  render() {
    const {balance} = this.props
    const {vertical, horizontal, snackbarOpen, snackMessage, isFetchingProducts, isFetchingCategories} = this.state
    return (
      <BasicPageLayout selectedMenu='store'>
        {
          isFetchingCategories || isFetchingProducts ? (
            <Typography variant='body1'>Carregando...</Typography>
          ) : (
            <div>
              <BasicTitle>Loja do Sensei</BasicTitle>
              <Typography style={{margin: '8px 0'}} variant='body1'>Diversas formas de resgatar suas Sensei Coins</Typography>
              <div style={{background: '#e9e9e9', padding:'8px', borderRadius: '5px', width:'fit-content', marginBottom:'32px'}}>
                <AccountBalanceWalletIcon style={{color: '#999', verticalAlign:'bottom', marginRight:'16px'}}/>
                <NumberFormat
                  value={balance/100}
                  decimalScale={2}
                  displayType='text'
                  fixedDecimalScale={true}
                  decimalSeparator=','
                  thousandSeparator='.'
                  style={{color:'#1f6f44', fontWeight:'bold'}}
                />
                <SenseiCoin margin='0 0 0 3px'/>
              </div>
              <Grid container>
                {
                  this.props.categories.map(c=>{
                    return(
                      <Grid item xs={12} key={c._id}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <GreenTitle>{c.name}</GreenTitle>
                            <GreenDivider/>
                          </Grid>
                          <Grid item xs={12} style={{marginBottom: 40}}>
                            <Grid container spacing={3}>
                              {
                                this.props.products.map(p => {
                                  if(p.category === c._id){
                                    return(
                                      <ProductElement key={p._id} product={p}/>
                                    )
                                  } else {
                                    return(null)
                                  }
                                })
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </div>
          )
        }
        <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={snackbarOpen}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{snackMessage}</span>}
        />
      </BasicPageLayout>
    )
  }
}

const mapStateToProps = ({ categories, products, balance }) => {
  return {
    categories,
    balance,
    products
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBalance: () => dispatch(getTransfersAccount()),
    getAllCategories: () => dispatch(getCategories()),
    getAllProducts: () => dispatch(getProducts())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Store)