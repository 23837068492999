import React, { Component } from 'react'
import { connect } from 'react-redux'
import BasicPageLayout from '../common/BasicPageLayout'
import { getReferralResultsFromActivation } from '../results/actions'
import { getAffiliatedActivation } from '../benefits/actions'
import Pagination from '@material-ui/lab/Pagination';
import BasicTitle from '../common/BasicTitle'
import BackButton from '../common/BackButton'
import SenseiCoin from '../common/SenseiCoin'
import ReferralResultsList from '../results/ReferralResultsList'
import { Card, Grid, CardContent, Typography, CircularProgress } from '@material-ui/core';
import NumberFormat from 'react-number-format'
import _ from 'lodash'
import ReactGA from 'react-ga';

class AffiliatedActivationReport extends Component {
  state = {
    isFetching: false,
    activation: {},
    results: [],
    registry_date: {},
    isLoadingResults: false,
    totalPages: 1,
    currentPage: 1,
  }

  async componentDidMount() {
    await this.getActivation();
    this.getResults({});

    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  getActivation = async () => {
    this.setState({isFetching: true})
    getAffiliatedActivation(this.props.match.params.id)
    .then(r=>{
      this.setState({
        activation: r.data,
        isFetching: false
      })
    })
    .catch(err=>{
      this.setState({
        snackbarOpen: true,
        snackMessage: err.message
      })
    })
  }

  getResults({ page = 1, limit = 10 }) {
    const { activation, registry_date } = this.state
    let query = {
      activation: activation._id,
    }
    if (!_.isEmpty(registry_date)) {
      query['result.registry_date'] = registry_date
    }
    this.setState({ isLoadingResults: true })

    getReferralResultsFromActivation({ page, limit, ...query })
      .then(res => {
        this.setState({
          results: res.data.docs,
          isLoadingResults: false,
          totalPages: res.data.pages,
          currentPage: Number(res.data.page)
        })
      })
      .catch(err => {
        this.setState({ isLoadingResults: false })
      })
  }

  handleSearchByDate = (registry_date) => {
    this.setState({ registry_date }, () => {
      this.getResults({})
    });
  }

  handlePaginate = (event, value) => {
    this.getResults({ page: value })
  }

  render() {
    const { activation, totalPages, currentPage, results, isLoadingResults, isFetching } = this.state
    return (
      <BasicPageLayout>
        <BackButton />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {
              isFetching && <CircularProgress />
            }
            <BasicTitle>
              Detalhes do Benefício Afiliado
            </BasicTitle>
            {
              activation.benefit && (
                <div>
                  <p><strong>Parceiro:</strong> {activation.benefit.partner.name}</p>
                  <p><strong>Seu {activation.benefit.identifier_needed} no parceiro:</strong> {activation.identifier_needed_value}</p>
                  <p><strong>Descrição do benefício:</strong> {activation.benefit.description}</p>
                </div>
              )
            }
          </Grid>
          
          <Grid container style={{ paddingBottom: 16 }} spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <BasicTitle> Total Recebido</BasicTitle>
                  <Typography variant="h6">
                    <SenseiCoin />

                    <b style={{ marginLeft: 8 }}><NumberFormat
                      value={activation.totalReceived / 100}
                      displayType={'text'}
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale="true"
                    /></b>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={12}>
            <BasicTitle>
              Resultados Lançados
            </BasicTitle>
            {
              isLoadingResults ? (<Typography variant="body1">Carregando...</Typography>) : (
                <ReferralResultsList results={results} />
              )
            }
          </Grid>
          <Grid item xs={12}>
            <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={currentPage} count={totalPages} onChange={this.handlePaginate} />
          </Grid>
        </Grid>

      </BasicPageLayout>
    )
  }
}

const mapStateToProps = ({ affiliatedActivations }) => {
  return {
    affiliatedActivations
  }
}


export default connect(mapStateToProps)(AffiliatedActivationReport);