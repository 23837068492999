import React, { Component } from 'react'
import BasicPageLayout from '../common/BasicPageLayout';
import BasicTitle from '../common/BasicTitle'
import { Typography } from '@material-ui/core';
import ReactGA from 'react-ga';
import VideoModulesShow from '../video_modules/VideoModulesShow';
import { getVideoModule } from '../video_modules/actions'
import { getVideosFetchData } from '../videos/actions';
import { connect } from 'react-redux'


class VideoModule extends Component {
  state = {
    video_module: {},
    no_activations: true
  }
  async componentDidMount() {
    if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } 
    const id = this.props.match.params.id;
    const video_module = await getVideoModule(id)
    console.log({ video_module })
    try {
      await this.props.getVideos({ video_module: video_module._id })
      this.setState({ video_module, no_activations: false })
    } catch (error) {
      this.setState({ no_activations: true })
    }
    
  }
  render() {
    const { video_module, no_activations } = this.state;
    const { videos } = this.props;
    return (
      <BasicPageLayout selectedMenu='senseitv'>
        <BasicTitle margin='0 0 16px'>Escola: {video_module.title}</BasicTitle>
        <Typography variant='body1'>Tenha acesso a conteúdos exclusivos do acervo do Sensei Poker</Typography>
        <VideoModulesShow video_module={video_module} videos={videos} no_activations={no_activations}/>
      </BasicPageLayout>
    )
  }
}


const mapStateToProps = ({ videos }) => {
  return {
    videos
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getVideos: (query) => dispatch(getVideosFetchData(query))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoModule)