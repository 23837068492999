import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import BasicHeader from '../common/BasicHeader';
import {Link} from 'react-router-dom'

const TermsOfUse = () => {
  return(
    <div>
      <div style={{ width: '100%', padding: 8, backgroundColor: '#1a1a1a', boxSizing: 'border-box' }}>
        <Grid container justify="flex-end">
          <Grid item style={{ marginRight: 16 }}>
            <Link to="/login" style={{ color: 'white', textDecoration: 'underline' }}>
              <Typography style={{ fontSize: 13 }}>
                  Entrar / Cadastrar
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </div>
      <BasicHeader/>
      <div style={{padding:'60px 10%', color:'#444'}}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{textAlign:'center'}}>
            <Typography variant='h3'>Termos de Uso</Typography>
            <Typography variant='h5'>Sensei Poker</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vestibulum mi non ligula luctus molestie. Etiam quis dignissim velit. Sed sagittis vulputate lacus, vel malesuada libero euismod cursus. Quisque mollis arcu nibh, eu sodales eros congue non. Vestibulum in mattis est, id elementum erat. Nulla eget mauris sit amet lorem fringilla pulvinar a at massa. Praesent eros quam, consectetur ut ante at, semper volutpat ex. Duis id dapibus elit. Sed varius ex vitae turpis aliquet sollicitudin et luctus nisi. Vestibulum tellus nulla, lobortis non ultrices quis, sodales nec eros. In ut nisi vel magna tincidunt hendrerit. Duis rhoncus odio est, ut mollis ligula congue a. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas scelerisque ornare dui hendrerit convallis.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vestibulum mi non ligula luctus molestie. Etiam quis dignissim velit. Sed sagittis vulputate lacus, vel malesuada libero euismod cursus. Quisque mollis arcu nibh, eu sodales eros congue non. Vestibulum in mattis est, id elementum erat. Nulla eget mauris sit amet lorem fringilla pulvinar a at massa. Praesent eros quam, consectetur ut ante at, semper volutpat ex. Duis id dapibus elit. Sed varius ex vitae turpis aliquet sollicitudin et luctus nisi. Vestibulum tellus nulla, lobortis non ultrices quis, sodales nec eros. In ut nisi vel magna tincidunt hendrerit. Duis rhoncus odio est, ut mollis ligula congue a. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas scelerisque ornare dui hendrerit convallis.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vestibulum mi non ligula luctus molestie. Etiam quis dignissim velit. Sed sagittis vulputate lacus, vel malesuada libero euismod cursus. Quisque mollis arcu nibh, eu sodales eros congue non. Vestibulum in mattis est, id elementum erat. Nulla eget mauris sit amet lorem fringilla pulvinar a at massa. Praesent eros quam, consectetur ut ante at, semper volutpat ex. Duis id dapibus elit. Sed varius ex vitae turpis aliquet sollicitudin et luctus nisi. Vestibulum tellus nulla, lobortis non ultrices quis, sodales nec eros. In ut nisi vel magna tincidunt hendrerit. Duis rhoncus odio est, ut mollis ligula congue a. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas scelerisque ornare dui hendrerit convallis.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default TermsOfUse