import { CircularProgress, Collapse, Grid, Paper, Snackbar, Typography } from '@material-ui/core'
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import QuantitySelector from '../common/QuantitySelector';
import PurchaseButton from '../common/PurchaseButton';
import ConfirmPurchaseDialog from './ConfirmPurchaseDialog';
import {sendPurchaseConfirmationCode, createOrderFetchData} from './actions'
import BasicDialog from '../common/BasicDialog';
import {connect} from 'react-redux'
import { Alert } from '@material-ui/lab';


class ProductElement extends Component {
  state={
    purchase_amount: 1,
    confirmPurchseOpen: false,
    snackbarOpen: false,
    snackMessage: '',
    vertical: 'bottom',
    horizontal: 'center',
    amountError: false,
    createErrorMessage: null,
    productPlatform: null,
    isSending: false,
    isCreating: false,
    orderCreatedOpen: false,
  }

  handleIncreaseAmount = () =>{
    const {purchase_amount} = this.state

    //the maximum to purchase is the quantity available of the product
    if(purchase_amount+1 <= this.props.product.quantity){
      this.setState({
        purchase_amount: purchase_amount+1
      })
    } else {
      this.setState({amountError: true})
    }
  }

  handleDecreaseAmount = () =>{
    const {purchase_amount} = this.state

    this.setState({amountError: false})

    //only decrease purchase_amount untill it's equal to one
    if(purchase_amount-1 >= 1){
      this.setState({
        purchase_amount: purchase_amount-1
      })
    }
  }

  handleOpenConfirmPurchase = () => {
    if(this.props.product.is_financial){
      let platform = this.props.platforms.find(p=>p._id === this.props.product.platform)
      this.setState({productPlatform: platform})
    }

    this.setState({isSending: true})
    sendPurchaseConfirmationCode()
    .then(r=>{
      this.setState({
        isSending: false,
        confirmPurchseOpen: true
      })
    })
    .catch(err=>{
      this.setState({
        isSending: false,
        snackbarOpen: true,
        snackMessage: err.message
      })
    })
  }

  handleCloseConfirmPurchase = () => {
    this.setState({
      confirmPurchseOpen: false
    })
  }

  handleCreateOrder = (data) => {
    this.setState({isCreating: true})
    createOrderFetchData(data)
    .then(r=>{
      this.setState({
        isCreating: false,
        confirmPurchseOpen: false,
        orderCreatedOpen: true
      })
    })
    .catch(err=>{
      this.setState({
        isCreating: false,
        createErrorMessage: err.message
      })
    })
  }

  render(){
    const {purchase_amount, confirmPurchseOpen, snackbarOpen, snackMessage, vertical, horizontal, isSending, createErrorMessage, isCreating, orderCreatedOpen, productPlatform, amountError} = this.state
    const {product} = this.props

    return(
      <Grid style={{marginBottom: '36px'}} key={product._id} item xs={12} sm={4} md={3}>
        <Collapse in={amountError}>
          <Alert severity='error'>
            Produto não disponível na quantidade desejada (máx = {product.quantity})
          </Alert>
        </Collapse>
        <Paper elevation={3}>
          <Link to={`/store/products/${product._id}`}>
            <div style={{background: 'black', width: '100%', height: '140px', borderRadius: '5px 5px 0 0', backgroundImage:`url(${product.image_path})`, backgroundSize: 'cover', backgroundPosition:'center', boxShadow: '0px 2px 3px #ccc' }}>
            </div>
          </Link>
          <div style={{padding: '16px 8px 8px'}}>
            <Grid alignItems='center' container spacing={2}>
              <Grid item xs={12}>
                <Link style={{textDecoration:'none'}} to={`/store/products/${product._id}`}>
                  <Typography style={{color: '#444', fontSize: 15, fontWeight: 'bold', marginBottom:8}} variant='body1'>
                    {product.name}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <QuantitySelector amount={purchase_amount} handleIncrease={this.handleIncreaseAmount} handleDecrease={this.handleDecreaseAmount} />
              </Grid>
              <Grid item xs={6}>
                {isSending ? <CircularProgress/> : <PurchaseButton handleClick={this.handleOpenConfirmPurchase} value={product.price/100 * product.price_index} />}
              </Grid>
            </Grid>
          </div>
        </Paper>
        <ConfirmPurchaseDialog createErrorMessage={createErrorMessage} open={confirmPurchseOpen} product={product} platform={productPlatform} amount={purchase_amount} isCreating={isCreating} createOrder={this.handleCreateOrder} cancel={this.handleCloseConfirmPurchase}/>
        <BasicDialog open={orderCreatedOpen} severity='success' handleOk={()=>this.props.history.push('/store/my-orders')}>Seu pedido foi criado com sucesso! Em até 24h, te entregaremos sua compra</BasicDialog>
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            key={`${vertical},${horizontal}`}
            open={snackbarOpen}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
          message={<span id="message-id">{snackMessage}</span>}
        />  
      </Grid>
    )
  }
}

const mapStateToProps = ({platforms})=>{
  return({
    platforms
  })
}

export default withRouter(connect(mapStateToProps)(ProductElement))