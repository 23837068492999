import React, { Component } from 'react'
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography, Grid } from '@material-ui/core'

class StatusWidget extends Component {
    
    render() {
        const statusColor =  {
            'PENDING': { 
              color: 'rgb(255 227 24)',
              label: 'Pendente',
              icon: <ErrorIcon fontSize='small' style={{ display: 'inline-block', marginRight: 8 }}/>
            },
            'APPROVED': { 
              color: '#61d295',
              label: 'Aprovado',
              icon: <CheckCircleIcon fontSize='small' style={{ display: 'inline-block', marginRight: 8 }}/>
            },
            'DECLINED': { 
              color: '#fb7684',
              label: 'Recusado',
              icon: <CancelIcon fontSize='small' style={{ display: 'inline-block', marginRight: 8 }}/>
            },
            'DENIED': { 
              color: '#fb7684',
              label: 'Recusado',
              icon: <CancelIcon fontSize='small' style={{ display: 'inline-block', marginRight: 8 }}/>
            },
            'CANCELED': { 
              color: '#ddd',
              label: 'Cancelado',
              icon: <CancelIcon fontSize='small' style={{ display: 'inline-block', marginRight: 8 }}/>
            }
          }

          const { status = 'PENDING' } = this.props;
          const { color , icon, label } = statusColor[status]

          return (
            <div style={{ 
                padding: '4px 8px', 
                background: color, 
                display: 'inline-block', 
                marginTop: 8,
                borderRadius: 5 
            }}>
                <Grid container alignItems="center">
                <Grid item>
                    {icon}
                </Grid>
                <Grid item>
                    <Typography variant='body2' style={{ display: 'inline-block' }}>
                    {label}
                    </Typography>
                </Grid>
                </Grid>
            </div>
          )

          
    }
}

export default StatusWidget
