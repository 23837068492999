import React from 'react'


const StyledParagraph = (props) => {

    return (
      <p style={{fontSize: '15px', padding: props.padding ? props.padding : '20px', letterSpacing: '1px', textAlign: props.textAlign, margin: props.margin}} >
        {props.children}
      </p>
    )

}

export default StyledParagraph;