export const products = (state = [], action) => {
  switch (action.type) {
      case 'GET_PRODUCTS':
      return action.products

      default:
      return state;
  }      
}

export const categories = (state = [], action) => {
  switch (action.type) {
      case 'GET_CATEGORIES':
      return action.categories

      default:
      return state;
  }      
}

export const orders = (state = [], action) => {
  switch (action.type) {
      case 'GET_ORDERS':
      return action.orders

      default:
      return state;
  }      
}

