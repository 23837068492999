const translateCognitoErrorMessage = ({ code, message }) => {
  switch(code){
    case 'CodeMismatchException':
      return 'Código de verificação inválido'
    case 'NotAuthorizedException':
      return 'E-mail ou senha incorretos'
    case 'UsernameExistsException':
      return 'Já existe um usuário utilizando o email fornecido'
    case 'InvalidPasswordException':
      return 'A senha deve conter pelo menos 8 caracteres, letras maiúsculas e minúsculas'
    default:
      return message
  }
}

export default translateCognitoErrorMessage