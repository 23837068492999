import axios from 'axios'
import {routes} from './core/api'
import {Auth} from 'aws-amplify'

export const storeGetProducts = (products) => {
  return {
      type: 'GET_PRODUCTS',
      products
  }
}

export const storeGetCategories = (categories) => {
  return {
      type: 'GET_CATEGORIES',
      categories
  }
}

export const storeGetOrders = (orders) => {
  return {
      type: 'GET_ORDERS',
      orders
  }
}


export const getProducts = (query) => {
  return dispatch => {
    return new Promise((resolve, reject)=>{
      Auth.currentSession()
      .then(session=>{
        return axios.get(
          routes.products,
          { 
            headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
            params:  query,
          })
          .then(res=>{
            dispatch(storeGetProducts(res.data))
            resolve(res)
        })
        .catch(err=>{
          reject(err)
        })
      })
      .catch(err=>{
        reject(err)
      })
    })
  }
}

export const getProduct = (id) => {
  return new Promise((resolve, reject)=>{
    Auth.currentSession()
    .then(session=>{
      return axios.get(
        routes.products+'/'+id,
        { 
          headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
        })
        .then(res=>{
          resolve(res)
      })
      .catch(err=>{
        reject(err)
      })
    })
    .catch(err=>{
      reject(err)
    })
  })
}

export const getCategories = (query) => {
  return dispatch => {
    return new Promise((resolve, reject)=>{
      Auth.currentSession()
      .then(session=>{
        return axios.get(
          routes.categories,
          { 
            headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
            params:  query,
          })
          .then(res=>{
            dispatch(storeGetCategories(res.data))
            resolve(res)
        })
        .catch(err=>{
          reject(err)
        })
      })
      .catch(err=>{
        reject(err)
      })
    })
  }
}

export const createOrderFetchData = (body) => {
  return new Promise((resolve, reject)=>{
      Auth.currentSession()
      .then(session=>{
          return axios.post(
          routes.orders,
          body,
          { 
              headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken, 'AccessToken': session.accessToken.jwtToken},
          })
          .then(res=>{
              resolve(res)
          })
          .catch(err=>{
              reject(err.response ? err.response.data : err)
          })
      })
      .catch(err=>{
          reject(err)
      })
  })
  
}

export const getOrdersFetchData = (query) => {
  return dispatch => {
    return new Promise((resolve, reject)=>{
      Auth.currentSession()
      .then(session=>{
        return axios.get(
          routes.orders,
          { 
            headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
            params:  query,
          })
          .then(res=>{
            dispatch(storeGetOrders(res.data))
            resolve(res)
        })
        .catch(err=>{
          reject(err)
        })
      })
      .catch(err=>{
        reject(err)
      })
    })
  }
}

export const sendPurchaseConfirmationCode = () => {
  return new Promise((resolve, reject)=>{
      Auth.verifyCurrentUserAttribute('email')
      .then(res=>{
          resolve(res)
      })
      .catch(err=>{
          reject(err)
      });
  })
}
