import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory} from 'react-router-dom';

const styles = theme => ({
  main: {
    backgroundColor: '#fff',
    color: "#444",
    fontWeight: 700,
    width: 90,
    height: 35,
    fontSize: 14,
    textTransform: 'uppercase',
    marginTop: '0px',
    marginBottom: '24px',
    paddingLeft: '8px',
    boxShadow: '0px 0px 0px white;',
    "&:hover": {
      boxShadow: '0px 0px 0px white;',
    }
  }
})


const BackButton = ({classes, children, handleClick}) => {

    const router = useHistory();
    if(!handleClick) {
      handleClick = () => { router.goBack() }
    }

    return (
      <Button
        onClick={handleClick}
        className={classes.main}
        variant="contained"
        margin="normal">
          <ArrowBackIcon style={{ marginRight:'8px' }}/>Voltar
      </Button>
    )
}

export default withStyles(styles)(BackButton);