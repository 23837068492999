import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BasicButton from '../../common/BasicButton'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FormControlLabel, Switch, Typography} from '@material-ui/core'
import BasicTitle from '../../common/BasicTitle'
// import {Link} from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import './SignUpForm.css'


class SignUpForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    email: PropTypes.string,
  }

  state = {
    name: '',
    email: this.props.email || '',
    password: '',
    phoneNumber: '',
    older: false,
    agree: false,
  }

  reset() {
    this.setState((state) => ({
      name: '',
      email: this.props.email || '',
      password: '',
      phoneNumber: '',
      older: false
    }))
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    if(field === 'older' || field === 'agree') {
      const checked = event.target.checked
      this.setState({ [field]: checked })
    } else {
      this.setState({ [field]: value })
    }
  }

  handleSubmit = () => {
    const data = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      phoneNumber: '+' + this.state.phoneNumber
    }
    this.props.handleSubmit({...data}, () => this.reset())
  }

  render() {
    const { name, email, password, phoneNumber } = this.state
    const { isFetching } = this.props

    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
      >
        <BasicTitle>Dados Pessoais</BasicTitle>

        <TextValidator
          fullWidth
          label="Nome"
          onChange={this.handleChange}
          name="name"
          value={name}
          validators={['required']}
          errorMessages={['Este campo é obrigatório']}
          margin="normal"
          variant="outlined"
        />
        <TextValidator
          fullWidth
          label="E-mail"
          onChange={this.handleChange}
          name="email"
          value={email}
          validators={['required']}
          errorMessages={['Este campo é obrigatório']}
          margin="normal"
          variant="outlined"
        />
        <PhoneInput
          country={'br'}
          specialLabel='Whatsapp'
          value={phoneNumber}
          onChange={phoneNumber => this.setState({ phoneNumber })}
          fullWidth
          margin="normal"
          variant="outlined"
        />
         
        <TextValidator
          fullWidth
          label="Senha"
          type='password'
          onChange={this.handleChange}
          name="password"
          value={password}
          validators={['required']}
          errorMessages={['Este campo é obrigatório']}
          margin="normal"
          variant="outlined"
        />
        <div id="message" style={{ marginBottom:'12px' }}>
          <Typography variant="caption">Sua senha deve conter pelo menos:<br></br><b>*</b> 8 caracteres<br></br><b>*</b> Letras maiúsculas e minúsculas
          </Typography>
        </div>

        <FormControlLabel
          control={
            <Switch
              checked={Boolean(this.state.older)}
              onChange={this.handleChange}
              name="older"
              color="primary"
            />
          }
        label={<Typography variant="caption">Eu, {this.state.name}, declaro que tenho mais de 18 anos</Typography>}
        />

        {/* <FormControlLabel
          control={
            <Switch
              checked={Boolean(this.state.agree)}
              onChange={this.handleChange}
              name="agree"
              color="primary"
            />
          }
        label={<Typography variant="caption">Eu, {this.state.name}, li e concordo com os <Link className="terms" target='_blank' to='terms-of-use'>Termos de Uso</Link></Typography>}
        /> */}

        <div style={{textAlign:'center', marginTop:'16px'}}>
          <BasicButton 
            style={{margin: 'auto'}}
            fullWidth
            color="primary"
            type="submit"
            disabled={isFetching || !this.state.older}
            margin="normal"
          >
            {isFetching && <CircularProgress size={20} />} Cadastrar
          </BasicButton>
        </div>
      </ValidatorForm>

    )
  }
}

export default SignUpForm