import React, { Component } from "react";
import { Grid, Box } from "@material-ui/core";
import BasicHeader from "../common/BasicHeader";
import FixedMenu from "../common/FixedMenu";
import { connect } from "react-redux";
import BasicWarning from "./BasicWarning";
import ReactWhatsapp from "react-whatsapp";

class BasicPageLayout extends Component {
  render() {
    const { children, selectedMenu, auth } = this.props;
    return (
      <div>
        <BasicHeader selectedMenu={selectedMenu} />
        <Box component="div" display={{ xs: "none", sm: "block" }}>
          <Box
            component="div"
            style={{ width: 220, position: "fixed", height: "100vh" }}
          >
            <FixedMenu selected={selectedMenu} />
          </Box>
          <Box
            component="div"
            style={{
              marginLeft: 220,
              width: "calc(100% - 220px)",
              padding: "80px 16px 16px 16px",
              boxSizing: "border-box",
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                {!auth.email_verified && (
                  <BasicWarning link="/account">
                    Clique aqui e verifique seu email para conseguir fazer
                    resgates
                  </BasicWarning>
                )}
                {localStorage.getItem("firstLogin") === "true" && (
                  <BasicWarning
                    background="#5454de"
                    link="/how-it-works"
                    onClick={() => localStorage.setItem("firstLogin", false)}
                  >
                    Está confuso? Clique aqui para ver como funciona a
                    plataforma de afiliação do Sensei
                  </BasicWarning>
                )}
                {children}
                <ReactWhatsapp
                  number="+55 (61) 99614-5707"
                  message="Olá, gostaria de saber sobre o Sensei Poker"
                  style={{
                    width: "80px",
                    position: "fixed",
                    border: "none",
                    bottom: "10px",
                    right: "10px",
                    backgroundColor: "transparent",
                  }}
                >
                  <img src="whatsapp03.png" alt="" style={{ width: "100%" }} />
                </ReactWhatsapp>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          component="div"
          display={{ xs: "flex", sm: "none" }}
          style={{ padding: 8 }}
        >
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 80 }}>
              {!auth.email_verified && (
                <BasicWarning link="/account">
                  Clique aqui e verifique seu email para conseguir fazer
                  resgates
                </BasicWarning>
              )}
              {localStorage.getItem("firstLogin") === "true" && (
                <BasicWarning
                  background="#5454de"
                  link="/how-it-works"
                  onClick={() => localStorage.setItem("firstLogin", false)}
                >
                  Está confuso? Clique aqui para ver como funciona a plataforma
                  de afiliação do Sensei
                </BasicWarning>
              )}
              {children}
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps)(BasicPageLayout);
