import React, { Component } from 'react'
import { getNegativeResultsFetchData } from './actions'
import { Grid, Card, CardContent, LinearProgress } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { connect } from 'react-redux'
import DateSearch from '../common/DateSearch';
import UserNegativeResultsList from './UserNegativeResultsList';
import moment from 'moment'


class MyNegativeResults extends Component {

    state = {
        registry_date: { startDate: moment().subtract(1, 'month').startOf('month').utc().format('YYYY-MM-DD'), endDate: moment().utc().format('YYYY-MM-DD') },
        isLoading: false
    }

    componentDidMount() {
        this.setState({ isLoading: true })
        this.props.getNegativeResults({ ...this.state.registry_date })
            .then(r => {
                this.setState({ isLoading: false })
            })
            .catch(err => {
                this.setState({ isLoading: false })
            })
    }

    handlePaginate = (event, value) => {
        this.setState({ isLoading: true })

        const { results } = this.props
        const { page } = results
        const { registry_date } = this.state
        const { startDate, endDate } = registry_date
        const query = {
            page: value || page,
            startDate,
            endDate
        }

        this.props.getNegativeResults(query)
            .then(r => {
                this.setState({ isLoading: false })
            })
            .catch(err => {
                this.setState({ isLoading: false })
            })
    }

    handleSearchByDate = (registry_date) => {
        this.setState({ registry_date }, () => {
            this.handlePaginate();
        });
    }

    render() {

        const { negative_results } = this.props
        const { isLoading, registry_date } = this.state
        const { docs = [], pages = 1, page = 1 } = negative_results
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <DateSearch initialDate={registry_date} handleSearch={this.handleSearchByDate} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    {
                                        isLoading ?
                                            <LinearProgress />
                                            :
                                            <UserNegativeResultsList isLoading={isLoading} docs={docs} />
                                    }
                                </Grid>
                                <Grid item style={{ textAlign: 'center' }} xs={12}>
                                    <div>
                                        <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={Number(page)} count={pages} onChange={this.handlePaginate} />
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}


const mapStateToProps = ({ negative_results }) => {
    return {
        negative_results
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNegativeResults: (query) => dispatch(getNegativeResultsFetchData(query))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyNegativeResults)