import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  main: {
    maxWidth: 400,
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  typography:{
    fontFamily: "Roboto"
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: 0,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  text: {
    textAlign: 'center'
  }
})

const BoxLogin = ({classes, children}) => {
  return (
    <div>
      <main className={classes.main}>
        <CssBaseline />
        <Paper elevation={3} className={classes.paper}>
          {children}
        </Paper>
      </main>
    </div>
  )
}

export default withStyles(styles)(BoxLogin)
