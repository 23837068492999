import { combineReducers } from 'redux'
import { auth, balance, signUpInfo, tickets_balance } from '../components/auth/reducers'
import { user, users, me} from '../components/users/reducers'
import { results, negative_results, referral_results } from '../components/results/reducers'
import { videos } from '../components/videos/reducers'
import { video_modules } from '../components/video_modules/reducers'
import { categories, products, orders} from '../components/store/reducers'
import { benefits, activeActivations, nonActiveActivations, affiliatedActivations, affiliatedPaginated } from '../components/benefits/reducers'
import { statement, platforms } from '../components/wallet/reducers'
export default combineReducers({
  auth,
  balance,
  user,
  users,
  benefits,
  me,
  activeActivations,
  nonActiveActivations,
  affiliatedActivations,
  affiliatedPaginated,
  statement,
  platforms,
  categories,
  products,
  orders,
  signUpInfo,
  results,
  negative_results,
  referral_results,
  tickets_balance,
  videos,
  video_modules
})