import React from 'react'


const GreenDivider = (props) => {

    return (
      <img alt="divisoria" style={{width: '120px', height: '3px', marginTop:'10px', marginBottom: '10px', marginLeft: props.marginLeft, marginRight: props.marginRight}} src={process.env.PUBLIC_URL + '/img-divisoria.png'}/>
    )

}

export default GreenDivider;