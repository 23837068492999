export const video_modules = (state = [], action) => {
    switch (action.type) {
        case 'GET_VIDEO_MODULES':
            return action.video_modules
        default:
            return state;
    }
}

export const video_module = (state = {}, action) => {
    switch (action.type) {
        case 'GET_VIDEO_MODULE':
            return action.video_module
        default:
            return state;
    }
}
