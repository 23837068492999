import React, { Component } from 'react'
import BasicPageLayout from '../common/BasicPageLayout';
import BasicTitle from '../common/BasicTitle';
import { Card, Grid, Typography, CircularProgress, Button } from '@material-ui/core';
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import SenseiCoin from '../common/SenseiCoin';
import BasicButton from '../common/BasicButton';
import { Link } from 'react-router-dom';
import { getTransfersAccount, getTicketsBalanceFetchData } from '../auth/actions'
import ReactGA from 'react-ga';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import CloseIcon from '@material-ui/icons/Close';
import AffiliateWidget from '../common/AffiliateWidget';
import DescriptionIcon from '@material-ui/icons/Description';
import TicketIcon from '../common/TicketIcon';

// const prizes = [
//   {
//     qnt: '1x',
//     description: `Buy In para o WSOP 2022`,
//     value: '10.000,00',
//     image: '/buy-in-wsop.jpg'
//   },
//   {
//     qnt: '5x',
//     description: `Avell A55 Mop`,
//     value: '1.400,00',
//     image: '/avell-a55-mob.jpg'
//   },
//   {
//     qnt: '4x',
//     description: `Mesa Genio Desk`,
//     value: '500,00',
//     image: '/genio-desk.jpg'
//   },
//   {
//     qnt: '4x',
//     description: `Cadeira Max Racer Bunker SMI`,
//     value: '500,00',
//     image: '/cadeira-gamer.jpg'
//   },
//   {
//     qnt: '5x',
//     description: `Kit Streamer`,
//     value: '200,00',
//     image: '/kit-streamer.jpg'
//   },
//   {
//     qnt: '5x',
//     value: `100,00`,
//     image: '/100-sensei-coins.jpg'
//   },
//   {
//     qnt: '30x',
//     value: `50,00`,
//     image: '/50-sensei-coins.jpg'
//   },
//   {
//     qnt: '40x',
//     value: '30,00',
//     image: '/30-sensei-coins.jpg'
//   }
// ]


class Raffle extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isFetching: true,
      totalResults: 0,
      doc: false
    }
  }

  async componentDidMount() {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    await this.props.getBalance()
    await this.props.getTicketsBalance()
    this.setState({ isFetching: false })
  }

  render() {
    const { balance, auth, tickets_balance = 0 } = this.props;
    const { isFetching } = this.state;
    return (
      <BasicPageLayout selectedMenu='raffle'>
        <Grid container style={{ paddingBottom: 16, minHeight: '161px' }} spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <Card style={{ padding: 16 }} elevation={3}>
              <Grid container>
                <Grid item xs={6}>
                  <BasicTitle margin='0 0 16px'>Saldo</BasicTitle>
                  <div style={{ textAlign: 'left' }}>
                    {isFetching ?
                      <CircularProgress />
                      :
                      <Typography variant="h6" style={{ marginTop: '30px' }}>
                        <SenseiCoin width='20px' />
                        <b style={{ marginRight: 8, fontSize: '24px', color: '#1f6f44' }}><NumberFormat
                          value={balance / 100}
                          displayType={'text'}
                          decimalScale={2}
                          thousandSeparator="."
                          decimalSeparator=","
                          fixedDecimalScale="true"
                        /></b>
                      </Typography>
                    }
                  </div>
                  <div style={{ marginTop: '18px' }}>
                    <Link to="/store" style={{ textDecoration: 'none' }}><BasicButton margin="16px 0 0 0"> <ShoppingCartOutlinedIcon style={{ width: '20px', marginRight: '8px' }} /> Loja</BasicButton></Link>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <BasicTitle margin='0 0 16px'>Tickets</BasicTitle>
                  <div style={{ textAlign: 'left' }}>
                    {isFetching ?
                      <CircularProgress />
                      :
                      <Typography variant="h6" style={{ marginTop: '30px' }}>
                        <div style={{ display: 'inline-block' }} >
                          <TicketIcon />
                        </div>
                        <div style={{ display: 'inline-block' }}>
                          <b style={{ marginRight: 8, fontSize: '24px', color: '#1f6f44' }}><NumberFormat
                            value={tickets_balance}
                            displayType={'text'}
                            decimalScale={0}
                          /></b>
                        </div>
                      </Typography>
                    }
                  </div>
                  <div style={{ textAlign: 'right', paddingTop: 30 }}>
                    <Button onClick={() => { this.setState(state => ({ docs: !state.docs }))}}><DescriptionIcon /> Como funciona?</Button>
                  </div>

                </Grid>
              </Grid>

            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <AffiliateWidget style={{ minHeight: '161px' }} id={auth.id} />
          </Grid>
        </Grid>


        {
          this.state.docs ? 
          <Grid container>
            <Grid item xs={12} style={{ padding: 16 }}>
              <Button onClick={() => { this.setState(state => ({ docs: !state.docs }))}}><CloseIcon /> Fechar</Button>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 64 }}>
              <div style={{ backgroundImage: "url('/PASSO-1.png')", width: "436px", minHeight: "485px", backgroundPosition: 'bottom', margin: '0 auto', textAlign: 'center' }}>
                <Typography variant="body1" style={{ width: '238px', margin: '0 auto', color: 'white', fontWeight: '600', padding: 20, fontSize: '24px', fontFamily: 'Poppins' }}>
                  Cadastre-se e ative os benefícios
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 64 }}>
              <div style={{ backgroundImage: "url('/PASSO-2.png')", width: "436px", minHeight: "485px", backgroundPosition: 'bottom', margin: '0 auto', textAlign: 'center' }}>
                <Typography variant="body1" style={{ width: '238px', margin: '0 auto', color: 'white', fontWeight: '600', padding: 20, fontSize: '24px', fontFamily: 'Poppins' }}>
                  Jogue e indique amigos para ganhar tickets
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 64 }}>
              <div style={{ backgroundImage: "url('/PASSO-3.png')", width: "436px", minHeight: "485px", backgroundPosition: 'bottom', margin: '0 auto', textAlign: 'center' }}>
                <Typography variant="body1" style={{ width: '238px', margin: '0 auto', color: 'white', fontWeight: '600', padding: 20, fontSize: '24px', fontFamily: 'Poppins' }}>
                  Acumule tickets, participe dos sorteios e resgate seus prêmios
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 64 }}>
              <div style={{ backgroundImage: "url('/PASSO-4.png')", width: "436px", minHeight: "485px", backgroundPosition: 'bottom', margin: '0 auto', textAlign: 'center' }}>
                <Typography variant="body1" style={{ width: '238px', margin: '0 auto', color: 'white', fontWeight: '600', padding: 20, fontSize: '24px', fontFamily: 'Poppins' }}>
                  Caso você não seja sorteado, 30% dos seus tickets acumulam para a próxima temporada
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} style={{ padding: 16 }}>
              <Button onClick={() => { this.setState(state => ({ docs: !state.docs }))}}><CloseIcon /> Fechar</Button>
            </Grid>
          </Grid> : null
        }

        {/* <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <BasicTitle>Prêmios do próximo sorteio</BasicTitle>


                <div style={{ textAlign: 'left', paddingTop: 20 }}>
                  <StyledParagraph padding={'4px'} margin={0}>Prize Pool: <b>$25.200,00</b></StyledParagraph>
                  <StyledParagraph padding={'4px'} margin={0}>Quantidade de premiados: <b></b></StyledParagraph>
                  <StyledParagraph padding={'4px'} margin={0}>Próxima distribuição de tickets: <b>15/04/2022</b></StyledParagraph>
                  <StyledParagraph padding={'4px'} margin={0}>Data do próximo sorteio: <b>30/04/2022</b></StyledParagraph>
                </div>

                <Grid container spacing={2}>
                  {
                    prizes.map(prize => (
                      <Grid key={prize.image} item xs={4}>
                        <Card elevation={2} style={{ marginTop: 10 }}>
                          <CardMedia
                            style={{ minHeight: 300, backgroundSize: 'contain' }}
                            image={prize.image}
                            title={prize.description}
                          />
                          <CardContent>
                            <Grid container>
                              <Grid item xs={3} style={{ textAlign: 'center' }}>
                                <span style={{ fontSize: 28, fontWeight: 500, marginRight: 10, padding: 4 }}>{prize.qnt}</span>
                              </Grid>
                              <Grid item xs={9}>
                                {
                                  prize.description ?
                                  <span style={{ lineHeight: '35px', textAlign: 'center' }}><b>{prize.description}</b> ou <SenseiCoin /> <b>{prize.value}</b></span>
                                  :
                                  <span style={{ lineHeight: '35px', textAlign: 'center' }}><SenseiCoin /> <b>{prize.value}</b></span>
                                }
                              </Grid>
                            </Grid>

                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  }
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid> */}
       
      </BasicPageLayout>
    )
  }
}



const mapStateToProps = ({ balance, auth, tickets_balance }) => {
  return {
    balance,
    tickets_balance,
    auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBalance: () => dispatch(getTransfersAccount()),
    getTicketsBalance: () => dispatch(getTicketsBalanceFetchData()),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Raffle)