


import React, { Component } from 'react'
import { Grid, List, ListItem, ListItemText, Card, CardContent, LinearProgress, Typography } from "@material-ui/core";
import BasicTitle from '../common/BasicTitle';
import BasicButton from '../common/BasicButton';
import { Link } from 'react-router-dom';

class VideoModulesShow extends Component {

    state = {
        title: null,
        code: null
    }

    componentDidMount() {
        const { docs = [] } = this.props.videos
        if (docs.lenght) {
            this.setState({ title: docs[0].title, code: docs[0].code })
        }
    }


    render() {
        const { videos, no_activations } = this.props
        const { title, code } = this.state
        const { docs = [] } = videos
        return (
            <div style={{ marginTop: 32 }}>
                <Grid container>
                    {
                        docs[0] ?
                            <Grid item xs={12} md={4} style={{ paddingRight: 8 }}>
                                <Card>
                                    <CardContent>
                                        <List>
                                            {
                                                docs.map(vid => (<ListItem key={vid._id} style={{ borderBottom: '1px solid #3c9a68b3', borderRadius: '2px' }} button onClick={() => this.setState({ code: vid.code, title: vid.title })}>
                                                    <ListItemText primary={vid.title} />
                                                </ListItem>))
                                            }
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                            : null
                    }
                    <Grid item xs={12} md={docs[0] ? 8 : 12}>
                        <Grid container>
                            {
                                docs[0] ?
                                    <Grid item xs={12}>
                                        <div style={{ margin: "16px 0" }}>
                                            <BasicTitle>{title || docs[0].title}</BasicTitle>
                                        </div>

                                        <div style={{ padding: "56.56% 0 0 0", position: "relative" }}><iframe src={`https://player.vimeo.com/video/${code || docs[0].code}?h=9c5cb4f7af&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{ position: "absolute", top: 0, left: 0, width: '100%', height: '100%' }} title="Kako - Cash Game: Um jogo de Detalhes"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                                    </Grid>
                                    :
                                    <Grid item xs={12}>

                                        { no_activations ? 
                                        <div>
                                            <Typography variant="h5" style={{ marginBottom: 16 }}>Solicite agora a ativação de um benefício para ter acesso ao conteúdo exclusivo!</Typography>
                                            <Link to="/dashboard"><BasicButton> Conhecer plataformas parceiras </BasicButton></Link>
                                        </div>
                                        : <LinearProgress fullWidth />
                                        }
                                    </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default VideoModulesShow