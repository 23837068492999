import React, { Component } from 'react'
import BasicPageLayout from '../common/BasicPageLayout';
import BasicTitle from '../common/BasicTitle'
import ReactPlayer from 'react-player'
import ReactGA from 'react-ga';

class HowItWorks extends Component {
  async componentDidMount() {
    if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } 
  }

  render() {
    localStorage.setItem('firstLogin', false)
    return (
      <BasicPageLayout selectedMenu='how-it-works'>
        <BasicTitle margin='0 0 16px'>Como utilizar o Sensei Club</BasicTitle>
        <ReactPlayer url='https://youtu.be/2Zlb-opC8G8' />
      </BasicPageLayout>
    )
  }
}

export default HowItWorks