export const benefits = (state = [], action) => {
  switch (action.type) {
      case 'GET_BENEFITS':
      return action.benefits
      default:
      return state;
  }      
}

export const activations = (state = [], action) => {
  switch (action.type) {
      case 'GET_ACTIVATIONS':
      return action.activations
      default:
      return state;
  }      
}

export const activeActivations = (state = [], action) => {
  switch (action.type) {
      case 'GET_ACTIVE_ACTIVATIONS':
      return action.activations
      default:
      return state;
  }      
}

export const nonActiveActivations = (state = [], action) => {
  switch (action.type) {
      case 'GET_NONACTIVE_ACTIVATIONS':
      return action.activations
      default:
      return state;
  }      
}

export const affiliatedActivations = (state = [], action) => {
  switch (action.type) {
      case 'GET_AFFILIATED_ACTIVATIONS':
      return action.activations
      default:
      return state;
  }      
}

export const affiliatedPaginated = (state = [], action) => {
  switch (action.type) {
      case 'GET_AFFILIATED_PAGINATED':
      return action.activations
      default:
      return state;
  }      
}
