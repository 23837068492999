import React, { Component } from 'react'
import { Grid, CardHeader, Card, CardContent, CardMedia } from "@material-ui/core"
import { getVideoModulesFetchData } from './actions'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class VideoModules extends Component {

  state = {
    modules: [],
    video_modules: {},
    isLoading: false
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    this.props.getVideoModules()
      .then(r => {
        this.setState({ isLoading: false })
      })
      .catch(err => {
        this.setState({ isLoading: false })
      })
  }

  handlePaginate = (event, value) => {
    this.setState({ isLoading: true })

    const { video_modules } = this.props
    const { page } = video_modules
    const query = {
      page: value || page,
    }

    this.props.getResults(query)
      .then(r => {
        this.setState({ isLoading: false })
      })
      .catch(err => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { video_modules } = this.props

    const { docs = [] } = video_modules

    return (
      <div>
        <Grid container spacing={4} style={{ padding: '16px 0' }}>
          {
            docs.map(mod => (
              <Grid item xs={12} sm={6} md={4}>
                <Link to={`/school/modules/${mod._id}`}>
                  <Card>
                    <CardMedia style={{ width: '100%', height: '150px' }} image={mod.image || `/videothumb.png`} />
                    <CardContent>
                      <CardHeader
                        title={mod.title}
                      />
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))
          }
        </Grid>
      </div>
    )
  }
}


const mapStateToProps = ({ video_modules }) => {
  return {
    video_modules
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getVideoModules: (query) => dispatch(getVideoModulesFetchData(query))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoModules)
