import axios from 'axios'
import { routes } from './core/api'
import { Auth } from 'aws-amplify'

export const storeGetVideoModules = (video_modules) => {
  return {
    type: 'GET_VIDEO_MODULES',
    video_modules
  }
}

export const getVideoModulesFetchData = (query) => {
    console.log({ query })
  return dispatch => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          return axios.get(
            routes.base,
            {
              headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken },
              params: query,
            })
            .then(res => {
              resolve(res.data)
              dispatch(storeGetVideoModules(res.data))
            })
            .catch(err => {
              reject(err)
            })
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export const getVideoModule = (id) => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          return axios.get(
            `${routes.base}/${id}`,
            {
              headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken }
            })
            .then(res => {
              resolve(res.data)
            })
            .catch(err => {
              reject(err)
            })
        })
        .catch(err => {
          reject(err)
        })
    })
  }
