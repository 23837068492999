
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import SenseiCoin from '../common/SenseiCoin'

class StatementList extends Component {

  render() {
    const { docs = [] } = this.props;
    const colors = {
      'C': 'green',
      'D': 'red'
    }
    const operation = {
      'C': '+',
      'D': '-'
    }
    return (
      <div>
        <Grid container spacing={2} alignItems="center" style={{ marginTop: 16, marginBottom: 8, borderBottom: '1px solid #dedede' }}>
          <Grid item xs={3}><Typography variant="caption">Data</Typography></Grid>
          <Grid item xs={2}><Typography variant="caption">Origem</Typography></Grid>
          <Grid item xs={3}><Typography variant="caption">Comentário</Typography></Grid>
          <Grid item style={{ textAlign: 'center' }} xs={2}><Typography variant="caption"><SenseiCoin /> Valor</Typography></Grid>
          <Grid item style={{ textAlign: 'center' }} xs={2}><Typography variant="caption"><SenseiCoin /> Saldo</Typography></Grid>
        </Grid>
        { docs.length ? docs.map(transaction => (
          <div style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }} key={transaction._id}>
            <Grid container spacing={2} justify="space-between" alignItems="center">
              <Grid item xs={3}>
                <Typography variant="body1" style={{ fontSize: 12 }}><b>{moment(transaction.created_at).format('DD/MM/YY')}</b></Typography>
                <Typography variant="body1" style={{ fontSize: 12 }}>{moment(transaction.created_at).format('HH:mm')}</Typography>
              </Grid>
              <Grid item xs={2}>
                <OriginLink transaction={transaction} toggleShow={this.props.toggleShow} />
              </Grid>
              <Grid item xs={3}>
                <Comment transaction={transaction} />
              </Grid>
              <Grid item style={{ textAlign: 'center' }} xs={2}>
                <Typography style={{ fontSize: 16 }} variant="caption">
                  <NumberFormat
                    style={{ color: colors[transaction.type] }}
                    value={transaction.value / 100}
                    displayType={'text'}
                    prefix={`${operation[transaction.type]}`}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                </Typography>
                <SenseiCoin margin="0 0 0 4px" />
              </Grid>
              <Grid item style={{ textAlign: 'center' }} xs={2}>
                <Typography style={{ fontSize: 16 }} variant="caption">
                  <NumberFormat
                    style={{ color: colors[transaction.type] }}
                    value={transaction.new_balance ? transaction.new_balance / 100 : '-'}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale="true"
                  />
                  <SenseiCoin margin="0 0 0 4px" />
                </Typography>
              </Grid>
            </Grid>
          </div>
        )) : <Grid container>
            <Grid item>Não há registros.</Grid>
          </Grid>}
      </div>
    )
  }
}

const OriginLink = ({ transaction, toggleShow }) => {
  const { transfer, sale, purchase } = transaction;
  let text = ''
  // let document = {}
  if (transfer) {
    // document = transfer;
    text = 'TRANSFERÊNCIA'
  }
  if (sale) {
    // document = sale;
    text = 'ENVIO'
  }
  if (purchase) {
    // document = sale;
    text = 'RECEBIMENTO'
  }

  let link = false
  if (transaction.transfer && (transaction.transfer.origin_user._id === process.env.REACT_APP_RAKEBACK_WALLET)) {
    let activation_comment = transaction.transfer.comment.split('-')
    let hasActivation = activation_comment[1] ? true : false
    if(hasActivation) {
      let activation_id = activation_comment[1].replace(' ', '')
      if (activation_id) {
        if (activation_comment[0].includes('afiliação')) {
          link = '/affiliated-activation/' + activation_id
        } else {
          link = '/activation/' + activation_id
        }
      }
    }
  }

  if (link) {
    return (
      <Link to={link} style={{ textDecoration: 'none', width: 'fit-content', display: 'block' }}>
        <div style={{ background: '#3c9a68', padding: 8, borderRadius: 3, width: 'fit-content' }}>
          <Typography variant="body1" style={{ fontSize: 12, fontWeight: '200', color: 'white' }}>{text}</Typography>
        </div>
      </Link>
    )
  } else {
    return (<div style={{ background: '#3c9a68', padding: 8, borderRadius: 3, width: 'fit-content' }}>
      <Typography variant="body1" style={{ fontSize: 12, fontWeight: '200', color: 'white' }}>{text}</Typography>
    </div>)
  }

}

const Comment = ({ transaction, toggleShow }) => {
  const { transfer, sale } = transaction;
  let document = {}
  if (transfer) {
    document = transfer;
  }
  if (sale) {
    document = sale;
  }

  return (
  <div>
    {
      document.comment || document.message ? (
        <Typography variant="caption">{document.comment || document.message}</Typography>
        ):(
        <Typography variant="caption">-</Typography>
      )
    }
  </div>)

}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  }
}


export default withRouter(connect(mapStateToProps)(StatementList))

