import React from 'react'
import MenuList from './MenuList'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  menu: {
    background: '#eaeaea',
    width: '100%',
    height: '100%',
    paddingTop: 60
  }
}));

const FixedMenu = (props) => {
  const classes = useStyles();

  return(
    <div className={classes.menu}>
      <MenuList selected={props.selected}/>
    </div>
  )
}

export default FixedMenu