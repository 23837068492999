import React, { Component } from 'react'
import BasicPageLayout from '../common/BasicPageLayout';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import BasicButton from '../common/BasicButton';
import ReactGA from 'react-ga';

class NotFound extends Component {
  async componentDidMount() {
    if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } 
  }

  render() {
    return (
      <BasicPageLayout>
        <Grid container justify="space-around" alignItems="center" style={{ paddingBottom: 16 }} spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant="h1">404</Typography>
            <Typography variant="h3">Página não encontrada</Typography>
          </Grid>
          <Grid item sm={8} md={5}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <BasicButton>Ir para página inicial</BasicButton>
            </Link>
          </Grid>
        </Grid>
      </BasicPageLayout>
    )
  }
}

export default NotFound