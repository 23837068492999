import React from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Grid, Container, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';

const FrequentQuestions = React.forwardRef((props, ref) => {
  const SupportLink = () => (
    <Grid container alignItems="center" justify="flex-start" style={{color:'blue'}}>
      <Grid item>
        <HelpIcon style={{ fontSize: 16, marginRight: 8 }} /> 
      </Grid>
      <Grid item>
        <Typography variant="caption">Esta resposta não te ajudou? Entre em contato com o Suporte pelo chat no canto inferior direito da página</Typography>
      </Grid>
    </Grid>)
  return (
    <div className="faq" ref={ref}>
          <Container>
            <Grid item xs={12} style={{fontSize: 12, fontWeight: 600}}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={{fontWeight: 600}}><span>1.</span> Como funciona?</Typography>
                </AccordionSummary>
                <AccordionDetails style={{display: 'block'}}>
                  <Typography style={{ marginBottom: 16 }}>
                  Ative seus benefícios, jogue normalmente e receba tickets com base no rake pago para as plataformas. Os tickets serão depositados na sua conta do Clube. Com os tickets você participará de sorteios com + de R$100.000,00 em prêmios.
                  </Typography>
                  {
                    props.dashboard===true && <SupportLink />
                  }
                  <div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography style={{fontWeight: 600}}><span>2.</span> Como ativo meus benefícios?</Typography>
                </AccordionSummary>
                <AccordionDetails style={{display: 'block'}}>
                  <Typography style={{ marginBottom: 16 }}>
                  Escolha um benefício, preencha o formulário e siga o cadastro na plataforma selecionada.
                  </Typography>
                  {
                    props.dashboard===true && <SupportLink />
                  }
                  <div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography style={{fontWeight: 600}}><span>3.</span> Como ganho tickets?</Typography>
                </AccordionSummary>
                <AccordionDetails style={{display: 'block'}}>
                  <Typography style={{ marginBottom: 16 }}>
                  A cada $10 de rake gerado em nossos benefícios ativos você ganha 1 ticket. A cada benefício ativo de um jogador indicado você ganha 1 ticket. A cada 10 tickets gerados pelos seus jogadores indicados você ganha 1 ticket. Fique ligado pois teremos eventos promocionais onde você também podem ganhar tickets.
                  </Typography>
                  {
                    props.dashboard===true && <SupportLink />
                  }
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography style={{fontWeight: 600}}><span>4.</span> Quando recebo meus tickets?</Typography>
                </AccordionSummary>
                <AccordionDetails style={{display: 'block'}}>
                  <Typography style={{ marginBottom: 16 }}>
                  Os tickets são distribuídos 1x por mês de acordo com que as salas nos informam os relatórios com rake gerado de cada jogador. Cada temporada dura 2 meses e os tickets acumulados serão válidos para os sorteios de + de R$100.000,00 em prêmios no fim da temporada.
                  </Typography>
                  {
                    props.dashboard===true && <SupportLink />
                  }
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <Typography style={{fontWeight: 600}}><span>5.</span> Como recebo meu prêmio?</Typography>
                </AccordionSummary>
                <AccordionDetails style={{display: 'block'}}>
                  <Typography style={{ marginBottom: 16 }}>
                  Ao fim de cada temporada ocorrerá os sorteios dos prêmios e caso seu ticket seja sorteado você irá ser notificado e poderá escolher entre o prêmio ou o valor em Sensei Coins depositdo na Sensei Wallet.
                  </Typography>
                  {
                    props.dashboard===true && <SupportLink />
                  }
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <Typography style={{fontWeight: 600}}><span>6.</span> Caso eu não seja sorteado, o que acontece com meus tickets?</Typography>
                </AccordionSummary>
                <AccordionDetails style={{display: 'block'}}>
                  <Typography style={{ marginBottom: 16 }}>
                  Ao fim de cada temporada ocorrerá os sorteios dos prêmios e caso nenhum dos seus tickets seja sorteado, 30% dos seus tickets serão acumulados para a próxima temporada.
                  </Typography>
                  {
                    props.dashboard===true && <SupportLink />
                  }
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel7a-content"
                  id="panel7a-header"
                >
                  <Typography style={{fontWeight: 600}}><span>7.</span> Como recebo a confirmação de que meus benefícios foram ativados?</Typography>
                </AccordionSummary>
                <AccordionDetails style={{display: 'block'}}>
                  <Typography style={{ marginBottom: 16 }}>
                  Você receberá um email de confirmação de cada benefício que for ativado e terá a um dashboard com a listagem de todos os seus benefícios ativos, respectivos status e valores gerados.
                  </Typography>
                  {
                    props.dashboard===true && <SupportLink />
                  }
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel8a-content"
                  id="panel8a-header"
                >
                  <Typography style={{fontWeight: 600}}><span>8.</span> Como funcionam os benefícios com base em Net Gaming Revenue (NGR)?</Typography>
                </AccordionSummary>
                <AccordionDetails style={{display: 'block'}}>
                  <Typography style={{ marginBottom: 16 }}>
                  Isso significa que a plataforma que oferece o benefício paga os rakebacks com base no lucro líquido dela. Então, o rake gerado é o valor que o usuário gerou de pagamentos de rake menos o valor proporcional dos bônus, dos recebimentos e taxas que a atividade deste mesmo usuário gerou como custo para a plataforma.
                  </Typography>
                  {
                    props.dashboard===true && <SupportLink />
                  }
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel9a-content"
                  id="panel9a-header"
                >
                  <Typography style={{fontWeight: 600}}><span>9.</span> Como funcionam os benefícios com base em Gross Gaming Revenue (GGR)?</Typography>
                </AccordionSummary>
                <AccordionDetails style={{display: 'block'}}>
                  <Typography style={{ marginBottom: 16 }}>
                  Isso significa que a plataforma que oferece o benefício paga os rakebacks com base no lucro bruto dela. Então, o rake gerado é o valor que o usuário gerou de pagamentos de rake menos o valor proporcional dos recebimentos que a atividade deste mesmo usuário gerou como custo para a plataforma.
                  </Typography>
                  {
                    props.dashboard===true && <SupportLink />
                  }
                </AccordionDetails>
              </Accordion>

            </Grid>
          </Container>
        </div>
  )
})

export default FrequentQuestions