const API_URL = process.env.REACT_APP_API_URL

export const routes = {
  base: API_URL+'/results',
  fromUser: API_URL+'/results/user',
  totalLastFromUser: API_URL+'/results/total-last-results/user',
  referalFromUser: API_URL+'/referral-results/user',
  totalLastReferalFromUser: API_URL+'/referral-results/total-last-results/user',
  baseNegative: API_URL+'/negative-results',
  baseReferral: API_URL+'/referral-results',
}