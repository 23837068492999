import React, { Component } from 'react'
import BasicPageLayout from '../common/BasicPageLayout';
import BasicTitle from '../common/BasicTitle'
import { getOrdersFetchData } from '../store/actions'
import { Grid, Card, CardContent } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { connect } from 'react-redux'
import DateSearch from '../common/DateSearch';
import OrdersList from '../store/OrdersList';
import OrderStatusSearch from '../common/OrderStatusSearch';
import moment from 'moment'


class Orders extends Component {

  state = {
    created_at: { startDate: moment().subtract(30, 'years').toISOString(), endDate: moment().toISOString() },
    status: 'ALL',
    user: '',
    isLoading: false,
  }

  componentDidMount() {
    this.setState({isLoading: true})
    this.props.getOrders({})
    .then(r=>{
      this.setState({isLoading: false})
    })
    .catch(err=>{
        this.setState({isLoading: false})
    })
  }

  handlePaginate = (event, value) => {
    const {status} = this.state
    const { orders } = this.props
    const { page } = orders
    const { created_at } = this.state
    const { startDate, endDate } = created_at
    const query = {
      page: value || page,
      startDate,
      endDate
    }

    if(status && status !== 'ALL') {
      query.status = status
    }

    console.log({query})
    
    this.props.getOrders(query)
    .then(r=>{
      this.setState({isLoading: false})
    })
    .catch(err=>{
        this.setState({isLoading: false})
    })
  }

  handleSearchByDate = (created_at) => {
    this.setState({created_at}, () => {
        this.handlePaginate();
    });
  }

  handleSearchByStatus = (status) => {
    this.setState({status}, () => {
        this.handlePaginate();
    });
  }

  render() {

    const { orders } = this.props
    const {isLoading} = this.state
    const { docs = [], totalPages = 1, page = 1 } = orders
    return (
      <BasicPageLayout selectedMenu='store_orders'>
        <BasicTitle>Meus pedidos</BasicTitle>

        <Grid container spacing={3} style={{ marginTop: 16 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <DateSearch handleSearch={this.handleSearchByDate}/>
                    </Grid>
                    <Grid item xs={6}>
                      <OrderStatusSearch handleSearch={this.handleSearchByStatus} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <OrdersList isLoading={isLoading} docs={docs}/>
                  </Grid>
                  <Grid item style={{ textAlign: 'center' }} xs={12}>
                    <div>
                      <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={Number(page)} count={totalPages} onChange={this.handlePaginate} />
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        
      </BasicPageLayout>
    )
  }
}


const mapStateToProps = ({ orders }) => {
  return {
    orders
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOrders: (query) => dispatch(getOrdersFetchData(query))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders)