import moment from 'moment';

export const user = (state = {}, action) => {
  switch (action.type) {
    case 'CREATE_USER':
      return action.user
    case 'GET_USER':
      return action.user
    default:
        return state;
  }
}

export const users = (state = [], action) => {
  switch (action.type) {
    case 'GET_USERS':
      return action.users
    default:
      return state;
  }
}

export const balance = (state = 0, action) => {
  switch (action.type) {
    case 'GET_BALANCE':
      return action.balance
    default:
        return state;
  }
}

export const me = (state = {}, action) => {
  switch (action.type) {
    case 'GET_ME':
      return action.me
    default:
        return state;
  }
}

export const filters = (
    state = {  
      platform: 'all',
      user: 'all',
      status: 'all',
      startDate: moment().subtract(7, 'days').toDate(),
      endDate: moment().toDate() 
    }, 
    action
  ) => {
    switch (action.type) {
      case 'GET_FILTERS':
        return action.filters
      default:
          return state;
    }
}