import axios from 'axios'
import { routes } from './core/api'
import { Auth } from 'aws-amplify'

export const storeGetResults = (results) => {
  return {
    type: 'GET_RESULTS',
    results
  }
}

export const storeGetReferralResults = (referral_results) => {
  return {
    type: 'GET_REFERRAL_RESULTS',
    referral_results
  }
}

export const storeGetNegativeResults = (negative_results) => {
  return {
    type: 'GET_NEGATIVE_RESULTS',
    negative_results
  }
}

export const getTotalResultsFromUser = (userId) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        return axios.get(
          routes.fromUser,
          {
            headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken }
          })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getTotalLastResultsFromUser = (userId) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        return axios.get(
          routes.totalLastFromUser,
          {
            headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken }
          })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getTotalLastReferalResultsFromUser = (userId) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        return axios.get(
          routes.totalLastReferalFromUser,
          {
            headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken }
          })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getTotalReferalResultsFromUser = (userId) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        return axios.get(
          routes.referalFromUser,
          {
            headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken }
          })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getNegativeResultsFromActivation = (query) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        return axios.get(
          routes.baseNegative,
          {
            headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken },
            params: query,
          })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getResultsFetchData = (query) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          return axios.get(
            routes.base,
            {
              headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken },
              params: query,
            })
            .then(res => {
              resolve(res.data)
              dispatch(storeGetResults(res.data))
            })
            .catch(err => {
              reject(err)
            })
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export const getNegativeResultsFetchData = (query) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          return axios.get(
            routes.baseNegative,
            {
              headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken },
              params: query,
            })
            .then(res => {
              resolve(res.data)
              dispatch(storeGetNegativeResults(res.data))
            })
            .catch(err => {
              reject(err)
            })
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export const getReferralResultsFetchData = (query) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          return axios.get(
            routes.baseReferral,
            {
              headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken },
              params: query,
            })
            .then(res => {
              resolve(res.data)
              dispatch(storeGetReferralResults(res.data))
            })
            .catch(err => {
              reject(err)
            })
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export const getResultsFromActivation = (query) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        return axios.get(
          routes.base,
          {
            headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken },
            params: query,
          })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getReferralResultsFromActivation = (query) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        return axios.get(
          routes.baseReferral,
          {
            headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken },
            params: query,
          })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
      .catch(err => {
        reject(err)
      })
  })
}