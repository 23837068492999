import React, {Component} from 'react'
import { AppBar, Box, Grid, Button, ButtonGroup, Container } from '@material-ui/core';
import LandingSideMenu from '../common/LandingSideMenu';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-scroll'
import { Link as LinkRouter} from "react-router-dom";



class BasicHeader extends Component{

  state = {
    user: {},
    isUserInfoOpen: false,
    menuOpen: false
  }

  headerStyle = {
    height: 60,
    background: '#fff',
    color: '#18191F',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '2px 2px 8px #9e9e9e'
  }

  logoStyle = {
    marginLeft: '16px',
    width: '120px',
    marginTop: 12
  }

  contentStyle ={
    marginTop: '2px'
  }

  authInfofStyle ={
    background: 'white',
    color: '#444',
    borderRadius: 0
  }

  accordionStyle ={
    borderRadius: 0,
    dropShadow: '2px 2px 2px #444',
    color: '#444'
  }
  
  usernameStyle ={
    fontSize: '12px',
    fontWeight: '700',
    color: '#444',
    marginBottom: '0'
  }

  menuIconStyle={
    color: 'black',
    display: 'block',
    fontSize: '24px',
    height: '100%'
  }

  handleOpenUserInfo() {
    this.setState((state)=>({
      isUserInfoOpen: !this.state.isUserInfoOpen
    }))
  }

  handleCloseMenu = () => {
    this.setState({menuOpen: false})
  }

  handleOpenMenu = () => {
    this.setState({menuOpen: true})
  }

  render() {
    const {selectedMenu} = this.props
    const pathname = window.location.pathname
    const isLanding = pathname === '/'
    
    return(
      <AppBar style={{position: 'fixed', width: '100%', zIndex: '1300'}}>
        <LandingSideMenu selected={selectedMenu} open={this.state.menuOpen} handleCloseMenu={this.handleCloseMenu} />
        <header style={this.headerStyle}>
          <Box component="div" display={{ xs: 'inline-flex', sm: 'none' }} onClick={this.handleOpenMenu}>
           { isLanding ? 
            <div style={{background: 'white', padding: '10px'}}>
              <MenuIcon style={this.menuIconStyle} />
            </div>
            : null }
            <div style={this.contentStyle}>
            <LinkRouter style={{ color: 'white' }}to="/" offset={-60}><img style={this.logoStyle} alt="logo" src={process.env.PUBLIC_URL + '/logo-preta.png'}/></LinkRouter>
            </div>
          </Box>
          <Box component="div" display={{ xs: 'none', sm: 'inline-flex' }} style={{ width: '100%'}}>
            <Container>
              <Grid container alignItems="center" justify="space-between" spacing={6}>
                  <Grid item xs={4} style={{ textAlign: 'left'}}>
                  <LinkRouter style={{ color: 'white' }}to="/" offset={-60}><img style={this.logoStyle} alt="logo" src={process.env.PUBLIC_URL + '/logo-preta.png'}/></LinkRouter>
                  </Grid>
                  <Grid item xs={8} style={{ textAlign: 'right' }}>
                      { isLanding ? 
                      <ButtonGroup style={{ color: '#18191F', marginTop: 8 }} variant="text" >
                      <Button style={{ padding: '8px 25px' }}><Link to="como-funciona" offset={-60}>Como funciona?</Link></Button>
                      <Button style={{ padding: '8px 25px' }}><Link to="benefits" offset={-60}>Benefícios</Link></Button>
                      <Button style={{ padding: '8px 25px' }}><Link to="wallet" offset={-60}>E-Wallet</Link></Button>
                      <Button style={{ padding: '8px 25px' }}><Link to="perguntas-frequentes" offset={-60}>FAQ</Link></Button>
                      <Button style={{ padding: '8px 25px', marginLeft: "24px", background: "linear-gradient(90deg, rgba(94,0,0,1) 0%, rgba(245,32,62,1) 100%)", color: 'white', borderRadius: 50, fontWeight: 600, fontSize: "14px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}><LinkRouter style={{ color: 'white' }}to="/login" offset={-60}>Login</LinkRouter></Button>
                    </ButtonGroup> :
                    null
                    }
                  </Grid>
              </Grid>
            </Container>
          </Box>
        </header>
        
      </AppBar>
    )
  }
}


export default BasicHeader