import React from 'react'
import { Typography, Card, CardMedia, CardContent, CardActions, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import BasicButton from '../common/BasicButton';
import CheckIcon from '@material-ui/icons/Check';
// import SenseiCoin from '../common/SenseiCoin';
// import reactStringReplace from 'react-string-replace';
import { connect } from 'react-redux'
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#eab411',
    color: '#615500'
  },
  button: {
    background: '#286645'
  },
  titleDiv: {
    padding: '16px 16px 0',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: '18px',
    fontFamily: 'merriweather',
    fontWeight: '600'
  },
  descriptionDiv: {
    padding: '16px 16px 0',
    textAlign: 'left'
  },
  description: {
    fontSize: '14px'
  },
  trophyIcon: {
    marginRight: '16px'
  }
}));

const BenefitElement = ({ benefit, handleGetBenefit, auth }) => {

  const classes = useStyles();
  

  return (
    <Card>
      <CardMedia
        style={{ height: 80 }}
        image={benefit.partner_logo}
      />
      <CardContent style={{ minHeight: 180 }}>
        <Typography variant='body1' className={classes.title}>{benefit.partner_name}</Typography>
        <Typography className={classes.description} variant='body1'>
          A <b>cada $10 de rake gerado </b> na {benefit.partner_name} <b>você ganha 1 ticket</b>
          {
            // reactStringReplace(benefit.description, '$C', (match, i) => (
            //   <SenseiCoin key={i} />
            // ))
            
          }
        </Typography>
        {
          benefit.partner_topics && benefit.partner_topics.length > 0 && (
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {
                benefit.partner_topics.map((t, i) => {
                  return (
                    <li key={i} style={{ margin: '8px 0', fontSize: 14 }}><CheckIcon style={{ fontSize: '14px', marginRight: 8, color: '#3c9a68' }} />{t}</li>
                  )
                })
              }
            </ul>
          )
        }
        {
          auth.extra_accounts === '1' && (
            <Grid container alignItems='center' style={{ color: "#444", padding: 8, background: '#efefef', borderRadius: 3 }}>
              <Grid item xs={2}>
                <LinkOutlinedIcon style={{}} />
              </Grid>
              <Grid item xs={10}>
                <Typography style={{ overflowWrap: 'break-word', fontSize: 10 }} variant='body1'>{benefit.link}</Typography>
              </Grid>
            </Grid>
          )
        }
      </CardContent>
      <CardActions>
        <BasicButton type="button"
          handleClick={() => {
            handleGetBenefit(benefit.link, benefit.partner_name, benefit.identifier_needed, benefit._id)
          }}
          styleType='yellow'
        >
          Ativar Benefício
        </BasicButton>
      </CardActions>
    </Card>
  )
}

const mapStateToProps = ({ auth }) => {
  return {
    auth
  }
}

export default connect(mapStateToProps)(BenefitElement)