import React from 'react'


const TrophyIcon = (props) => {

    return (
      <img alt="troféu" style={{width: '40px', margin: props.margin}}src={process.env.PUBLIC_URL + '/ic_trofeu.svg'}/>
    )

}

export default TrophyIcon;