import React, { Component } from 'react'
import BasicPageLayout from '../common/BasicPageLayout';
import UserDetailsForm from '../users/form/UserDetailsForm';
import UserPasswordForm from '../users/form/UserPasswordForm';
import BasicTitle from '../common/BasicTitle'
import ReactGA from 'react-ga';


class Account extends Component {
  componentDidMount() {
    if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } 
  }
  render() {

    return (
      <BasicPageLayout selectedMenu='account'>
        <BasicTitle>Minha conta</BasicTitle>
        <UserDetailsForm />
        <BasicTitle margin='32px 0 0'>Mudar Senha</BasicTitle>
        <UserPasswordForm />
      </BasicPageLayout>
    )
  }
}


export default Account