import { routes } from './core/api';
import {Auth} from 'aws-amplify'
import axios from 'axios'

export const storeGetBenefits = (benefits) => {
    return {
        type: 'GET_BENEFITS',
        benefits
    }
}

export const storeGetActivations = (activations) => {
    return {
        type: 'GET_ACTIVATIONS',
        activations
    }
}

export const storeGetActiveActivations = (activations) => {
    return {
        type: 'GET_ACTIVE_ACTIVATIONS',
        activations
    }
}

export const storeGetNonActiveActivations = (activations) => {
    return {
        type: 'GET_NONACTIVE_ACTIVATIONS',
        activations
    }
}

export const storeGetAffiliatedActivations = (activations) => {
    return {
        type: 'GET_AFFILIATED_ACTIVATIONS',
        activations
    }
}

export const storeGetAffiliatedPaginated = (activations) => {
    return {
        type: 'GET_AFFILIATED_PAGINATED',
        activations
    }
}

export const getBenefits = (query) => {
    return dispatch => {
        return new Promise((resolve, reject)=>{
            return axios.get(
                routes.getBenefits,
                { 
                    headers: { 'Content-Type': 'application/json'},
                    params:  query,
                })
                .then(res=>{
                    dispatch(storeGetBenefits(res.data))
                    resolve(res)
                })
                .catch(err=>{
                    reject(err)
                })
        })
        
    }
}

export const getActiveActivations = (query) => {
    return dispatch => {
        return new Promise((resolve, reject)=>{
        Auth.currentSession()
        .then(session=>{
            return axios.get(
            routes.activations,
            { 
                headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
                params:  {...query, status: "ACTIVE"},
            })
            .then(res=>{
                dispatch(storeGetActiveActivations(res.data))
                resolve(res)
            })
            .catch(err=>{
            reject(err)
            })
        })
        .catch(err=>{
            reject(err)
        })
        })
    }
}

export const getNonActiveActivations = (query) => {
    return dispatch => {
        return new Promise((resolve, reject)=>{
        Auth.currentSession()
        .then(session=>{
            return axios.get(
            routes.activations,
            { 
                headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
                params:  {...query, status: "NONACTIVE"},
            })
            .then(res=>{
                dispatch(storeGetNonActiveActivations(res.data))
                resolve(res)
            })
            .catch(err=>{
            reject(err)
            })
        })
        .catch(err=>{
            reject(err)
        })
        })
    }
}

export const getActivation = (id) => {
    return new Promise((resolve, reject)=>{
    Auth.currentSession()
    .then(session=>{
        return axios.get(
        routes.activations+"/"+id,
        { 
            headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken}
        })
        .then(res=>{
            resolve(res)
        })
        .catch(err=>{
        reject(err)
        })
    })
    .catch(err=>{
        reject(err)
    })
    })
}

export const getAffiliatedActivation = (id) => {
    return new Promise((resolve, reject)=>{
    Auth.currentSession()
    .then(session=>{
        return axios.get(
        routes.activations+"/affiliated/"+id,
        { 
            headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken}
        })
        .then(res=>{
            resolve(res)
        })
        .catch(err=>{
        reject(err)
        })
    })
    .catch(err=>{
        reject(err)
    })
    })
}

export const getAffiliatedActivations = (query) => {
    return dispatch => {
        return new Promise((resolve, reject)=>{
        Auth.currentSession()
        .then(session=>{
            return axios.get(
            routes.activations+'/affiliated',
            { 
                headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
                params:  query,
            })
            .then(res=>{
                dispatch(storeGetAffiliatedActivations(res.data))
                resolve(res)
            })
            .catch(err=>{
            reject(err)
            })
        })
        .catch(err=>{
            reject(err)
        })
        })
    }
}

export const getAffiliatedPaginated = (query) => {
    return dispatch => {
        return new Promise((resolve, reject)=>{
        Auth.currentSession()
        .then(session=>{
            return axios.get(
            routes.activations+'/affiliated-paginated',
            { 
                headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
                params:  query,
            })
            .then(res=>{
                dispatch(storeGetAffiliatedPaginated(res.data))
                resolve(res)
            })
            .catch(err=>{
            reject(err)
            })
        })
        .catch(err=>{
            reject(err)
        })
        })
    }
}

export const createActivation = (body) => {
    return dispatch => {
        return new Promise((resolve, reject)=>{
        Auth.currentSession()
        .then(session=>{
            return axios.post(
            routes.activations,
            body,
            { 
                headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
            })
            .then(res=>{
                resolve(res)
            })
            .catch(err=>{
            reject(err)
            })
        })
        .catch(err=>{
            reject(err)
        })
        })
    }
}

export const updateActivation = (activationId, identifierValue) => {
    return dispatch => {
        return new Promise((resolve, reject)=>{
        Auth.currentSession()
        .then(session=>{
            return axios.put(
            routes.activations+'/'+activationId,
            {identifier_needed_value: identifierValue},
            { 
                headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
            })
            .then(res=>{
                resolve(res)
            })
            .catch(err=>{
            reject(err)
            })
        })
        .catch(err=>{
            reject(err)
        })
        })
    }
}

export const cancelActivation = (activationId) => {
    return new Promise((resolve, reject)=>{
    Auth.currentSession()
    .then(session=>{
        return axios.put(
        routes.activations+'/'+activationId,
        {status: 'CANCELED'},
        { 
            headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
        })
        .then(res=>{
            resolve(res)
        })
        .catch(err=>{
        reject(err)
        })
    })
    .catch(err=>{
        reject(err)
    })
    })
}
