import { Grid, Typography } from '@material-ui/core'
import React, {Component} from 'react'
// import SenseiCoin from './SenseiCoin'
import StyledParagraph from './StyledParagraph'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import TicketIcon from './TicketIcon';

class AffiliateWidget extends Component{
  state={
    copied: false
  }

  handleCopy = () => {
    this.setState({copied: true})
    setTimeout(()=>{
      this.setState({copied: false})
    }, 3000)
  }

  render(){
    let {style} = this.props

    if(!style){
      style = {}
    }

    return(
      <div style={{...style, background: '#f6f6f6', padding:16, textAlign:'center', borderRadius:5, border: '2px solid orange'}}>
          <StyledParagraph padding='0px' > <TicketIcon /> <b>Ganhe 1 ticket</b> a cada jogador indicado que <b>criar conta no Sensei Club e ter um benefício ativo</b></StyledParagraph>
          <StyledParagraph padding='0px' > <TicketIcon /> <b>Ganhe 1 ticket</b> a cada <b>10 tickets gerados pelo jogador indicado</b></StyledParagraph>
          <StyledParagraph padding='0px' >Utilize o link abaixo para indicar:</StyledParagraph>
          <div>
            <CopyToClipboard text={`${process.env.REACT_APP_ENVIRONMENT_URL}?ref=${this.props.id}`} onCopy={this.handleCopy}>
              <div style={{cursor:'pointer', padding: 8, fontSize: 12, background: 'white'}}>
                <Grid container spacing={1} style={{alignItems: 'center'}}>
                  <Grid item xs={1}>
                    <FileCopyOutlinedIcon/>
                  </Grid>
                  <Grid item xs={11}>
                    <span ref={(span) => this.refLink = span}>
                    {process.env.REACT_APP_ENVIRONMENT_URL}?ref={this.props.id}
                    </span>
                  </Grid>
                </Grid>
              </div>
            </CopyToClipboard>
            {this.state.copied && <Typography variant='body1' style={{color:'green', padding:8, borderRadius:3, fontSize:10, marginTop:8}}>Link Copiado!</Typography>}
          </div>
        </div>
    )
  }
}

export default AffiliateWidget