import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import GreenTitle from './GreenTitle';
import StyledParagraph from './StyledParagraph';
import GreenDivider from './GreenDivider';
import BasicButton from './BasicButton';
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  root: {
    padding:'25px',
    boxSizing: 'border-box'
    }
})


const BasicDialog = ({classes, children, handleOk, open, severity}) => {

    return (
      <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      PaperProps={{
        style: {
          padding:'25px',
          boxSizing: 'border-box'
        }}}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      {severity === 'success' ? <GreenTitle>Uhul!</GreenTitle> : <GreenTitle>Oops!</GreenTitle>}
      <GreenDivider/>
      <StyledParagraph>{children}</StyledParagraph>
      <BasicButton handleClick={handleOk}>OK</BasicButton>
      
    </Dialog>
    )

}

export default withStyles(styles)(BasicDialog);