import { routes } from './core/api';
import {Auth} from 'aws-amplify'
import axios from 'axios'


export const getStatement = (statement) => {
    return {
      type: 'GET_STATEMENT',
      statement
    }
  }

export const getWithdrawals = (withdrawals) => {
    return {
      type: 'GET_WITHDRAWALS',
      withdrawals
    }
  }

  export const getDeposits = (deposits) => {
    return {
      type: 'GET_DEPOSITS',
      deposits
    }
  }

export const getPlatforms = (platforms) => {
    return {
      type: 'GET_PLATFORMS',
      platforms
    }
  }

  export const getWithdrawalsFetchData = (query) => {
    return dispatch => {
        return new Promise((resolve, reject)=>{
            Auth.currentSession()
            .then(session=>{
                return axios.get(
                routes.createWithdrawal,
                { 
                    headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
                    params:  query,
                })
                .then(res=>{
                    dispatch(getWithdrawals(res.data))
                    resolve(res)
                })
                .catch(err=>{
                reject(err)
                })
            })
            .catch(err=>{
                reject(err)
            })
        })
    }
    
}

export const getDepositsFetchData = (query) => {
    return dispatch => {
        return new Promise((resolve, reject)=>{
            Auth.currentSession()
            .then(session=>{
                return axios.get(
                routes.createDeposit,
                { 
                    headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
                    params:  query,
                })
                .then(res=>{
                    dispatch(getDeposits(res.data))
                    resolve(res)
                })
                .catch(err=>{
                reject(err)
                })
            })
            .catch(err=>{
                reject(err)
            })
        })
    }
    
}

export const getPlatformsFetchData = (query) => {
    return dispatch => {
        return new Promise((resolve, reject)=>{
            Auth.currentSession()
            .then(session=>{
                return axios.get(
                routes.getPlatforms,
                { 
                    headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
                    params:  query,
                })
                .then(res=>{
                    dispatch(getPlatforms(res.data))
                    resolve(res)
                })
                .catch(err=>{
                reject(err)
                })
            })
            .catch(err=>{
                reject(err)
            })
        })
    }
    
}

export const createWithdrawalFetchData = (verificationCode, withdrawal) => {
    return dispatch => {
        return new Promise((resolve, reject)=>{
            Auth.currentSession()
            .then(session=>{
                return axios.post(
                routes.createWithdrawal,
                { ...withdrawal, value: withdrawal.value * 100, verificationCode },
                { 
                    headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken, 'AccessToken': session.accessToken.jwtToken},
                })
                .then(res=>{
                    resolve(res)
                })
                .catch(err=>{
                    reject(err.response ? err.response.data : err)
                })
            })
            .catch(err=>{
                reject(err)
            })
        })
    }
    
}
export const createDepositFetchData = (deposit) => {
    return dispatch => {
        return new Promise((resolve, reject)=>{
            Auth.currentSession()
            .then(session=>{
                return axios.post(
                routes.createDeposit,
                { ...deposit, value: deposit.value * 100 },
                { 
                    headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken, 'AccessToken': session.accessToken.jwtToken},
                })
                .then(res=>{
                    resolve(res)
                })
                .catch(err=>{
                    reject(err.response ? err.response.data : err)
                })
            })
            .catch(err=>{
                reject(err)
            })
        })
    }
}

export const sendWithdrawalConfirmationCode = () => {
    return new Promise((resolve, reject)=>{
        Auth.verifyCurrentUserAttribute('email')
        .then(res=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        });
    })
}


export const getStatementFetchData = (query) => {
    return dispatch => {
        return new Promise((resolve, reject)=>{
            Auth.currentSession()
            .then(session=>{
                return axios.get(
                routes.getStatement,
                { 
                    headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
                    params:  query,
                })
                .then(res=>{
                    dispatch(getStatement(res.data))
                    resolve(res)
                })
                .catch(err=>{
                reject(err)
                })
            })
            .catch(err=>{
                reject(err)
            })
        })
    }
    
}