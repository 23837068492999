import React, { Component } from 'react'
import { getReferralResultsFetchData } from './actions'
import { Grid, Card, CardContent, LinearProgress } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { connect } from 'react-redux'
import UserReferralResultsList from './UserReferralResultsList';


class MyReferralResults extends Component {

    state = {
        isLoading: false
    }

    componentDidMount() {
        this.setState({ isLoading: true })
        this.props.getReferralResults({ })
            .then(r => {
                this.setState({ isLoading: false })
            })
            .catch(err => {
                this.setState({ isLoading: false })
            })
    }

    handlePaginate = (event, value) => {
        this.setState({ isLoading: true })

        const { referral_results } = this.props
        const { page } = referral_results
        const query = {
            page: value || page
        }

        this.props.getReferralResults(query)
            .then(r => {
                this.setState({ isLoading: false })
            })
            .catch(err => {
                this.setState({ isLoading: false })
            })
    }

    render() {

        const { referral_results } = this.props
        const { isLoading } = this.state
        const { docs = [], pages = 1, page = 1 } = referral_results
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    {
                                        isLoading ?
                                            <LinearProgress />
                                            :
                                            <UserReferralResultsList isLoading={isLoading} docs={docs} />
                                    }
                                </Grid>
                                <Grid item style={{ textAlign: 'center' }} xs={12}>
                                    <div>
                                        <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={Number(page)} count={pages} onChange={this.handlePaginate} />
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}


const mapStateToProps = ({ referral_results }) => {
    return {
        referral_results
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getReferralResults: (query) => dispatch(getReferralResultsFetchData(query))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyReferralResults)