import { CircularProgress, Dialog, Grid, Typography } from '@material-ui/core'
import React, {Component} from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import NumberFormat from 'react-number-format';
import BasicButton from '../common/BasicButton';
import GreenDivider from '../common/GreenDivider'
import GreenTitle from '../common/GreenTitle'
import SenseiCoin from '../common/SenseiCoin';

class ConfirmPurchaseDialog extends Component{
  state={
    validationCode: '',
    identifier: '',
    platform: null
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  render(){
    const { open, amount, product, cancel, createOrder, createErrorMessage, isCreating, platform } = this.props;
    const {validationCode, identifier} = this.state;


    return(
      <Dialog
       open={open}
       onClose={cancel}
       PaperProps={{
        style: {
          padding:'32px',
          boxSizing: 'border-box'
        }}}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <GreenTitle>Confirme a Compra</GreenTitle>
            <GreenDivider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>Produto: <span style={{color:'#999'}}>{amount}x</span> <span style={{fontWeight:'bold'}}>{product.name}</span></Typography>
            <Typography variant='body1'>
              Valor:  
              <NumberFormat
                value={amount * product.price/100 * product.price_index}
                fixedDecimalScale={true}
                decimalScale={2}
                thousandSeparator='.'
                decimalSeparator=','
                displayType='text'
                style={{color:'#1f6f44', fontWeight:'bold', marginLeft:8}}
              />
              <SenseiCoin margin='0 0 0 3px' />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{fontWeight: 'bold'}} variant='body1'>Informe o código de confirmação que enviamos para o seu email e o identificador do resgate</Typography>
          </Grid>
          <Grid item xs={12}>
            <ValidatorForm
              ref="form"
              onSubmit={()=>createOrder({
                verificationCode: validationCode,
                product: product._id,
                quantity: amount,
                identifier
              })}
            >
              <TextValidator
                fullWidth
                value={validationCode}
                label="Código de Confirmação"
                type='text'
                onChange={this.handleChange}
                name="validationCode"
                validators={['required']}
                errorMessages={['Preencha esse campo']}
                margin="dense"/>
              {
                product.is_financial && platform && (
                  <TextValidator
                    fullWidth
                    value={identifier}
                    label={`${platform.identifier} na ${platform.name}`}
                    type='text'
                    onChange={this.handleChange}
                    name="identifier"
                    validators={['required']}
                    errorMessages={['Preencha esse campo']}
                    margin="dense"
                    style={{marginBottom: 16}}
                    />
                )
              }
                {createErrorMessage && <Typography variant='body1' style={{fontSize: 12, color: 'red'}}>{createErrorMessage}</Typography>}
                <BasicButton margin='8px 0 0'>{isCreating && <CircularProgress size={20}/>}Confirmar</BasicButton>
                <BasicButton handleClick={cancel} type='button' styleType='gray' margin='8px 0 0'>Cancelar</BasicButton>
            </ValidatorForm>
          </Grid>
        </Grid>
        
      </Dialog>
    )
  }
}


export default ConfirmPurchaseDialog