export const results = (state = [], action) => {
    switch (action.type) {
        case 'GET_RESULTS':
            return action.results
        default:
            return state;
    }
}


export const referral_results = (state = [], action) => {
    switch (action.type) {
        case 'GET_REFERRAL_RESULTS':
            return action.referral_results
        default:
            return state;
    }
}

export const negative_results = (state = [], action) => {
    switch (action.type) {
        case 'GET_NEGATIVE_RESULTS':
            return action.negative_results
        default:
            return state;
    }
}
