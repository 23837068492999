import React from 'react'
import { Link } from "react-router-dom";


const BasicLink = (props) => {

  return(
    <Link style={{ color:'#444', textDecoration: 'none' }} to={props.to}>{props.children}</Link>
  )
}


export default BasicLink