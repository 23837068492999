import "./landing.css";
import LandingHeader from "../common/LandingHeader";
import WhiteCard from "../common/Card";
import CustomButton from "../common/CustomButton";
import CardSalas from "../common/CardSalas";
import {
  AppBar,
  Typography,
  Button,
  Grid,
  Container,
  Box,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import React, { Component } from "react";
import Faq from "../faq";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
import queryString from "query-string";
import { connect } from "react-redux";
import { setSignUpInfoData } from "../auth/actions";
import { withRouter } from "react-router";
import { getBenefits } from "../benefits/actions";
import ReactWhatsapp from "react-whatsapp";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

class Landing extends Component {
  state = {
    menuOpen: false,
    ref: null,
  };

  componentDidMount() {
    this.props.getAllBenefits({});
    const parsed = queryString.parse(this.props.location.search);

    if (parsed.ref) {
      let ref = parsed.ref.replace("/", "");
      this.setState({ ref: ref });
      this.props.setSignUpInfo({ ref: ref, benefit: null });
    }

    if (Object.keys(this.props.auth).length > 0) {
      this.props.history.push("/dashboard");
    }

    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    if (process.env.REACT_APP_HJID && process.env.REACT_APP_HJSV) {
      hotjar.initialize(process.env.REACT_APP_HJID, process.env.REACT_APP_HJSV);
    }
  }

  render() {
    const { benefits } = this.props;
    return (
      <div className="App landing-page">
        <ThemeProvider theme={theme}>
          <div>
            <AppBar position="static" style={{ backgroundColor: "white" }}>
              <LandingHeader />
            </AppBar>
            <section
              id="club"
              style={{
                background: "url('./bg-vermelho.png')",
                paddingTop: "56px",
                backgroundSize: "cover",
              }}
            >
              <Container style={{ marginBottom: "64px" }}>
                <Grid container alignItems="center" justify="space-between">
                  <Grid item xs={12} md={6}>
                    <img
                      className="logo-club"
                      src="./logo-club.svg"
                      alt="Sensei Club"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography style={{ color: "white", fontSize: "35px", lineHeight: 1, marginBottom: 64, marginTop: 80 }}> + de <span className="prizeText" >R$100.000,00</span> em prêmios</Typography>
                    <Typography style={{ color: "white", fontSize: "20px" }}>
                      Um <b style={{ fontWeight: 600 }}>clube de benefícios</b>{" "}
                      feito para você jogador de poker.
                      <br />
                      Simples, Confiável e <b style={{ fontWeight: 600 }}>Gratuito</b>.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      onClick={() => {
                        this.props.setSignUpInfo({
                          ref: this.state.ref,
                          open: true,
                        });
                        this.props.history.push("/login");
                      }}
                      variant="contained"
                      size="large"
                      style={{
                        marginTop: "32px",
                        background: "white",
                        color: "#A30908",
                        borderRadius: 50,
                        fontWeight: 600,
                        fontSize: "20px",
                      }}
                    >
                      Entre para o clube
                    </Button>
                  </Grid>
                </Grid>
              </Container>
              <div style={{ background: "rgba(0,0,0,0.5)", padding: "32px" }}>
                <Container>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                      <Grid container alignItems="center" justify="center">
                        <Grid item>
                          <img
                            className="icon-footer"
                            src="./cadeado.svg"
                            alt="Cadeado"
                          />
                        </Grid>
                        <Grid item>
                          <p className="text-footer">
                            Lucro com 100% de segurança
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" justify="center">
                        <Grid item>
                          <img
                            className="icon-footer"
                            src="./icone-pessoa.svg"
                            alt="Usuários"
                          />
                        </Grid>
                        <Grid item>
                          <p className="text-footer">+ de 2000 afiliados</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" justify="center">
                        <Grid item>
                          <img
                            className="icon-footer"
                            src="./main-check.svg"
                            alt="Cadeado"
                          />
                        </Grid>
                        <Grid item>
                          <p className="text-footer">Tudo grátis</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </section>
            {/* <section
              id="resultados"
              style={{ background: "url('./bg-white.png')", padding: "56px 0" }}
            >
              <Container>
                <Grid container justify="space-between" alignItems="center">
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ textAlign: "center", paddingBottom: "48px" }}
                  >
                    <img
                      src="./resultados-alavancados.png"
                      alt="Resultados Alavancados"
                      style={{ width: "100%" }}
                    />
                    <Typography
                      style={{
                        color: "black",
                        fontSize: "2em",
                        textTransform: "uppercase",
                      }}
                    >
                      Entre para o clube e aumente seus lucros no poker
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={12} md={6} style={{ marginBottom: "48px" }}>
                    <img
                      src="./macbook2.svg"
                      alt="Resultados alavancados"
                      style={{ width: "90%" }}
                    />
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="center"
                    >
                      <Grid item>
                        <div
                          style={{
                            height: "12px",
                            width: "12px",
                            backgroundColor: "#34ef3d",
                            marginRight: "8px",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{ color: "black", fontSize: "15px" }}
                        >
                          Resultados regulares
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" justify="center">
                      <Grid item>
                        <div
                          style={{
                            height: "12px",
                            width: "12px",
                            backgroundColor: "#EADE53",
                            marginRight: "8px",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{ color: "black", fontSize: "15px" }}
                        >
                          Resultados alavancados pelo Sensei Club
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ marginBottom: "48px" }}>
                    <img src="./max.svg" alt="Max Lacerda" />
                    <Typography
                      style={{
                        color: "black",
                        fontSize: "22px",
                        fontWeight: 600,
                      }}
                    >
                      Max Lacerda
                    </Typography>
                    <Typography style={{ color: "black", fontSize: "15px" }}>
                      Especialista em Cash Game - NL 500
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomButton
                      onClick={() => {
                        this.props.setSignUpInfo({
                          ref: this.state.ref,
                          open: true,
                        });
                        this.props.history.push("/login");
                      }}
                      text="Alavancar resultados"
                    />
                  </Grid>
                </Grid>
              </Container>
            </section> */}
            <section
              id="como-funciona"
              style={{
                background: "url('./bg-white.png')",
                paddingTop: "56px",
              }}
            >
              <Container>
                <Grid container justify="space-between" alignItems="center">
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ textAlign: "center", paddingBottom: "48px" }}
                  >
                    <img
                      src="./como-funciona.png"
                      alt="Como Funciona"
                      style={{ width: "100%" }}
                    />
                    <Typography
                      style={{
                        color: "#A30908",
                        fontSize: "22px",
                        lineHeight: "33px",
                      }}
                    >
                      O Sensei Club foi pensado para você <b>jogador de poker</b>, cadastre e jogue nas principais plataformas poker <b>através dos nossos benefícios</b>, acumulando tickets para concorrer a <b>+ de R$100.000,00 em prêmios</b>.
                      <br />
                      <b>
                        Jogando no Sensei Club cedo ou tarde você irá ser premiado!
                      </b>
                    </Typography>
                  </Grid>
                  <Box component={Grid} item xs={12} md={3}>
                    <img className="passo" src="./passo1.png" alt="Passo 1" />
                    <Typography style={{ color: "black", fontSize: "18px" }}>
                      <b>Cadastre-se</b>, <b>ative um benefício</b> e comece a ganhar tickets para os
                      sorteios.
                    </Typography>
                  </Box>
                  <Box component={Grid} item xs={12} md={3}>
                    <img className="passo" src="./passo2.png" alt="Passo 2" />
                    <Typography style={{ color: "black", fontSize: "18px" }}>
                      <b>Jogue, indique amigos e ganhe mais tickets</b>. Quanto mais
                      tickets maior sua chance de ganhar.
                    </Typography>
                  </Box>
                  <Box component={Grid} item xs={12} md={3}>
                    <img className="passo" src="./passo3.png" alt="Passo 3" />
                    <Typography style={{ color: "black", fontSize: "18px" }}>
                      Concorra a + de <b style={{ fontWeight: 600, fontSize: 25 }}>R$100.000</b> em prêmios a cada 60 dias.{" "}
                    </Typography>
                  </Box>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: "48px", marginBottom: "56px" }}
                  >
                    <CustomButton
                      onClick={() => {
                        this.props.setSignUpInfo({
                          ref: this.state.ref,
                          open: true,
                        });
                        this.props.history.push("/login");
                      }}
                      text="Quero ativar meus benefícios"
                    />
                  </Grid>
                </Grid>
              </Container>
              <div
                id="benefits"
                style={{ background: "rgba(0,0,0,0.1)", padding: "48px 0" }}
              >
                <Container>
                  <Grid container>
                    <Grid item xs={12}>
                      <img
                        src="./beneficios.png"
                        alt="Benefícios Lucrativos"
                        style={{ maxWidth: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ color: "#A30908", fontSize: "22px", marginTop: 32 }}>
                        Aproveite a chance de concorrer a + de<b style={{ fontSize: 30 }}> R$100.000,00.</b>
                      </Typography>

                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          color: "#A30908",
                          fontSize: "24px",
                          fontWeight: "600",
                          margin: "32px 0",
                        }}
                      >
                        Ative um benefício abaixo e se cadastre:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container justify="center" alignItems="stretch">
                    {benefits.map((benefit) => {
                      return (
                        <CardSalas
                          key={benefit._id}
                          img={benefit.partner_logo}
                          alt={benefit.partner_name}
                          sala={benefit.partner_name}
                          descricao={benefit.description}
                          beneficios={benefit.partner_topics}
                          onClick={() => {
                            this.props.setSignUpInfo({
                              ref: this.state.ref,
                              open: true,
                              benefit: benefit,
                            });
                            this.props.history.push("/login");
                          }}
                        />
                      );
                    })}
                  </Grid>
                </Container>
              </div>
            </section>
            <section
              id="lucrativo-confiavel"
              style={{
                background: "url('./bg-vermelho.png')",
                padding: "56px 0",
                backgroundSize: "cover",
              }}
            >
              <Container>
                <Grid container>
                  <Grid item xs={12}>
                    <img
                      src="./lucrativo.png"
                      alt="Lucrativo e Confiável"
                      style={{ width: "100%", marginBottom: "8px" }}
                    />
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "22px",
                        marginBottom: "32px",
                      }}
                    >
                      Veja alguns dos profissionais que fazem parte do Clube:
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="flex-start" justify="space-between">
                  <Grid item xs={12} md={4} style={{ marginBottom: "32px" }}>
                    <img src="./zinhao.svg" alt='Mateus Carrion "Zinhao"' />
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "22px",
                        fontWeight: 600,
                      }}
                    >
                      Mateus Carrion "Zinhao"
                    </Typography>
                    <Typography style={{ color: "white", fontSize: "15px" }}>
                      Especialista em Cash Game - NL 500/NL1K
                      <br />
                      Co-fundador BrPC
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} style={{ marginBottom: "32px" }}>
                    <img src="./alisson.svg" alt="Alisson Piekazewicz" />
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "22px",
                        fontWeight: 600,
                      }}
                    >
                      Alisson Piekazewicz
                    </Typography>
                    <Typography style={{ color: "white", fontSize: "15px" }}>
                      Especialista em MTTs
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} style={{ marginBottom: "32px" }}>
                    <img
                      src="./lipepiv.svg"
                      alt='Felipe Boianovsky "lipepiv"'
                    />
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "22px",
                        fontWeight: 600,
                      }}
                    >
                      Felipe Boianovsky “lipepiv”
                    </Typography>
                    <Typography style={{ color: "white", fontSize: "15px" }}>
                      Especialista em MTTs e Cash Game NLHE
                      <br />
                      Campeão do evento #45 do EPT Barcelona – 2019
                      <br />
                      Campeão do $530 Bounty Builder High Roller – 2019
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        this.props.setSignUpInfo({
                          ref: this.state.ref,
                          open: true,
                        });
                        this.props.history.push("/login");
                      }}
                      size="large"
                      style={{
                        marginTop: "40px",
                        background: "white",
                        color: "#A30908",
                        borderRadius: 50,
                        fontWeight: 600,
                        fontSize: "20px",
                      }}
                    >
                      JUNTE-SE AOS PROFISSIONAIS
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </section>
            <section
              id="perguntas-frequentes"
              style={{
                textAlign: "left",
                background: "url('./bg-white.png')",
                padding: "56px 0",
              }}
            >
              <Container>
                <Grid container justify="center">
                  <Grid item>
                    <Typography
                      variant="h3"
                      style={{
                        color: "#A30908",
                        fontWeight: "700",
                        fontSize: "35px",
                        marginBottom: "32px",
                      }}
                    >
                      PERGUNTAS FREQUENTES
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <Faq
                      question="Como funciona?"
                      answer="Ative seus benefícios, jogue normalmente e receba tickets com base no rake pago para as plataformas. Os tickets serão depositados na sua conta do Clube. Com os tickets você participará de sorteios com + de R$100.000,00 em prêmios."
                    />
                    <Faq
                      question="Como ativo meus benefícios?"
                      answer="Escolha um benefício, preencha o formulário e siga o cadastro na plataforma selecionada."
                    />
                    <Faq
                      question="Como ganho tickets?"
                      answer="A cada $10 de rake gerado em nossos benefícios ativos você ganha 1 ticket. A cada benefício ativo de um jogador indicado você ganha 1 ticket. A cada 10 tickets gerados pelos seus jogadores indicados você ganha 1 ticket. Fique ligado pois teremos eventos promocionais onde você também podem ganhar tickets."
                    />
                    <Faq
                      question="Quando recebo meus tickets?"
                      answer="Os tickets são distribuídos 1x por mês de acordo com que as salas nos informam os relatórios com rake gerado de cada jogador. Cada temporada dura 2 meses e os tickets acumulados serão válidos para os sorteios de + de R$100.000,00 em prêmios no fim da temporada."
                    />
                    <Faq
                      question="Como recebo meu prêmio?"
                      answer="Ao fim de cada temporada ocorrerá os sorteios dos prêmios e caso seu ticket seja sorteado você irá ser notificado e poderá escolher entre o prêmio ou o valor em Sensei Coins depositdo na Sensei Wallet"
                    />
                    <Faq
                      question="Caso eu não seja sorteado, o que acontece com meus tickets?"
                      answer="Ao fim de cada temporada ocorrerá os sorteios dos prêmios e caso nenhum dos seus tickets seja sorteado, 30% dos seus tickets serão acumulados para a próxima temporada."
                    />
                    <Faq
                      question="Como recebo a confirmação de que meus benefícios foram ativados?"
                      answer="Você receberá um email de confirmação de cada benefício que for ativado e terá a um dashboard com a listagem de todos os seus benefícios ativos, respectivos status e valores gerados."
                    />
                    <Faq
                      question="Como funcionam os benefícios com base em Net Gaming Revenue (NGR)?"
                      answer="Isso significa que a plataforma que oferece o benefício paga os rakebacks com base no lucro líquido dela. Então, o rake gerado é o valor que o usuário gerou de pagamentos de rake menos o valor proporcional dos bônus, dos recebimentos e taxas que a atividade deste mesmo usuário gerou como custo para a plataforma."
                    />
                    <Faq
                      question="Como funcionam os benefícios com base em Gross Gaming Revenue (GGR)?"
                      answer="Isso significa que a plataforma que oferece o benefício paga os rakebacks com base no lucro bruto dela. Então, o rake gerado é o valor que o usuário gerou de pagamentos de rake menos o valor proporcional dos recebimentos que a atividade deste mesmo usuário gerou como custo para a plataforma."
                    />
                  </Grid>
                </Grid>
              </Container>
            </section>
            <section
              id="wallet"
              style={{
                background: "url('./bg-vermelho.png')",
                paddingTop: "48px",
                backgroundSize: "cover",
              }}
            >
              <Container>
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <img
                      src="./ewallet.png"
                      alt="E-Wallet"
                      style={{ width: "90%" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <img
                      src="./logo-senseiwallet.svg"
                      alt="Sensei Wallet"
                      style={{ width: "90%" }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        color: "white",
                        textTransform: "uppercase",
                        fontWeight: 300,
                        fontSize: "22px",
                        textAlign: "center",
                        margin: "48px 0",
                      }}
                    >

                      <b>
                        facilitamos a movimentação do seu dinheiro entre
                        plataformas. cuidados de tudo para que você possa focar
                        no seu jogo.
                      </b>
                    </Typography>
                  </Grid>
                </Grid>
                <Container>
                  <Grid container justify="center">
                    <Grid item xs={12} md={4}>
                      <WhiteCard
                        alt="Receber"
                        img="./card-receber.svg"
                        action="Receber"
                        text="Transfira o dinheiro para a Sensei Wallet e o receba como saldo na sua carteira."
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <WhiteCard
                        alt="Enviar"
                        img="./card-enviar.svg"
                        action="Enviar"
                        text="Faça o envio do saldo da sua carteira para qualquer uma das salas parceiras."
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <WhiteCard
                        alt="Transferir"
                        img="./card-transferir.svg"
                        action="Transferir"
                        text="Transfira facilmente seu saldo entre usuários da Sensei Wallet."
                      />
                    </Grid>
                  </Grid>
                </Container>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ padding: "56px 0" }}
                >
                  <Grid item style={{ padding: "32px" }}>
                    <img src="./sem-custo.svg" alt="Sem Custos" />
                    <Typography
                      style={{
                        color: "white",
                        textTransform: "uppercase",
                        fontWeight: 300,
                        fontSize: "15px",
                        textAlign: "center",
                        marginTop: "16px",
                      }}
                    >
                      Sem custos
                      <br />
                      abusivos
                    </Typography>
                  </Grid>
                  <Grid item style={{ padding: "32px" }}>
                    <img src="./bitcoin.svg" alt="Bitcoin" />
                    <Typography
                      style={{
                        color: "white",
                        textTransform: "uppercase",
                        fontWeight: 300,
                        fontSize: "15px",
                        textAlign: "center",
                        marginTop: "16px",
                      }}
                    >
                      Resgate em
                      <br />
                      bitcoin
                    </Typography>
                  </Grid>
                  <Grid item style={{ padding: "32px" }}>
                    <img src="./sem-burocracia.svg" alt="Sem Burocracia" />
                    <Typography
                      style={{
                        color: "white",
                        textTransform: "uppercase",
                        fontWeight: 300,
                        fontSize: "15px",
                        textAlign: "center",
                        marginTop: "16px",
                      }}
                    >
                      Sem
                      <br />
                      burocracia
                    </Typography>
                  </Grid>
                  <Grid item style={{ padding: "32px" }}>
                    <img src="./seguranca.svg" alt="Segurança" />
                    <Typography
                      style={{
                        color: "white",
                        textTransform: "uppercase",
                        fontWeight: 300,
                        fontSize: "15px",
                        textAlign: "center",
                        marginTop: "16px",
                      }}
                    >
                      Mais
                      <br />
                      segurança
                    </Typography>
                  </Grid>
                  <Grid item style={{ padding: "32px" }}>
                    <img src="./suporte.svg" alt="Suporte" />
                    <Typography
                      style={{
                        color: "white",
                        textTransform: "uppercase",
                        fontWeight: 300,
                        fontSize: "15px",
                        textAlign: "center",
                        marginTop: "16px",
                      }}
                    >
                      Suporte em
                      <br />
                      português
                    </Typography>
                  </Grid>
                  <Grid container justify="center">
                    <Grid item>
                      <a href="https://wallet.sensei.poker">
                        <Button
                          variant="contained"
                          size="large"
                          style={{
                            marginTop: "48px",
                            background: "white",
                            color: "#A30908",
                            borderRadius: 50,
                            fontWeight: 600,
                            fontSize: "20px",
                          }}
                        >
                          QUERO GANHAR MAIS DINHEIRO
                        </Button>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </section>

            <section
              id="sobre"
              style={{
                backgroundColor: "black",
                color: "white",
                position: "relative",
              }}
            >
              <div
                style={{
                  height: 2,
                  width: "80%",
                  background: "#cd0201",
                  position: "absolute",
                  bottom: 45,
                  left: "50%",
                  marginLeft: "-40%",
                  boxShadow: "0px 0px 9px rgb(255 0 0)",
                }}
              ></div>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    background: "url('./club-bg.png') no-repeat",
                    backgroundSize: "cover",
                    minHeight: 300,
                  }}
                >
                  <a
                    style={{ color: "white" }}
                    href="https://club.sensei.poker"
                  >
                    <Typography variant="h3" style={{ marginTop: 160 }}>
                      <b>Clube</b>
                    </Typography>
                    <Typography variant="h6">
                      <b>de Benefícios</b>
                    </Typography>
                  </a>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    background: "url('./team-bg.png') no-repeat",
                    backgroundSize: "cover",
                    minHeight: 300,
                    borderLeft: "1px solid #3a060a",
                    borderRight: "1px solid #3a060a",
                  }}
                >
                  <a
                    style={{ color: "white" }}
                    href="https://senseikan.sensei.poker"
                  >
                    <Typography variant="h3" style={{ marginTop: 160 }}>
                      <b>Time</b>{" "}
                    </Typography>
                    <Typography variant="h6">
                      <b>SenseiKan</b>
                    </Typography>
                  </a>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    background: "url('./school-bg.png') no-repeat",
                    backgroundSize: "cover",
                    minHeight: 300,
                  }}
                >
                  <a
                    style={{ color: "white" }}
                    href="https://escola.sensei.poker"
                  >
                    <Typography variant="h3" style={{ marginTop: 160 }}>
                      <b>Escola</b>{" "}
                    </Typography>
                    <Typography variant="h6">
                      <b>Maior Acervo</b>
                    </Typography>
                  </a>
                </Grid>
              </Grid>
            </section>
            <section
              style={{
                background: "url('./bg-footer.svg')",
                backgroundSize: "cover",
                backgroundPosition: "center",
                padding: "64px 0",
                color: "white",
              }}
            >
              <Container>
                <Grid container justify="center">
                  <Grid item xs={12} md={4}>
                    <img
                      src="./senseipoker.png"
                      alt="Sensei Poker"
                      style={{ maxWidth: "80%" }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </section>
          </div>
        </ThemeProvider>
        <ReactWhatsapp
                  number="+55 (61) 99614-5707"
                  message="Olá, gostaria de saber sobre o Sensei Poker"
                  style={{
                    width: "80px",
                    position: "fixed",
                    border: "none",
                    bottom: "10px",
                    right: "10px",
                    backgroundColor: "transparent",
                  }}
                >
                  <img src="whatsapp03.png" alt="" style={{ width: "100%" }} />
                </ReactWhatsapp>
      </div>
    );
  }
}

const mapStateToProps = ({ benefits, auth, signUpInfo }) => {
  return {
    benefits,
    auth,
    signUpInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllBenefits: () => dispatch(getBenefits()),
    setSignUpInfo: (info) => dispatch(setSignUpInfoData(info)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Landing)
);
