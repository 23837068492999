import React, { Component } from 'react'
import BasicButton from '../../common/BasicButton'
import StyledParagraph from '../../common/StyledParagraph'
import GreenTitle from '../../common/GreenTitle'
import GreenDivider from '../../common/GreenDivider'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {CircularProgress, Typography, Grid, Dialog } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter} from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux'
import { getUserData, updateUser, verifyUserEmail, sendEmailVerificationCode} from '../actions'
import PhoneInput from 'react-phone-input-2'
import AffiliateWidget from '../../common/AffiliateWidget'

const styles = theme => ({
  main: {
    color: "#CCC",
  },
  label: {
    fontSize: '12px'
  },
  input: {
    fontSize: '15px'
  }
})

class UserDetailsForm extends Component{  

state = {
  name: '',
  phone_number: '',
  email: '',
  originalEmail: '',
  id:'',
  account: '',
  platform: '',
  verifyEmailOpen: false,
  verificationCode: '',
  snackbarOpen: false,
  snackMessage: '',
  vertical: 'bottom',
  horizontal: 'center',
  unsaved_email: false,
  isFetching: false,
  isLoading: true,
  isVerifying: false,
  copied: false,
}

componentDidMount(){
  this.getUser()
}

getUser(){
  getUserData()
  .then(res=>{
    const {
      name,
      phone_number,
      email,
      id
    } = res.data
    this.setState({
      name,
      phone_number,
      email,
      originalEmail: email,
      id,
      isLoading: false
    })
  })
  .catch(err=>{
    this.setState({
      isLoading: false,
      snackbarOpen: true,
      snackMessage: err.message
    })
  })
}

verifyEmail = () => {
  this.setState({isVerifying: true})
  verifyUserEmail(this.state.verificationCode)
  .then(res=>{
    this.setState({
      isVerifying: false,
      snackbarOpen: true,
      verifyEmailOpen: false,
      snackMessage: 'email verificado com sucesso!'
    })

    window.location.reload();
  })
  .catch(err=>{
    this.setState({
      isVerifying: false,
      snackbarOpen: true,
      snackMessage: 'erro: '+err.message
    })
  })
}

sendVerificationCode = () => {
  sendEmailVerificationCode()
  .then(res=>{
    this.setState({
      verifyEmailOpen: true
    })
  })
  .catch(err=>{
    this.setState({
      snackbarOpen: true,
      snackMessage: 'erro: '+err.message
    })
  })
}

handleClose = () => {
  this.setState((state) => ({ ...state, snackbarOpen: false }));
};

handleCloseVerification = () => {
  this.setState({verifyEmailOpen: false})
}

handleSubmit = () => {
  const {  name, phone_number, email, originalEmail } = this.state;
  let phone = phone_number
  if(phone.charAt(0) !== '+'){
    phone = '+'+phone
  }
  const body = { name, phone_number: phone, email };
  updateUser(body).then(res => {
    this.setState({ 
      snackbarOpen: true, 
      snackMessage: 'Usuário atualizado com sucesso', 
      verifyEmailOpen: email !== originalEmail ? true : false
    });
  }).catch(err => {
    this.setState({ snackbarOpen: true, snackMessage: 'Erro ao atualizar usuário' });
  })
}

handleChange = (event) => {
  const value = event.target.value
  const field = event.target.name

  if(field === 'email'){
    if(value !== this.props.auth.user_email){
      this.setState({
        unsaved_email: true
      })
    } else {
      this.setState({
        unsaved_email: false
      })
    }
  }

  this.setState({ [field]: value })
}

handleCopy = () => {
  this.setState({copied: true})
  setTimeout(()=>{
    this.setState({copied: false})
  }, 3000)
}


render() {
  const { isFetching, isLoading, vertical, horizontal, snackbarOpen, snackMessage, name, phone_number, email, isVerifying, unsaved_email} = this.state
  const { classes, auth } = this.props
  

  return (
    isLoading ? (
      <Typography variant='body1'>Carregando...</Typography>
    ) : (
      <Grid container>
        <Grid container>

          <Grid item xs={12} sm={9} md={6} lg={4}>
            <AffiliateWidget style={{marginTop:16}} id={auth.id}/>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={9} md={6} lg={4}>
          <ValidatorForm
          ref="form"
          onSubmit={this.handleSubmit}
          className={classes.main}
        >

          <TextValidator
            fullWidth
            label="Nome"
            onChange={this.handleChange}
            name="name"
            value={name}
            validators={['required']}
            errorMessages={['Preencha este campo']}
            margin="normal"
            InputProps={{ classes: { root: classes.input } }}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
          />
          <Grid container style={{width:'100%'}}>
            <Grid item xs={9}>
              <TextValidator
                fullWidth
                label="E-mail"
                onChange={this.handleChange}
                name="email"
                value={email}
                validators={['required']}
                errorMessages={['Preencha este campo']}
                margin="normal"
                InputProps={{ classes: { root: classes.input } }}
                InputLabelProps={{
                  classes: {
                    root: classes.label
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} style={{display:'flex', alignItems:'center'}}>
              {
                unsaved_email ? <span style={{fontSize: 10, fontWeight: '600', color: '#bd7e0b', textAlign:'center'}}>salve o email antes de verificá-lo</span> : (
                  <BasicButton styleType='yellow' handleClick={this.sendVerificationCode} type='button' disabled={auth.email_verified || unsaved_email}>{!auth.email_verified ? 'Verificar' : 'Verificado'}</BasicButton>
                )
              }
            </Grid>
          </Grid>

          <PhoneInput
            country={'br'}
            specialLabel='Whatsapp'
            value={phone_number}
            onChange={phone_number => this.setState({ phone_number })}
            fullWidth
            margin="normal"
            variant="outlined"
          />
         

          <BasicButton
            fullWidth
            color="primary"
            type="submit"
            disabled={isFetching}
            margin='10px 0px 0px 0px'
          >
            {this.state.isFetching && <CircularProgress size={20} />} Atualizar dados
          </BasicButton>
        </ValidatorForm>

        <Dialog open={this.state.verifyEmailOpen} onClose={this.handleCloseVerification} style={{margin: 'auto', width: '400px', padding:'30px'}}>
              <GreenTitle padding="20px 20px 0 20px" textAlign='center'>
                  VERIFIQUE SEU NOVO EMAIL!
                </GreenTitle>
              <GreenDivider marginLeft='auto' marginRight='auto'/>
              <StyledParagraph textAlign="center" padding='0 20px'>Informe aqui o código que te enviamos no email <strong>{email}</strong> </StyledParagraph>
              <ValidatorForm
                style={{
                  margin: 'auto'
                }}
                ref="form"
                onSubmit={()=>this.verifyEmail()}
              >
                <TextValidator
                fullWidth
                value={this.state.verificationCode}
                label="Código"
                type='text'
                onChange={this.handleChange}
                name="verificationCode"
                validators={['required']}
                errorMessages={['Preencha esse campo']}
                margin="dense"/>
                <BasicButton>{isVerifying && <CircularProgress size={20} />}VERIFICAR EMAIL</BasicButton>
              </ValidatorForm>
            </Dialog>
      
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`}
          open={snackbarOpen}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
        message={<span id="message-id">{snackMessage}</span>}
        />
        </Grid>
      </Grid>
    )
  )
}
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  }
}

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(UserDetailsForm)))