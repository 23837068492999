import React from 'react'
import Typography from '@material-ui/core/Typography'


const GreenTitle = (props) => {

    return (
      <Typography style={{color: "#3C9A68", fontSize: '20px', fontWeight: "700", letterSpacing: "2px", padding: props.padding, textAlign: props.textAlign}} component="h1" variant="h5">
        {props.children}
      </Typography>
    )

}

export default GreenTitle;