import * as types from './constants/ActionTypes'
import Auth from '../../utils/auth';
import { routes } from './core/api';
import axios from 'axios'
import { Auth as AuthCognito } from 'aws-amplify';


export const createUser = (user) => {
  return {
      type: types.AUTH_CREATE_USER,
      user,
  }
}

export function confirmSignInFetchData(user, code) {
  return dispatch => {
    return AuthCognito.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA')
      .then(confirmedUser => {
        return AuthCognito.currentAuthenticatedUser().then(loggedUser => {
          dispatch(signInUser(loggedUser))
          return new Promise(resolve => {
            resolve(loggedUser);
          })
        })
      })
      .catch(err => {
        return new Promise(function (resolve, reject) {
          reject(err)
        })
      })
  }
}

export const signInUser = (user) => {
  return {
      type: types.AUTH_SIGNIN_USER,
      user: {
        user_name: user.attributes.name,
        user_email: user.attributes.email,
        id: user.username,
        email_verified: user.attributes.email_verified,
        extra_accounts: user.attributes['custom:extra_accounts'] || false
      }
  }
}

export const authLogoutUser = () => {
  return {
      type: types.AUTH_LOGOUT_USER
  }
}

export const getBalance = (balance) => {
  return {
      type: 'GET_BALANCE',
      balance,
  }
}

export const getTicketsBalance = (tickets_balance) => {
  return {
      type: 'GET_TICKETS_BALANCE',
      tickets_balance,
  }
}

export const setSignUpInfo = (signUpInfo) => {
  return {
      type: 'SET_SIGNUP_INFO',
      signUpInfo,
  }
}

export function setSignUpInfoData(info) {
  return dispatch => {
    dispatch(setSignUpInfo(info))
  }
}

// -- SIGNIN COM COGNITO
export function signInFetchData(username, password) {
  return dispatch => {
    return AuthCognito.signIn(username.trim(), password)
    .then(user => { 
      if(["NEW_PASSWORD_REQUIRED", "SOFTWARE_TOKEN_MFA"].includes(user.challengeName)){
        return new Promise(resolve => {
          resolve(user);
        })
      }

      dispatch(signInUser(user))
      return new Promise(resolve => {
        resolve(user);
      })
    })
    .catch(err => {
      return new Promise(function(resolve, reject) {
        reject(err)
      })
    })
  }
}

export function getTransfersAccount() {
  return dispatch => {
    return new Promise(function(resolve, reject) {
      AuthCognito.currentSession()
      .then(session=>{
          return axios.get(
          routes.wallet,
          { 
              headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
          })
          .then(res=>{
              dispatch(getBalance(res.data))
              resolve(res)
          })
          .catch(err=>{
          reject(err)
          })
      })
      .catch(err=>{
          reject(err)
      })
    })
  }
}

export function getTicketsBalanceFetchData() {
  return dispatch => {
    return new Promise(function(resolve, reject) {
      AuthCognito.currentSession()
      .then(session=>{
          return axios.get(
          routes.club_tickets,
          { 
              headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
          })
          .then(res=>{
              dispatch(getTicketsBalance(res.data))
              resolve(res)
          })
          .catch(err=>{
          reject(err)
          })
      })
      .catch(err=>{
          reject(err)
      })
    })
  }
}

export function logout(username, password) {
  return dispatch => {
    AuthCognito.signOut()
    .then(res=>{
      dispatch(authLogoutUser())
      return new Promise(function(resolve) {
        resolve('você foi deslogado')
      })
    })
    .catch(err=>{
      return new Promise((resolve, reject) => {
        reject(err.message);
      })
    })
  }
}

export function isUserAuthenticated() {
    return new Promise((resolve, reject) => {
      AuthCognito.currentSession()
      .then(session=>{
        resolve(session)
      })
      .catch(err=>{
          reject(err)
      })
  })
}

// -- SIGNUP COM COGNITO
export function signUp(user) {
  return dispatch => {
    const attributes = {
        name: user.name,
        email: user.email,
        phone_number: `+${user.phoneNumber}`,
    }

    if(user.affiliator) {
      attributes['custom:affiliator'] = user.affiliator
    }
    
    return AuthCognito.signUp({
          username: user.email,
          password: user.password,
          attributes 
      })
      .then(body => {
        Auth.storeInfoForValidation(body.userSub, body.user.username);
        return new Promise(function(resolve) {
          resolve(body)
        })
      })
      .catch(err => {
        return new Promise((resolve, reject) => {
          reject(err);
        })
      });
  }
}

export function validate(validationCode) {
  return dispatch => {
    return AuthCognito.confirmSignUp(Auth.getValidationEmail(), validationCode)
    .then(res => {
      return new Promise(resolve => resolve({}))
    })
    .catch(err=>{
      return new Promise((resolve, reject) => {
        reject(err.message);
      })
    })
  }

}


export function resendSignUpConfirmation() {
  return dispatch => {
    return AuthCognito.resendSignUp(Auth.getValidationEmail())
    .then(res => {
      // console.log('resposta do servidor: ', res)
      
      return new Promise(resolve => resolve({}))
    })
    .catch(err=>{
      return new Promise((resolve, reject) => {
        reject(err.message);
      })
    })
  }
}

export function completeNewPasswordChallenge(user, newPassword, RequiredNameAttribute) {
  //fixing phone_number format
  RequiredNameAttribute.phone_number = '+'+RequiredNameAttribute.phone_number
  return dispatch => {
    return AuthCognito.completeNewPassword(user, newPassword, RequiredNameAttribute)
    .then((res)=>{
      return new Promise((resolve) => {
        resolve(res);
      })
    })
    .catch(err=>{
      return new Promise((resolve, reject) => {
        reject(err.message);
      })
    })
  }
}