export const statement = (state = {}, action) => {
    switch (action.type) {
      case 'GET_STATEMENT':
        return action.statement
      default:
          return state;
    }
  }
  
export const platforms = (state = [], action) => {
    switch (action.type) {
      case 'GET_PLATFORMS':
        return action.platforms
      default:
          return state;
    }
  }