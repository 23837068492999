import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    acordeon: {
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "none",
    },
    heading: {
      fontSize: '22px',
      fontWeight: 600,
      lineHeight: 1.3,
    }
  }));

export default function SimpleAccordion(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion className={classes.acordeon}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <Grid container className={classes.heading}>
                <Grid item xs={12}>
                    <AddIcon style={{}}/>{props.question}
                </Grid>
            </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography >
            {props.answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
