import React, {Component} from 'react'
import { Grid, TextField } from '@material-ui/core'
import { withRouter } from 'react-router-dom';



class IdentifierSearch extends Component {

  constructor(props) {
    super(props);

    this.handleSearchByIdentifier = this.handleSearchByIdentifier.bind(this);
    this.timer = null

  }
  
  state={
    isFetching: false,
    isSearching: false,
    searchTerm: ''
  }

  
  handleChange = (e) => {
    this.setState({user: e})
    this.props.handleSearch(e.id)
  }

  handleSearchByIdentifier = (event) => {
    const searchTerm = event.target.value;
    this.setState({searchTerm, docs: [], isSearching: true })
    clearTimeout(this.timer)
    this.timer = setTimeout(()=>{
      if(searchTerm === ''){
        this.props.handleSearch('')
      }
      else{
        this.props.handleSearch(searchTerm)
      }
    }, 500)
    
  }
  

  render(){
    const { searchTerm } = this.state;
    return(
      <Grid container justify="space-between" alignItems="center" spacing={3}>
        <Grid item xs={12} style={{ paddingTop: 16 }}>
          <TextField label='Buscar por identificador' fullWidth value={searchTerm} onChange={this.handleSearchByIdentifier}></TextField>
        </Grid>
        
      </Grid>
    )
  }
}


export default withRouter(IdentifierSearch)