import React, {Component} from 'react'
import { Grid } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

class DateSearch extends Component {
  
  state={
    startDate: this.props.initialDate ? this.props.initialDate.startDate : moment().subtract(30, 'years').utc().toDate(),
    endDate: this.props.initialDate ? this.props.initialDate.endDate : moment().utc().toDate()
  }

  handleStartDateChange = (date) => {
    this.setState({startDate: date})
    this.props.handleSearch({ startDate: moment(date).startOf('day').toISOString(), endDate: moment(this.state.endDate).endOf('day').toISOString() })
  }
  
  handleEndDateChange = (date) => {
    this.setState({endDate: date})
    this.props.handleSearch({ startDate: moment(this.state.startDate).startOf('day').toISOString(), endDate: moment(date).endOf('day').toISOString() })
  }

  render(){

    const { startDate, endDate } = this.state;

    return(
      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              margin="normal"
              label="Início"
              format="dd/MM/yyyy"
              value={moment(startDate).utc(false).toDate()}
              onChange={this.handleStartDateChange}
            />
          </MuiPickersUtilsProvider>
          
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                margin="normal"
                label="Fim"
                format="dd/MM/yyyy"
                value={moment(endDate).utc(false).toDate()}
                onChange={this.handleEndDateChange}
              />
            </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    )
  }
}

export default DateSearch