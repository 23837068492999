import { headers, routes } from './core/api';
import { Auth } from 'aws-amplify';
import axios from 'axios';

export const createUser = (user) => {
  return {
      type: 'CREATE_USER',
      user
  }
}

export const getMe = (me) => {
  return {
    type: 'GET_ME',
    me
  }
}

export const updateUser = (body) => {
  return new Promise((resolve, reject)=>{
    Auth.currentSession()
    .then(session=>{
      axios.put(
        routes.me,
        body,
        { 
          headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
        }
      )
      .then(res=>{
        resolve(res)
      })
      .catch(err=>{
        reject(err)
      })
    })
    .catch(err=>{
      reject(err)
    })
  })
}

export const changePassword = (oldPassword, newPassword) => {
  return new Promise((resolve, reject)=>{
    Auth.currentSession()
    .then(session=>{
      axios.put(
        routes.changePassword,
        {
          oldPassword,
          newPassword
        },
        { 
          headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken, 'AccessToken': session.accessToken.jwtToken},
        }
      )
      .then(res=>{
        resolve(res)
      })
      .catch(err=>{
        reject(err)
      })
    })
    .catch(err=>{
      reject(err)
    })
  })
}

export const getUserData = () => {
  return new Promise((resolve, reject)=>{
    Auth.currentSession()
    .then(session=>{
      axios.get(
        routes.me,
        { 
          headers: { 'Content-Type': 'application/json', 'Authorization': session.idToken.jwtToken},
        }
      )
      .then(res=>{
        resolve(res)
      })
      .catch(err=>{
        reject(err)
      })
    })
    .catch(err=>{
      reject(err)
    })
  })
}


export function getFiltersFetchData(filters) {
  return dispatch => {
    dispatch(getFilters(filters))
  }
}

export const getFilters = (filters) => {
  return {
      type: 'GET_FILTERS',
      filters
  }
}

export const getBalance = (balance) => {
  return {
      type: 'GET_BALANCE',
      balance
  }
}
export const getUser = (user) => {
  return {
      type: 'GET_USER',
      user
  }
}
export const getUsers = (users) => {
  return {
      type: 'GET_USERS',
      users
  }
}

export function createUserFetchData(body) {
  return dispatch => {
    return fetch(routes.create, {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify(body)
    })
    .then(res => { 
      if(res.status !== 200) { 
        return new Promise((resolve, reject) => {
          reject('Usuário não criado!');
        })
       }
      return res.json() 
    })
    .then(body => {
      dispatch(createUser(body))
      return new Promise(function(resolve) {
        resolve(body)
      })
    })
  }
  
}

export function getMeFetchData() {
  return dispatch => {
    return fetch(routes.me, {
      method: 'GET',
      headers: headers()
    })
    .then(res => { 
      return res.json() 
    })
    .then(body => {
      dispatch(getMe(body))
      return new Promise(function(resolve) {
        resolve(body)
      })
    })
  }
}

export function getUserBalanceFetchData(user_id = "") {
  return dispatch => {
    return fetch(routes.getBalance+`${user_id}`, {
      method: 'GET',
      headers: headers()
    })
    .then(res => { 
      return res.json() 
    })
    .then(body => {
      dispatch(getBalance(body))
      return new Promise(function(resolve) {
        resolve(body)
      })
    })
  }
}

export function getUsersFetchData({ page = 1, query = {}, all = false}) {
  let qs = '?';
    if(page) {
      qs += 'page='+page
    }
  
  if(all) {
    qs += '&limit=999'
  }
  var queryString = Object.keys(query).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
  }).join('&');

  let route = routes.get+qs;
  if(queryString.length) {
    route += `&${queryString}`
  }

  return dispatch => {
    return fetch(route, {
      method: 'GET',
      headers: headers()
    })
    .then(res => { 
      return res.json() 
    })
    .then(body => {
      dispatch(getUsers(body))
      return new Promise(function(resolve) {
        resolve(body)
      })
    })
  }
}

export function getUserFetchData(id) {
  return dispatch => {
    return fetch(`${routes.get}/${id}`, {
      method: 'GET',
      headers: headers()
    })
    .then(res => { 
      return res.json() 
    })
    .then(body => {
      dispatch(getUser(body))
      return new Promise(function(resolve) {
        resolve(body)
      })
    })
  }
}

export function verifyUserEmail(code){
  return new Promise((resolve, reject)=>{
    Auth.verifyCurrentUserAttributeSubmit('email', code)
    .then(res=>{
      resolve(res)
    })
    .catch(err=>{
      reject(err)
    })
  })
}

export function sendEmailVerificationCode(){
  return new Promise((resolve, reject)=>{
    Auth.verifyCurrentUserAttribute('email')
    .then(res=>{
      resolve(res)
    })
    .catch(err=>{
      reject(err)
    })
  })
}