import { Button, Typography } from '@material-ui/core'
import React, {Component} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import NumberFormat from 'react-number-format';
import SenseiCoinWhite from './SenseiCoinWhite';

const styles = theme => ({
  button: {
    background: '#2C93DD',
    "&:hover":{
      background: '#46abf3'
    }
  },
})

class PurchaseButton extends Component{
  render(){

    const {classes, value, handleClick} = this.props

    return(
      <Button
        onClick={handleClick}
        className={classes.button}
        fullWidth>
        <Typography  style={{color:'white',fontSize: 15, fontWeight: 'bold', textAlign: 'right'}} variant='body1'>
          <NumberFormat
            displayType='text'
            value={value}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator='.'
            decimalSeparator=','
          />
          <SenseiCoinWhite width='17px' verticalAlign='text-top' margin='0 0 0 2px'/>
        </Typography>
      </Button>
    )
  }
}

export default withStyles(styles)(PurchaseButton)