import { Grid, Typography } from '@material-ui/core'
import React, {Component} from 'react'
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  amountIcon:{
    color:'#999', 
    verticalAlign:'middle',
    "&:hover":{
      color: '#444'
    }
  }
})

class QuantitySelector extends Component{
  render(){

    const {classes, amount, handleIncrease, handleDecrease} = this.props

    return(
      <div style={{background: '#e8e8e8', borderRadius: 5, height:32, display:'flex', alignItems:'center'}}>
        <Grid container alignItems='center' >
          <Grid style={{cursor:'pointer',textAlign:'center'}} item xs={4}>
          <IndeterminateCheckBoxIcon onClick={handleDecrease} className={classes.amountIcon}/>
          </Grid>
          <Grid item style={{textAlign:'center'}} xs={4}>
          <Typography variant='body1'>
            {amount}
          </Typography>
          </Grid>
          <Grid item style={{textAlign:'center', cursor:'pointer'}} xs={4}>
            <AddBoxIcon onClick={handleIncrease} className={classes.amountIcon}/>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(QuantitySelector)