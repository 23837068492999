import React, { Component } from 'react'
import BasicPageLayout from '../common/BasicPageLayout';
import BasicTitle from '../common/BasicTitle'
import { Typography } from '@material-ui/core';
import VideoModules from '../video_modules/VideoModules';
import ReactGA from 'react-ga';


class SenseiTV extends Component {
  async componentDidMount() {
    if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } 
  }
  render() {
    return (
      <BasicPageLayout selectedMenu='senseitv'>
        <BasicTitle margin='0 0 16px'>Escola</BasicTitle>
        <Typography variant='body1'>Tenha acesso a conteúdos exclusivos do acervo do Sensei Poker</Typography>
        <VideoModules/>
      </BasicPageLayout>
    )
  }
}


export default SenseiTV