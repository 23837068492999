import React, {Component} from 'react'
import { Dialog, CircularProgress } from '@material-ui/core'
import GreenTitle from '../common/GreenTitle'
import GreenDivider from '../common/GreenDivider'
import { ValidatorForm ,TextValidator } from 'react-material-ui-form-validator';
import withStyles from '@material-ui/core/styles/withStyles'
import BasicButton from '../common/BasicButton'

const styles = theme => ({
  center: {
    margin:'8px auto'
  }
})

class ActivationInformIdentifier extends Component{
  state={
    identifierValue: ''
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  render() {
    const {activation, open, classes, handleSubmitInformIdentifier, onClose, isFetching} = this.props

    return(
      <Dialog open={open} 
        onClose={onClose}
        PaperProps={{
          style: {
            padding:'32px',
            textAlign:'center',
            boxSizing: 'border-box'
          }
        }}
      >
        <GreenTitle>INFORME O <strong style={{textTransform: 'uppercase', textDecoration: 'underline'}}>{Object.keys(activation).length > 0 && activation.benefit.identifier_needed}</strong> DA CONTA</GreenTitle>
      <GreenTitle>QUE VOCÊ CRIOU NA <strong style={{textTransform: 'uppercase', textDecoration: 'underline'}}>{Object.keys(activation).length > 0 && activation.benefit.partner.name}</strong></GreenTitle>
        <div className={classes.center}>
          <GreenDivider/>
        </div>
        <ValidatorForm
                  style={{
                    margin: 'auto'
                  }}
                  ref="form"
                  onSubmit={()=>handleSubmitInformIdentifier(activation._id, this.state.identifierValue)}
                >
                  <TextValidator
                  fullWidth
                  value={this.state.identifierValue}
                  label={Object.keys(activation).length > 0 && activation.benefit.identifier_needed}
                  type='text'
                  onChange={this.handleChange}
                  name="identifierValue"
                  validators={['required']}
                  errorMessages={['Preencha esse campo']}
                  margin="dense"/>
                  <BasicButton margin='16px 0'>{isFetching && <CircularProgress size={20} />}CONFIRMAR</BasicButton>
                </ValidatorForm>
        
      </Dialog>
    )
  }
}

export default withStyles(styles)(ActivationInformIdentifier)