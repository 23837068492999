import React, {Component} from 'react'
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
class OrderStatusSearch extends Component {
  
  state={
    status: 'ALL'
  }

  
  handleChange = (e) => {
    this.setState({status: e.target.value})
    this.props.handleSearch(e.target.value)
  }

  render(){

    const { status } = this.state;

    return(
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={6} style={{ paddingTop: 16 }}>
          <FormControl style={{ width: 150 }}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              value={status}
              onChange={this.handleChange}
              fullWidth
            >
              <MenuItem value={'ALL'}>Todos</MenuItem>
              <MenuItem value={'PENDING'}>Pendentes</MenuItem>
              <MenuItem value={'DENIED'}>Negados</MenuItem>
              <MenuItem value={'APPROVED'}>Aprovados</MenuItem>
              <MenuItem value={'CANCELED'}>Cancelados</MenuItem>
            </Select>
          </FormControl>
        </Grid>
       
      </Grid>
    )
  }
}

export default OrderStatusSearch;