import React, { Component } from 'react'
import BasicPageLayout from './BasicPageLayout'
import BasicButton from './BasicButton'
import { Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Logger } from 'aws-cloudwatch-log'

const config = { 
	logGroupName: 'affiliation_frontend_errors', 
	logStreamName: 'frontend', 
	region: 'us-east-1', 
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, 
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY, 
	local: false 		// Optional. If set to true, the log will fall back to the standard 'console.log'.
}

const cloudWatchLogger = new Logger(config);
class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
      // Display fallback UIs
      this.setState({ hasError: true });
      // You can also log the error to an error reporting service
      cloudWatchLogger.log({ message: error, trace: info })
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return  <BasicPageLayout>
        <Grid container justify="space-around" alignItems="center" style={{ paddingBottom: 16 }} spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant="h3">Ocorreu um erro, tente novamente mais tarde.</Typography>
          </Grid>
          <Grid item sm={8} md={5}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <BasicButton>Ir para página inicial</BasicButton>
            </Link>
          </Grid>
        </Grid>
      </BasicPageLayout>;
      }
      return this.props.children;
    }
}

export default ErrorBoundary
