import * as types from './constants/ActionTypes'
import { initialUserState } from './constants/ReducersInitialState'

export const auth = (state = initialUserState(), action) => {
  switch (action.type) {
    case types.AUTH_CREATE_USER:
      return Object.assign({}, state, action.user)
    case types.AUTH_SIGNIN_USER:
      return Object.assign({}, state, action.user)
    case types.AUTH_LOGOUT_USER:
      return initialUserState
    default:
        return state;
  }
}

export const balance = (state = null, action) => {
  switch (action.type) {
      case 'GET_BALANCE':
        return action.balance
      default:
        return state;
  }      
}

export const tickets_balance = (state = null, action) => {
  switch (action.type) {
      case 'GET_TICKETS_BALANCE':
        return action.tickets_balance
      default:
        return state;
  }      
}

export const signUpInfo = (state = { benefit: null, ref: null, open: false }, action) => {
  switch (action.type) {
      case 'SET_SIGNUP_INFO':
        return action.signUpInfo
      default:
        return state;
  }      
}
