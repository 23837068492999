import React, {Component} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { Grid, Typography } from '@material-ui/core'
import SenseiCoin from '../common/SenseiCoin'
import moment from 'moment'
import NumberFormat from 'react-number-format'

const styles = theme => ({
  resultDiv:{
    padding: '16px 0',
    borderBottom:'1px solid #ececec'
  }
})

class ResultsList extends Component {
  render() {
    const {classes, results} = this.props

    return(
      <div>
        <Grid container spacing={2} alignItems="center" style={{ marginTop: 16, marginBottom: 8, borderBottom: '1px solid #dedede' }}>
          <Grid item xs={3}><Typography variant="caption">Data</Typography></Grid>
          <Grid item style={{ textAlign: 'center' }} xs={3}><Typography variant="caption">Rakeback</Typography></Grid>
          <Grid item style={{ textAlign: 'center' }} xs={3}><Typography variant="caption">Desconto</Typography></Grid>
          <Grid item style={{ textAlign: 'right' }} xs={3}><Typography variant="caption">Recebido</Typography></Grid>
        </Grid>
        {
        results.length > 0 ? (
          results.map(r=>{
            let value = r.rakeback_value || r.value
            
            if(r.discount) {
              value -= r.discount
            }

            const date = r.registry_date || r.result.registry_date
            return(
              <div className={classes.resultDiv} key={r._id}>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography variant="body1" style={{ color: "#444444" }}>{moment(date).utc().format('MM/YYYY')}</Typography>
                    {
                      r.validity_period_start && (
                        <Typography variant="body1" style={{ fontSize: 10, color: "#444444" }}>({moment(r.validity_period_start).utc().format('DD/MM/YYYY')} a {moment(r.validity_period_end).utc().format('DD/MM/YYYY')})</Typography>
                      )
                    }
                  </Grid>
                  <Grid item style={{textAlign: 'center'}} xs={3}>
                    <Typography variant="caption" style={{ color: "#444444" }}><b><NumberFormat 
                      value={r.rakeback_value / 100 }
                      decimalScale={2} 
                      displayType={'text'} 
                      thousandSeparator="."
                      decimalSeparator=","
                      fixedDecimalScale="true"
                      /></b><SenseiCoin width={12} margin='0 0 0 5px'/></Typography>
                  </Grid>
                  <Grid item style={{textAlign: 'center'}} xs={3}>
                    <Typography variant="caption" style={{ color: "#444444" }}><b><NumberFormat 
                      value={r.discount / 100 }
                      decimalScale={2} 
                      displayType={'text'} 
                      thousandSeparator="."
                      decimalSeparator=","
                      fixedDecimalScale="true"
                      /></b><SenseiCoin width={12} margin='0 0 0 5px'/></Typography>
                  </Grid>
                  <Grid item style={{textAlign: 'right'}} xs={3}>
                    <Typography variant="body1" style={{ color: "#444444" }}><b><NumberFormat 
                      value={value / 100 }
                      decimalScale={2} 
                      displayType={'text'} 
                      thousandSeparator="."
                      decimalSeparator=","
                      fixedDecimalScale="true"
                      /></b><SenseiCoin margin='0 0 0 5px'/></Typography>
                  </Grid>
                </Grid>
                
              </div>
            )
          })
        ) : (
          <Typography variant="body1">Não há resultados aqui</Typography>
        )
        }
      </div>
    )
  }
}

export default withStyles(styles)(ResultsList)