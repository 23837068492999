import React, { Component } from 'react'
import BasicPageLayout from '../common/BasicPageLayout';
import BasicTitle from '../common/BasicTitle'
import { Grid, Button, ButtonGroup } from '@material-ui/core';
import MyResults from '../results/MyResults';
import MyReferralResults from '../results/MyReferralResults';
import MyNegativeResults from '../results/MyNegativeResults';


class Results extends Component {

  state = {
    active: 'benefits'
  }

  changeView = (view) => {
    this.setState({ active: view })
  }

  render() {
    const { active } = this.state
    return (
      <BasicPageLayout selectedMenu='results'>
        <BasicTitle>Meus resultados</BasicTitle>

        <Grid container spacing={3} style={{ marginTop: 16 }}>
          <Grid item xs={12}>
            <ButtonGroup color="primary" aria-label="outlined primary button group">
              <Button onClick={() => { this.changeView('benefits')}} variant={ active === 'benefits' ? 'contained' : 'outlined' }>Meus benefícios</Button>
              <Button onClick={() => { this.changeView('affiliateds')}} variant={ active === 'affiliateds' ? 'contained' : 'outlined' }>Benefícios afiliados</Button>
              <Button onClick={() => { this.changeView('negatives')}} variant={ active === 'negatives' ? 'contained' : 'outlined' }>Resultados negativos</Button>
            </ButtonGroup>

          </Grid>
        </Grid>

        {
          active === 'benefits' ?
            <MyResults />
          : active === 'affiliateds' ?
            <MyReferralResults />
          : active === 'negatives' ?
          <MyNegativeResults /> : null
         }

      </BasicPageLayout>
    )
  }
}

export default Results