import React from 'react'
import { Dialog, CircularProgress } from '@material-ui/core'
import GreenTitle from '../common/GreenTitle'
import GreenDivider from '../common/GreenDivider'
import TrophyIcon from '../common/TrophyIcon'
import StyledParagraph from '../common/StyledParagraph'
import withStyles from '@material-ui/core/styles/withStyles'
import BasicButton from '../common/BasicButton'


const styles = theme => ({
  center: {
    margin:'8px auto'
  }
})

const BenefitActivationInstructions = ({partnerName, identifierNeeded, link, open, classes, counter, showButton, handleCloseInstructions}) => {

  return(
    <Dialog open={open} 
      PaperProps={{
        style: {
          padding:'32px',
          textAlign:'center',
          boxSizing: 'border-box'
        }
      }}
    >
      <GreenTitle>ESTAMOS QUASE LÁ!</GreenTitle>
      <div className={classes.center}>
        <TrophyIcon/>
      </div>
      <GreenTitle>SIGA AS INSTRUÇÕES</GreenTitle>
      <div className={classes.center}>
        <GreenDivider/>
      </div>
      <StyledParagraph padding='0' textAlign='center'>
        <strong>1.</strong> Você será redirecionado para a página da <strong>{partnerName}</strong> com nosso código de bônus. Nesta página, você deverá criar uma nova conta.
      </StyledParagraph>
      <StyledParagraph padding='0' textAlign='center'>
        <strong>2.</strong> Criada a sua conta, volte para cá e nos informe o seu <strong>{identifierNeeded}</strong>.
      </StyledParagraph>
      <StyledParagraph padding='0' textAlign='center'>
        ATENÇÃO! Precisamos dessa informação para confirmar seu benefício! Então lembre de voltar e informar, ok? =)
      </StyledParagraph>
      
      {
        !showButton ? (
          <div>
            <StyledParagraph padding='0' textAlign='center'>
              <strong>{counter}</strong>s
            </StyledParagraph>
            <div className={classes.center}>
              <CircularProgress/>
            </div>
          </div>
        ) : (
          <BasicButton 
            type="button" 
            handleClick={()=>{
              
              handleCloseInstructions()
            }}
          >
            Seguir para {partnerName}
          </BasicButton>
        )
      }
      
    </Dialog>
  )
}

export default withStyles(styles)(BenefitActivationInstructions)