        
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import SenseiCoin from '../common/SenseiCoin'
import StatusWidget from '../common/StatusWidget'
import GetAppIcon from '@material-ui/icons/GetApp';

class OrdersList extends Component {
  
  render() {
    const { docs = [], isLoading } = this.props;
   
    return (
        <div>
            <Grid container spacing={2} alignItems="center" style={{ marginTop: 16, marginBottom: 8, borderBottom: '1px solid #dedede' }}>
                      <Grid item xs={1}><Typography variant="caption">Data</Typography></Grid>
                      <Grid item xs={2}><Typography variant="caption">Usuário</Typography></Grid>
                      <Grid item xs={2}><Typography variant="caption">Compra</Typography></Grid>
                      <Grid item xs={2}><Typography variant="caption">Identificador </Typography></Grid>
                      <Grid item xs={2}><Typography variant="caption"><SenseiCoin /> Valor </Typography></Grid>
                      <Grid item xs={2}><Typography variant="caption">Status</Typography></Grid>
                      <Grid item xs={1}><Typography variant="caption">Recibo</Typography></Grid>
            </Grid>
             { docs.length ? docs.map(order => (
                  <div key={order._id}>
                      <Grid container spacing={1}  alignItems="center"  style={{ borderBottom: '1px solid #ededed' }}>
                      <Grid item xs={1}>
                          <Typography variant='caption'><b>{moment(order.created_at).format('DD/MM/YYYY')}</b></Typography>
                      </Grid>
                      <Grid item xs={2}>
                         <Typography variant='caption' style={{display:'block'}}>{order.user.name}</Typography>
                         <Typography variant='caption' style={{display:'block'}}>{order.user.email}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                         <Typography variant='caption'><span style={{color: '#999'}}>{order.quantity}x</span></Typography>
                         <Typography variant='caption'>{order.product.name}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="caption">{order.identifier_value}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body1">
                            <SenseiCoin />
                            <b><NumberFormat 
                            value={ order.total/100} 
                            displayType={'text'} 
                            decimalScale={2}
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale="true"
                            style={{ marginLeft: 8 }}
                            /></b>
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <StatusWidget status={order.status} />  
                      </Grid>
                      <Grid item xs={1}>
                        {order.receipt && 
                        <a href={order.receipt} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#000000de'}}>
                          <GetAppIcon />
                        </a>
                        }
                        {order.comment !== '' && 
                        <Typography variant='caption' style={{display:'block'}}>{order.comment}</Typography>
                        }
                      </Grid>
                    </Grid>
                  </div>
            )) : 
            <Grid container>  
              <Grid item>{isLoading ? 'Carregando...' : 'Não há registros.'}</Grid>
            </Grid> }
        </div>
    )
  }
}


export default withRouter(OrdersList)

      