import React, { Component } from 'react'
import BasicButton from '../../common/BasicButton'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {CircularProgress, Typography, Grid } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter} from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux'
import { changePassword } from '../actions'

const styles = theme => ({
  main: {
    color: "#CCC",
  },
  label: {
    fontSize: '12px'
  },
  input: {
    fontSize: '15px'
  }
})

class UserDetailsForm extends Component{  

state = {
  oldPassword:'',
  newPassword:'',
  confirmNewPassword: '',
  snackbarOpen: false,
  snackMessage: '',
  vertical: 'bottom',
  horizontal: 'center',
  isFetching: false,
  isLoading: false,
  copied: false,
}


handleClose = () => {
  this.setState((state) => ({ ...state, snackbarOpen: false }));
};

handleSubmit = () => {
  this.setState({isLoading: true})
  const {  oldPassword, confirmNewPassword } = this.state;
  changePassword(oldPassword, confirmNewPassword).then(res => {
    this.setState({ isLoading: false, snackbarOpen: true, snackMessage: 'Senha atualizada com sucesso', oldPassword: '', newPassword: '', confirmNewPassword: '' });
  }).catch(err => {
    this.setState({ isLoading: false, snackbarOpen: true, snackMessage: 'Erro ao atualizar a senha' });
  })
}

handleChange = (event) => {
  const value = event.target.value
  const field = event.target.name
  this.setState({ [field]: value })
}

handleCopy = () => {
  this.setState({copied: true})
  setTimeout(()=>{
    this.setState({copied: false})
  }, 3000)
}


render() {
  const { isFetching, isLoading, vertical, horizontal, snackbarOpen, snackMessage, oldPassword, newPassword, confirmNewPassword} = this.state
  const { classes } = this.props
  

  return (
    isLoading ? (
      <Typography variant='body1'>Carregando...</Typography>
    ) : (
      <Grid container>
        <Grid item xs={12} sm={9} md={6} lg={4}>
          <ValidatorForm
          ref="form"
          onSubmit={this.handleSubmit}
          className={classes.main}
        >

          <TextValidator
            fullWidth
            label="Senha Atual"
            onChange={this.handleChange}
            name="oldPassword"
            value={oldPassword}
            type='password'
            validators={['required']}
            errorMessages={['Preencha este campo']}
            margin="normal"
            InputProps={{ classes: { root: classes.input } }}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
          />

          <TextValidator
            fullWidth
            label="Nova Senha"
            onChange={this.handleChange}
            name="newPassword"
            value={newPassword}
            type='password'
            validators={['required']}
            errorMessages={['Preencha este campo']}
            margin="normal"
            InputProps={{ classes: { root: classes.input } }}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
          />

          <TextValidator
            fullWidth
            label="Confirme Nova Senha"
            onChange={this.handleChange}
            name="confirmNewPassword"
            value={confirmNewPassword}
            type='password'
            validators={['required', `matchRegexp:^${newPassword}$`]}
            errorMessages={['Preencha este campo', 'As senhas não são iguais']}
            margin="normal"
            InputProps={{ classes: { root: classes.input } }}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
          />

          <BasicButton 
            fullWidth
            color="primary"
            type="submit"
            disabled={isFetching}
            margin='10px 0px 0px 0px'
          >
            {this.state.isFetching && <CircularProgress size={20} />} Atualizar senha
          </BasicButton>
        </ValidatorForm>
      
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`}
          open={snackbarOpen}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
        message={<span id="message-id">{snackMessage}</span>}
        />
        </Grid>
      </Grid>
    )
  )
}
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  }
}

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(UserDetailsForm)))