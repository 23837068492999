import React from 'react'
import { Typography, Grid, Button, Container } from '@material-ui/core';


export default function CardSalas(props) {


    return (
        <Grid item xs={12} md={3} style={{ marginBottom: "16px", padding: "16px" }}>
            <div style={{ background: "white", borderRadius: "12px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", width: "100%", boxSizing: "border-box" }}>
                <Grid container alignItems="center" style={{ background: "black", borderRadius: "11px 11px 0 0" }}>
                    <Grid item xs={12} style={{ textAlign: "center", borderRadius: "11px 11px 0 0" }}>
                        <img src={props.img} alt={props.alt} style={{ width: "100%", borderRadius: "11px 11px 0 0" }}></img>
                    </Grid>
                </Grid>
                <Container>
                    <Grid container >
                        <Grid item xs={12}>
                            <Typography style={{ textAlign: "left", marginTop: "8px", fontSize: "22px", fontWeight: 700, color: "#3D3D3D" }} >{props.sala}</Typography>
                            <Typography style={{ textAlign: "left", fontSize: "12px", color: "#565656", marginTop: "8px" }} >A cada <b>$10 de rake gerado</b> na {props.sala} <b>você ganha 1 ticket</b> para participar do sorteio</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ textAlign: "left", fontSize: "12px", color: "#565656" }} >{props.tipo}</Typography>
                            <div style={{ background: "rgba(196, 196, 196, 0.4)", height: "1px", width: "100%", margin: "3% 0" }} />
                        </Grid>
                        <Grid item>
                            {
                                props.beneficios.map((beneficio, i) => <Typography key={i} style={{ textAlign: "left", fontSize: "15px", color: "#2C2C2C" }}><img src="./check.svg" alt="Check" style={{ marginRight: "8px", marginTop: "3%" }} />{beneficio}</Typography>)
                            }
                        </Grid>
                        <Grid item xs={12} style={{ margin: "16px 0" }}>
                            <Button
                                variant="contained"
                                onClick={()=>{
                                    props.onClick()
                                }} size="large" 
                                style={{ background: "linear-gradient(90deg, rgba(109,0,0,1) 0%, rgba(163,9,8,1) 100%)", color: 'white', borderRadius: "10px", fontWeight: 600, fontSize: "20px", width: "100%" }}
                            >
                                ATIVAR
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Grid>
    );
}