import React, { Component } from 'react'
import { Switch, Route } from 'react-router'
import { withRouter } from 'react-router-dom'
import PrivateRoute from './auth/core/PrivateRoute'
import SignIn from './auth/SignIn'
import Logout from './auth/Logout'
import Landing from './pages/Landing'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Account from './pages/Account'
import Amplify from 'aws-amplify'
import amplify_config from '../amplify-config'
import {connect} from 'react-redux'
import {signInUser} from './auth/actions'
import Statement from './pages/Statement'
import { getPlatformsFetchData } from './wallet/actions'
import ActivationReport from './pages/ActivationReport'
import ErrorBoundary from './common/ErrorBoundary'
import AffiliatedActivationReport from './pages/AffiliatedActivationReport'
import TermsOfUse from './pages/TermsOfUse'
import Results from './pages/Results'
import SenseiTV from './pages/SenseiTV'
import HowItWorks from './pages/HowItWorks'
import Support from './pages/Support'
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import Store from './pages/Store'
import ProductDetails from './pages/ProductDetails'
import MyOrders from './pages/MyOrders'
import VideoModule from './pages/VideoModule'
import Raffle from './pages/Raffle'

Amplify.configure(amplify_config);

class App extends Component {

  constructor(props) {
    super(props)
    this.state = { 
      isFetching: true
    }
  }

  async componentDidMount() {

    try {
      const user = await Amplify.Auth.currentUserInfo();
      const tagManagerArgs = {
          gtmId: process.env.REACT_APP_GTM_ID
      }
    
      TagManager.initialize(tagManagerArgs)
      if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
      } 
      
      
      if(user){
        this.props.authenticateUser(user)
        this.props.getPlatforms()
      }
      this.setState({isFetching: false})
    }
    catch(e) {
      this.setState({ isFetching: false });
    }
  }

  render() {
    const { isFetching } = this.state;
    const { auth } = this.props
    return (
      !isFetching && (
        <ErrorBoundary>
          <Switch>
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/dashboard' component={Home} />
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/activation/:id' component={ActivationReport} />
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/affiliated-activation/:id' component={AffiliatedActivationReport} />
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/account' component={Account} />
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/school' component={SenseiTV} />
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/school/modules/:id' component={VideoModule} />
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/statement' component={Statement} />
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/how-it-works' component={HowItWorks} />
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/support' component={Support} />
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/store' component={Store} />
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/store/products/:id' component={ProductDetails} />
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/store/my-orders' component={MyOrders} />
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/results' component={Results} />
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} exact path='/raffle' component={Raffle} />
            <Route exact path='/' render={(props) => (
              <Landing {...props} />
              )}/>
            <Route exact path='/login' render={(props) => (
              <SignIn {...props} />
              )}/>
            <Route exact path='/terms-of-use' render={(props) => (
              <TermsOfUse {...props} />
              )}/>
            <Route exact path='/logout' render={(props) => (
              <Logout {...props} />
              )}/>
            <PrivateRoute isAuthenticated={Object.keys(auth).length > 0 && auth.constructor === Object} component={NotFound}/>
          </Switch>
        </ErrorBoundary>
      )
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authenticateUser: (user) => dispatch(signInUser(user)),
    getPlatforms: () => dispatch(getPlatformsFetchData())
  }
}



const mapStateToProps = ({ auth }) => {
  return {
    auth,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
