import React, {Component} from 'react'
import BasicLink from '../common/BasicLink'
import Accordion from '@material-ui/core/Accordion';
import { AccordionSummary, AccordionDetails, List, AppBar, Box} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { connect } from 'react-redux'
import SideMenu from '../common/SideMenu';
import MenuIcon from '@material-ui/icons/Menu';


class BasicHeader extends Component{

  state = {
    user: {},
    isUserInfoOpen: false,
    menuOpen: false
  }

  headerStyle = {
    height: 60,
    background: '#090909',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '2px 2px 8px #9e9e9e'
  }

  logoStyle = {
    marginLeft: '16px',
    width: '120px',
    marginTop: 12
  }

  contentStyle ={
    marginTop: '2px'
  }

  authInfofStyle ={
    background: 'white',
    color: '#444',
    borderRadius: 0
  }

  accordionStyle ={
    borderRadius: 0,
    dropShadow: '2px 2px 2px #444',
    color: '#444'
  }
  
  usernameStyle ={
    fontSize: '12px',
    fontWeight: '700',
    color: '#444',
    marginBottom: '0'
  }

  menuIconStyle={
    color: 'black',
    display: 'block',
    fontSize: '24px',
    height: '100%'
  }

  handleOpenUserInfo() {
    this.setState((state)=>({
      isUserInfoOpen: !this.state.isUserInfoOpen
    }))
  }

  handleCloseMenu = () => {
    this.setState({menuOpen: false})
  }

  handleOpenMenu = () => {
    this.setState({menuOpen: true})
  }

  render() {
    const {selectedMenu} = this.props
    return(
      <AppBar style={{position: 'fixed', width: '100%', zIndex: '1300'}}>
        <SideMenu selected={selectedMenu} open={this.state.menuOpen} handleCloseMenu={this.handleCloseMenu} />
        <header style={this.headerStyle}>
          <Box component="div" display={{ xs: 'inline-flex', sm: 'none' }} onClick={this.handleOpenMenu}>
            <div style={{background: 'white', padding: '10px'}}>
              <MenuIcon style={this.menuIconStyle} />
            </div>
            <div style={this.contentStyle}>
              <img style={this.logoStyle} alt="logo" src={process.env.PUBLIC_URL + '/senseipoker-logo.png'}/>
            </div>
          </Box>
          <Box component="div" display={{ xs: 'none', sm: 'inline-flex' }}>
            <div style={this.contentStyle}>
              <img style={this.logoStyle} alt="logo" src={process.env.PUBLIC_URL + '/senseipoker-logo.png'}/>
            </div>
          </Box>
          {this.props.auth.user_email && (
            <div style={this.authInfofStyle} >
              <Accordion expanded={this.state.isUserInfoOpen} style={this.accordionStyle} onClick={()=>this.handleOpenUserInfo()} >
                <AccordionSummary style={{height:'60px', boxSizing:'border-box'}} expandIcon={<ExpandMoreIcon />}>
                  <img  alt="avatar" style={{width: '30px', verticalAlign:'middle'}} src={process.env.PUBLIC_URL + '/ic_ninja.svg'}/>
                  <div style={{display: 'inline-block', marginLeft:'15px'}}>
                    <p style={this.usernameStyle}>
                      {this.props.auth.user_name.split(' ')[0]}
                    </p>
                    <p style={{marginTop: 0, fontSize:'10px'}}>
                      {this.props.auth.user_email}
                    </p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <li style={{marginBottom: '20px'}}><BasicLink to='/account'><SettingsOutlinedIcon style={this.menuIconStyle}/> MINHA CONTA</BasicLink></li>
                    <li><BasicLink to='/logout'><ArrowBackOutlinedIcon style={this.menuIconStyle}/>SAIR</BasicLink></li>
                  </List>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </header>

      </AppBar>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  }
}

export default connect(mapStateToProps)(BasicHeader)