import React, { Component } from 'react'
import { connect } from 'react-redux'
import BasicPageLayout from '../common/BasicPageLayout'
import { getResultsFromActivation, getNegativeResultsFromActivation } from '../results/actions'
import { getActiveActivations, getActivation } from '../benefits/actions'
import Pagination from '@material-ui/lab/Pagination';
import BasicTitle from '../common/BasicTitle'
import BackButton from '../common/BackButton'
import SenseiCoin from '../common/SenseiCoin'
import ResultsList from '../results/ResultsList'
import { Card, Grid, CardContent, Typography, LinearProgress } from '@material-ui/core';
import NumberFormat from 'react-number-format'
import ReactGA from 'react-ga';
import NegativeResultsList from '../results/NegativeResultsList'

class ActivationReport extends Component {
  state = {
    activation: {},
    results: [],
    negeativeResults: [],
    registry_date: {},
    isLoadingResults: false,
    totalPages: 1,
    currentPage: 1,
    isFetching: false
  }

  async componentDidMount() {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    this.setState({ isFetching: true })
    let activation = await getActivation(this.props.match.params.id)
    this.setState({ isFetching: false })
    this.setState({ activation: activation.data })
    this.getResults({})
    this.getNegativeResults({})
  }

  getResults({ page = 1, limit = 10 }) {
    const { activation, registry_date } = this.state
    let query = {
      activation: activation._id,
      ...registry_date
    }
    this.setState({ isLoadingResults: true })
    getResultsFromActivation({ page, limit, ...query })
      .then(res => {
        this.setState({
          results: res.data.docs,
          isLoadingResults: false,
          totalPages: res.data.pages,
          currentPage: Number(res.data.page)
        })
      })
      .catch(err => {
        this.setState({ isLoadingResults: false })
      })
  }

  getNegativeResults({ page = 1, limit = 10 }) {
    const { activation, registry_date } = this.state
    let query = {
      activation: activation._id,
      ...registry_date
    }
    this.setState({ isLoadingResults: true })
    getNegativeResultsFromActivation({ page, limit, ...query })
      .then(res => {
        this.setState({
          negativeResults: res.data.docs,
          isLoadingResults: false,
          totalPages: res.data.pages,
          currentPage: Number(res.data.page)
        })
      })
      .catch(err => {
        this.setState({ isLoadingResults: false })
      })
  }

  handleSearchByDate = (registry_date) => {
    this.setState({ registry_date }, () => {
      this.getResults({})
    });
  }

  handlePaginate = (event, value) => {
    this.getResults({ page: value })
  }

  handlePaginateNegative = (event, value) => {
    this.getNegativeResults({ page: value })
  }

  render() {
    const { activation, totalPages, currentPage, results, isLoadingResults, negativeResults, isFetching } = this.state
    return (
      <BasicPageLayout>
        <BackButton />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BasicTitle>
              Benefício Ativo
            </BasicTitle>
            {
              activation.benefit && (
                <div style={{ marginTop: '28px' }}>
                  <p><strong>Plataforma:</strong> {activation.benefit.partner.name}</p>
                  <p style={{ textTransform: 'capitalize' }}><strong>{activation.benefit.identifier_needed}:</strong> {activation.identifier_needed_value}</p>
                  <p><strong>Rakeback:</strong> {activation.rakeback_player}%</p>
                </div>
              )
            }
          </Grid>
          {
            isFetching ? (
              <Grid container style={{ padding: 12 }}>
                <Grid item xs={12}>
                  <LinearProgress /> 
                </Grid>
              </Grid>
            ) : (
              <div>

                <Grid container style={{ paddingBottom: 24, margin: 0 }} spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Card>
                      <CardContent>
                        <BasicTitle>Total Gerado</BasicTitle>
                        <Typography variant="h6" style={{ marginTop: '16px' }}>
                          <b style={{ marginRight: 8 }}><NumberFormat
                            value={activation.totalGenerated / 100}
                            displayType={'text'}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            prefix="$"
                            fixedDecimalScale="true"
                          /></b>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Card>
                      <CardContent>
                        <BasicTitle>Total Recebido</BasicTitle>
                        <Typography variant="h6" style={{ marginTop: '16px' }}>
                          <SenseiCoin />

                          <b style={{ marginLeft: 8 }}><NumberFormat
                            value={activation.totalReceived / 100}
                            displayType={'text'}
                            thousandSeparator="."
                            decimalScale={2}
                            decimalSeparator=","
                            fixedDecimalScale="true"
                          /></b>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container style={{ padding: 12 }}>

                  <Grid item md={6} sm={12} xs={12}>
                    <BasicTitle margin='0 0 32px 0'>
                      Resultados
                      </BasicTitle>
                    <Typography variant='caption'>
                      Descontos são provenientes de resultados negativos previamente gerados. Quando o jogador utiliza bônus e promoções, a sala desconta esses valores do rakeback, podendo gerar saldo negativo (regime de cálculo Net Revenue).
                      </Typography>
                    {
                      isLoadingResults ? (<Typography variant="body1">Carregando...</Typography>) : (
                        <ResultsList results={results} />
                      )
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={currentPage} count={totalPages} onChange={this.handlePaginate} />
                  </Grid>
                  {
                    (negativeResults && negativeResults.length > 0) && (
                      <Grid item xs={12}>
                        <Grid item md={6} sm={12} xs={12}>
                          <BasicTitle margin='0 0 32px 0'>
                            Resultados Negativos
                            </BasicTitle>
                          <Typography variant='caption'>
                            O rakeback é calculado em regime de Net Revenue, ou seja, quando o jogador utiliza bônus e promoções na Sala, a própria Sala subtrai estes valores do rakeback do jogador, podendo o rakeback gerar saldo negativo.
                            </Typography>
                          {
                            isLoadingResults ? (<Typography variant="body1">Carregando...</Typography>) : (
                              <NegativeResultsList results={negativeResults} />
                            )
                          }
                        </Grid>
                        <Grid item xs={12}>
                          <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={currentPage} count={totalPages} onChange={this.handlePaginateNegative} />
                        </Grid>
                      </Grid>
                    )
                  }
                </Grid>
              </div>
            )
          }

        </Grid>

      </BasicPageLayout>
    )
  }
}

const mapStateToProps = ({ activeActivations, auth }) => {
  return {
    activeActivations,
    auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getActivations: () => dispatch(getActiveActivations()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivationReport);