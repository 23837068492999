
import Auth from '../../../utils/auth';
const baseApi = process.env.REACT_APP_API_URL;


export const headers = () => { 
  const token = Auth.getToken()
  return new Headers({
    'Content-Type': 'application/json',
    'Authorization': token
  }) 
}

export const routes = {
  me: `${baseApi}/me`,
  changePassword: `${baseApi}/me/change-password`,
  generateHash: `${baseApi}/me/generate-hash`,
} 

