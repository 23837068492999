import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import SignInForm from './form/SignInForm'
import { signInFetchData, signUp, validate, resendSignUpConfirmation, completeNewPasswordChallenge, confirmSignInFetchData } from './actions'
import BoxCenter from './UI/box/Login'
import BasicButton from '../common/BasicButton'
import StyledParagraph from '../common/StyledParagraph'
import GreenDivider from '../common/GreenDivider'
import GreenTitle from '../common/GreenTitle';
import LandingHeader from '../common/LandingHeader';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Divider from '@material-ui/core/Divider';
import { Dialog, CircularProgress } from '@material-ui/core';
import SignUpForm from './form/SignUpForm';
import Auth from '../../utils/auth';
import { ValidatorForm ,TextValidator } from 'react-material-ui-form-validator';
import translateCognitoErrorMessage from '../../utils/translateCognitoErrorMessage';
import PhoneInput from 'react-phone-input-2';
import { getPlatformsFetchData } from '../wallet/actions'
import TokenSignin from './TokenSignIn'

class SignIn extends Component {

  state = {
    isFetching: false,
    didInvalidate: false,
    snackbarOpen: false,
    signupOpen: false,
    validationOpen: false,
    confirmedOpen: false,
    codeResending: false,
    newPasswordChallengeOpen: false,
    challengeSuccessOpen: false,
    newPhoneNumber: '',
    newName: '',
    newPassword: '',
    validationCode: '',
    snackMessage: '',
    vertical: 'bottom',
    tokenMfaOpen: false,
    horizontal: 'center',
  }

  componentDidMount(){
    if(Object.keys(this.props.auth).length > 0){
      this.props.history.push('/dashboard')
    }

    if(this.props.signUpInfo.open) {
      this.setState({
        signupOpen: true
      })
    }
  }

  login({username, password}) {
    this.setState((state) => ({
      isFetching: true,
      didInvalidate: false,
    }))

    this.props.signIn(username, password)
    .then(body => {
      this.props.getPlatforms()
      if(body.challengeName === "NEW_PASSWORD_REQUIRED"){
        this.handleOpenModalNewPassword()
        this.setState((state) => ({
          isFetching: false,
          user: body
        }))
      } else {
        if (body.challengeName === "SOFTWARE_TOKEN_MFA") {
          this.handleOpenModalTokenMfa()
          this.setState((state) => ({
            isFetching: false,
            user: body
          }))
        } else {
          this.setState((state) => ({
            isFetching: false,
            didInvalidate: false,
          }))
          this.props.history.push('/dashboard')
        }
      }
    })
    .catch(err => {
      this.setState((state) => ({
        isFetching: false,
        didInvalidate: true,
        snackbarOpen: true,
        snackMessage: translateCognitoErrorMessage(err)
      }))
    })
  }

  signup({name, email, phoneNumber, password}) {
    this.setState((state) => ({
      isFetching: true,
      didInvalidate: false,
    }))

    const user = {
      name,
      email,
      phoneNumber,
      password
    }


    if(this.props.signUpInfo.ref) {
      user.affiliator = this.props.signUpInfo.ref
    }

    this.props.signUp(user)
    .then(body => {
      this.setState((state) => ({
        isFetching: false,
        didInvalidate: false,
      }))

      //setting first login to display stuff on the dashboard
      localStorage.setItem('firstLogin', true);
      this.handleCloseModal();
      this.handleOpenModalValidation();
    })
    .catch(err => {
      this.setState((state) => ({
        isFetching: false,
        didInvalidate: true,
        snackbarOpen: true,
        snackMessage: translateCognitoErrorMessage(err)
      }))
    })
  }

  validate(validationCode) {
    this.setState((state) => ({
      isFetching: true,
      didInvalidate: false,
    }))

    this.props.validate(validationCode)
    .then(body => {
      this.setState((state) => ({
        isFetching: false,
        didInvalidate: false,
      }))

      this.handleCloseModalValidation();
      this.handleOpenModalConfirmed();
    })
    .catch(ex => {
      this.setState((state) => ({
        isFetching: false,
        didInvalidate: true,
        snackbarOpen: true,
        snackMessage: ex
      }))
    })
  }

  resendCode() {
    this.setState((state)=>({
      codeResending: true
    }))

    this.props.resendSignUp()
    .then(()=>{
      this.setState((state) => ({
        codeResending: false,
        snackbarOpen: true,
        snackMessage: 'Reenviamos o código. Verifique seu email'
      }));

    })
    .catch(ex => {
      this.setState((state) => ({
        codeResending: false,
        didInvalidate: true,
        snackbarOpen: true,
        snackMessage: ex
      }))
    })
  }

  completeNewPasswordChallenge(user, newPassword, requiredAttributes) {
    this.setState((state) => (
      {
        isFetching: true
    }
    ))

    this.props.completeNewPassword(user, newPassword, requiredAttributes)
    .then(res=>{
      this.setState((state) => (
        {
          isFetching: false
      }))

      localStorage.setItem('firstLogin', true);
      this.handleCloseModalNewPassword()
      this.handleOpenModalChallengeSuccess()
    })
    .catch(err=>{
      this.setState((state) => (
        {
          isFetching: false,
          didInvalidate: true,
          snackbarOpen: true,
          snackMessage: err
      }
      ))
    })
  }

  handleChange = (event) => {
    const value = event.target.value
    const field = event.target.name
    this.setState({ [field]: value })
  }

  handleClose = () => {
    this.setState((state) => ({ ...state, snackbarOpen: false }));
  };

  handleOpenModal = () => {
    this.setState((state) => ({ ...state, signupOpen: true }));
  };

  handleCloseModal = () => {
    this.setState((state) => ({ ...state, signupOpen: false }));
  };

  handleOpenModalValidation = () => {
    this.setState((state) => ({ ...state, validationOpen: true }));
  };

  handleCloseModalValidation = () => {
    this.setState((state) => ({ ...state, validationOpen: false }));
  };
  handleOpenModalConfirmed = () => {
    this.setState((state) => ({ ...state, confirmedOpen: true }));
  };

  handleCloseModalConfirmed= () => {
    this.setState((state) => ({ ...state, confirmedOpen: false }));
  };

  handleOpenModalChallengeSuccess = () => {
    this.setState((state) => ({ ...state, challengeSuccessOpen: true }));
  };

  handleCloseModalChallengeSuccess = () => {
    this.setState((state) => ({ ...state, challengeSuccessOpen: false }));
  };

  handleOpenModalNewPassword= () => {
    this.setState((state) => ({ ...state, newPasswordChallengeOpen: true }));
  };

  handleCloseModalNewPassword= () => {
    this.setState((state) => ({ ...state, newPasswordChallengeOpen: false }));
  };

  submitToken = (user, code) => {
    this.props.confirmSignIn(user, code).then(c => {
      this.props.history.push('/dashboard')
    }).catch(e => {
      this.setState((state) => (
        {
          isCompletingNewPassword: false,
          didInvalidate: true,
          snackbarOpen: true,
          snackMessage: 'Código inválido'
        }
      ))
    })
  }

  handleOpenModalTokenMfa = () => {
    this.setState((state) => ({ ...state, tokenMfaOpen: true }));
  };

  render() {
    const { isFetching, vertical, horizontal, snackbarOpen, tokenMfaOpen, user } = this.state

    return (
      <div>
        <LandingHeader />
        <div style={{padding:'30px 7%'}}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="stretch"
            spacing={6}
          >  
            <Grid item xs={12} sm={5} lg={4}>
              <BoxCenter>
                <GreenTitle padding="20px 20px 0 20px" >
                  ENTRAR
                </GreenTitle>
                <GreenDivider marginLeft='20px'/>
                <SignInForm 
                  handleSubmit={(data) => this.login(data)}
                  isFetching={isFetching}
                />
                <Snackbar
                  anchorOrigin={{ vertical, horizontal }}
                  key={`${vertical},${horizontal}`}
                  open={snackbarOpen}
                  onClose={this.handleClose}
                  ContentProps={{
                    'aria-describedby': 'message-id',
                  }}
                message={<span id="message-id">{this.state.snackMessage}</span>}
                />
              </BoxCenter>
            </Grid>

            <Divider className="divider" style={{marginTop: '90px', height:'330px'}} orientation="vertical" flexItem />
            
            
            <Grid item className="cadastro" xs={12} sm={5} lg={4} style={{ textAlign: 'center' }}>
                <BoxCenter>
              <Grid container>
                  <Grid item xs={12}>
                    <GreenTitle padding="20px 20px 0 20px" textAlign='center'>
                      CADASTRE-SE
                    </GreenTitle>
                  </Grid>
                  <Grid item xs={12}>
                    <GreenDivider marginLeft='auto' marginRight='auto'/>
                  </Grid>
                  <Grid item xs={12}>
                  <img alt="troféu" style={{width: '140px' }}src={process.env.PUBLIC_URL + '/senseiclub-vertical.png'}/>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledParagraph textAlign='center' margin='15px auto'>Conheça nossos benefícios exclusivos para concorrer a <b>+ de R$100.000,00</b> em prêmios!</StyledParagraph>
                  </Grid>
                  <Grid item xs={12}>
                    <BasicButton handleClick={this.handleOpenModal} style={{marginTop: '0', marginBottom: '20px !important'}}>CADASTRE-SE</BasicButton>
                  </Grid>
                </Grid>
                </BoxCenter>
            </Grid>
            
            <Dialog open={this.state.signupOpen} onClose={this.handleCloseModal}>
              <GreenTitle padding="20px 20px 0 20px" textAlign='center'>
                  CADASTRE-SE
                </GreenTitle>
              <GreenDivider marginLeft='auto' marginRight='auto'/>
              <StyledParagraph textAlign="center" padding='0 20px'>Caso você já tenha conta na <b>SenseiWallet</b>, não é necessário se cadastrar. Basta fazer o login com as mesmas credenciais de acesso.</StyledParagraph>
              <StyledParagraph textAlign="center" padding='0 20px'>Preencha os campos abaixo para ganhar seus benefícios!</StyledParagraph>
              <div style={{padding:'20px'}}>
                <SignUpForm handleSubmit={(data)=>this.signup(data)} isFetching={this.state.isFetching} ></SignUpForm>
              </div>
            </Dialog>
            <Dialog open={this.state.validationOpen} disableBackdropClick={true} onClose={this.handleCloseModalValidation} style={{margin: 'auto', width: '400px', padding:'30px'}}>
              <GreenTitle padding="20px 20px 0 20px" textAlign='center'>
                  CONFIRME SUA CONTA!
                </GreenTitle>
              <GreenDivider marginLeft='auto' marginRight='auto'/>
              <StyledParagraph textAlign="center" padding='0 20px'>Informe aqui o código que te enviamos no seguinte email: <strong>{Auth.getValidationEmail()}</strong> </StyledParagraph>
              <ValidatorForm
                style={{
                  margin: 'auto'
                }}
                ref="form"
                onSubmit={()=>this.validate(this.state.validationCode)}
              >
                <TextValidator
                fullWidth
                value={this.state.validationCode}
                label="Código"
                type='text'
                onChange={this.handleChange}
                name="validationCode"
                validators={['required']}
                errorMessages={['Preencha esse campo']}
                margin="dense"/>
                <BasicButton margin='32px 0px 16px' onClick={()=>this.validate(this.state.validationCode)}>{isFetching && <CircularProgress size={20} />}CONFIRMAR CONTA</BasicButton>
                <span><div onClick={()=>this.resendCode()} style={{textDecoration: 'underline', color: '#444', marginBottom: '20px', textAlign:'center', display: 'block', cursor: 'pointer'}}>{this.state.codeResending ? 'Reenviando código...' : '-> Reenviar código'}</div></span>
              </ValidatorForm>
            </Dialog>
            <Dialog open={this.state.confirmedOpen} onClose={this.handleCloseModalConfirmed} style={{margin: 'auto', width: '400px', padding:'30px'}}>
              <GreenTitle padding="20px 20px 0 20px" textAlign='center'>
                  SUA CONTA FOI CONFIRMADA!
                </GreenTitle>
              <GreenDivider marginLeft='auto' marginRight='auto'/>
              <StyledParagraph textAlign="center" padding='0 20px'>Faça seu login para acessar os benefícios</StyledParagraph>
              <BasicButton handleClick={()=>this.handleCloseModalConfirmed()}>OK</BasicButton>
            </Dialog>
            
            <Dialog open={this.state.newPasswordChallengeOpen} onClose={this.handleCloseModalNewPassword}>
                <GreenTitle padding="20px 20px 0 20px" textAlign='center'>
                  COMPLETE SEU CADASTRO
                </GreenTitle>
                <GreenDivider marginLeft='auto' marginRight='auto'/>
                <StyledParagraph textAlign="center" padding='0 20px'>Informe seus dados e crie uma nova senha</StyledParagraph>
                <div style={{padding:'0 20px 20px'}}>
                <ValidatorForm
                  ref="form"
                  onSubmit={()=>this.completeNewPasswordChallenge(this.state.user, this.state.newPassword, {name: this.state.newName, phone_number: this.state.newPhoneNumber})}
                >
                  <div style={{padding: '20px'}}>
                    <TextValidator
                      fullWidth
                      label="Seu Nome"
                      type="text"
                      onChange={this.handleChange}
                      variant='outlined'
                      name="newName"
                      value={this.state.newName}
                      validators={['required']}
                      errorMessages={['Preencha esse campo']}
                      margin="dense"
                    />
                    <PhoneInput
                      country={'br'}
                      specialLabel='Whatsapp'
                      value={this.state.newPhoneNumber}
                      onChange={newPhoneNumber => this.setState({ newPhoneNumber })}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                    <TextValidator
                      fullWidth
                      label="Senha NOVA"
                      type='password'
                      onChange={this.handleChange}
                      variant='outlined'
                      name="newPassword"
                      value={this.state.newPassword}
                      validators={['required']}
                      errorMessages={['Preencha esse campo']}
                      margin="dense"
                    />
                  </div>
                  <BasicButton onClick={()=>this.completeNewPasswordChallenge(this.state.user, this.state.newPassword, {name: this.state.newName, phone_number: this.state.newPhoneNumber})}>
                    {isFetching && <CircularProgress size={20} />} Confirmar
                  </BasicButton>
                </ValidatorForm>
                </div>
            </Dialog>
            <Dialog open={this.state.challengeSuccessOpen} onClose={this.handleCloseModalChallengeSuccess}>
            <GreenTitle padding="20px 20px 0 20px" textAlign='center'>
                  SUA SENHA FOI ATUALIZADA!
                  </GreenTitle>
                <GreenDivider marginLeft='auto' marginRight='auto'/>
                <StyledParagraph textAlign="center" padding='0 20px'>Agora, você pode fazer o login com sua nova senha!</StyledParagraph>
                <BasicButton handleClick={this.handleCloseModalChallengeSuccess}>
                    OK
                  </BasicButton>
            </Dialog>
            <TokenSignin isOpen={tokenMfaOpen} handleSubmit={(user, answer) => this.submitToken(user, answer)} handleClose={this.handleCloseModalTokenMfa} isFetching={this.state.isSigningUp} user={user} />
          </Grid>
        </div>
      </div>
      
    )
  }
}

const mapStateToProps = ({ auth, signUpInfo }) => {
  return {
    auth,
    signUpInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (username, password) => dispatch(signInFetchData(username, password)),
    signUp: (user) => dispatch(signUp(user)),
    validate: (validationCode) => dispatch(validate(validationCode)),
    resendSignUp: () => dispatch(resendSignUpConfirmation()),
    completeNewPassword: (user, newPassword, requiredAttributes) => dispatch(completeNewPasswordChallenge(user, newPassword, requiredAttributes)),
    confirmSignIn: (user, code) => dispatch(confirmSignInFetchData(user, code)),
    getPlatforms: () => dispatch(getPlatformsFetchData())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn))
