import React from 'react'
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-scroll'
import { Link as LinkRouter} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({

  drawer: {
    width: '220px',
    flexShrink: 0
  }
}));

const LandingSideMenu = (props) => {

  const classes = useStyles();

  const handleCloseMenu = () => {
    props.handleCloseMenu()
  }

  return (
    <Drawer
      className={classes.drawer}
      open={props.open}
      onClose={props.handleCloseMenu}
      anchor="left"
      classes={{
        paper: classes.drawer,
      }}
    >
      <div style={{ padding: 16 }} onClick={handleCloseMenu}>
        <MenuIcon className={classes.menuIcon} />
      </div>
      <List>
        <Link to="resultados" spy={true} smooth={true}><ListItem button onClick={() => props.onClose()} style={{ margin: '10px 0' }}>
          <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>Resultados</Typography>} />
        </ListItem></Link>
        <Link to="benefits" spy={true} smooth={true}><ListItem button onClick={() => props.onClose()} style={{ margin: '10px 0' }}>
          <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>Benefícios</Typography>} />
        </ListItem></Link>
        <Link to="wallet" spy={true} smooth={true}><ListItem button onClick={() => props.onClose()} style={{ margin: '10px 0' }}>
          <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>E-Wallet</Typography>} />
        </ListItem></Link>
        <Link to="perguntas-frequentes" spy={true} smooth={true}><ListItem button onClick={() => props.onClose()} style={{ margin: '10px 0' }}>
          <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>FAQ</Typography>} />
        </ListItem></Link>
        <LinkRouter to="/login"> <ListItem button onClick={() => props.onClose()} style={{ margin: '10px 0' }}>
          <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle} style={{ color: "#f50057" }}>LOGIN</Typography>} />
        </ListItem></LinkRouter>
      </List>
    </Drawer>
  )
}


export default LandingSideMenu