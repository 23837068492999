import React, {Component} from 'react'
import { Grid, Typography, Paper, Button, CircularProgress } from '@material-ui/core'
import BenefitElement from './BenefitElement'
import BasicTitle from '../common/BasicTitle'
import {connect} from 'react-redux'
import {getBenefits, createActivation, getNonActiveActivations, getAffiliatedActivations, updateActivation, getActiveActivations, getAffiliatedPaginated} from './actions'
import { CSVLink } from "react-csv";
import { setSignUpInfoData } from '../auth/actions'
import BenefitActivationInstructions from './BenefitActivationInstructions'
import ActivationElement from './ActivationElement'
import AffiliatedActivationsList from './AffiliatedActivationsList'
import ActivationInformIdentifier from './ActivationInformIdentifier'
import Snackbar from '@material-ui/core/Snackbar';
import { Link } from 'react-router-dom'
import GetAppIcon from '@material-ui/icons/GetApp';
import ActivationsList from './ActivationsList'
import Pagination from '@material-ui/lab/Pagination'
import IdentifierSearch from '../common/IdentifierSearch'
import { getTotalResultsFromUser, getTotalReferalResultsFromUser } from  '../results/actions'


class BenefitsList extends Component{

  state={
    instructionOpen: false,
    partnerName: '',
    activationToInform: {},
    identifierNeeded: '',
    identifier_search_affiliated: '',
    identifier_search_active: '',
    linkToBenefit: '',
    activationsCSV: null,
    affiliatedActivationsCSV: null,
    activeActivations: [],
    totalLastResults: 0,
    totalResults: 0,
    totalAffiliatedLastResults: 0,
    totalAffiliatedResults: 0,
    totalPagesActive: 1,
    currentPageActive: 1,
    totalPagesAffiliated: 1,
    currentPageAffiliated: 1,
    isLoadingNonActive: false,
    currentPageNonActive: 1,
    totalPagesNonActive: 1,
    informIdentifierOpen: false,
    showButton: false,
    counter: 0,
    vertical: 'bottom',
    horizontal: 'center',
    snackbarOpen: false,
    snackMessage: '',
    isUpdatingActivation: false,
    isLoadingActiveActivations: false,
    isLoadingAffiliatedActivations: false,
    isLoadingNonActiveActivations: false
  }

  async componentDidMount(){
    await this.props.getAllBenefits()

    this.setState({
      isLoadingActiveActivations: true,
      isLoadingAffiliatedActivations: true
    })

    this.getActiveActivations({})
    this.getNonActiveActivations({})
    this.getAffiliatedActivationsPaginated({})
    let totalResults = await getTotalResultsFromUser(this.props.auth.id)
    this.setState({totalResults: totalResults.data})
    let totalAffiliatedResults = await getTotalReferalResultsFromUser(this.props.auth.id)
    this.setState({totalAffiliatedResults: totalAffiliatedResults.data})

    
    const { benefit } = this.props.signUpInfo;
    if(benefit) {
      this.handleOpenInstructions(benefit.link, benefit.partner_name, benefit.identifier_needed, benefit._id)
      this.props.setSignUpInfo({ ...this.props.signUpInfo, open: false, benefit: null })
    }
  }

  getActiveActivations = ({page=1, limit=10}) =>{
    const {identifier_search_active} = this.state

    const query = {page, limit}
    
    if(identifier_search_active !== '') {
      query.identifier_needed_value = identifier_search_active
    }

    this.props.getActiveActivations(query).then(r=>{
      this.setState({
        activeActivations: r.data.docs,
        totalPagesActive: Number(r.data.pages),
        currentPageActive: Number(r.data.page),
        isLoadingActiveActivations: false
      })
    })
  }
  
  getNonActiveActivations = ({page=1, limit=10}) =>{

    const query = {page, limit}

    this.props.getNonActiveActivations(query).then(r=>{
      this.setState({
        totalPagesNonActive: Number(r.data.pages),
        currentPageNonActive: Number(r.data.page),
        isLoadingNonActiveActivations: false
      })
    })
  }

  getAffiliatedActivationsPaginated = ({page=1, limit=10}) =>{
    const {identifier_search_affiliated} = this.state

    const query = {page, limit}
    
    if(identifier_search_affiliated !== '') {
      query.identifier_needed_value = identifier_search_affiliated
    }

    this.props.getAffiliatedPaginated(query).then(r=>{
      this.setState({
        totalPagesAffiliated: Number(r.data.pages),
        currentPageAffiliated: Number(r.data.page),
        isLoadingAffiliatedActivations: false
      })
    })
  }

  handleOpenInstructions = (link, partner, identifier, id) => {
    this.setState({
      counter: 10,
      partnerName: partner,
      identifierNeeded: identifier,
      linkToBenefit: link,
      showButton: false,
      instructionOpen: true
    })

    const interval = setInterval(() => {
      const c = this.state.counter - 1
      this.setState({counter: c})
    }, 1000)

    setTimeout(() => {
      clearInterval(interval)
      this.props.createActivation({ benefit: id }).then((res) => {
        this.props.getAllBenefits()
        this.getNonActiveActivations({})
      })
      .catch(err=>{
        this.setState({
          snackbarOpen: true,
          snackMessage: err.response.data.message
        })
      })

      this.setState({
        showButton: true
      })
    }, 10000)

  }


  handleCloseInstructions = () => {
    window.open(this.state.linkToBenefit, '_blank')
    this.setState({
      counter: 0,
        partnerName: '',
        identifierNeeded: '',
        linkToBenefit: '',
        instructionOpen: false,
        showButton: false
    })
    
  }

  handleOpenInformIdentifier = (activation) => {
    this.setState({
      informIdentifierOpen: true,
      activationToInform: activation,
    })
  }

  handleCloseInformIdentifier = () => {
    this.setState({informIdentifierOpen: false})
  }

  handleSubmitInformIdentifier = (activationId, identifierValue) => {
    this.setState({isUpdatingActivation: true})
    this.props.updateActivation(activationId, identifierValue).then(res=>{
      this.getNonActiveActivations()
      this.setState({
        informIdentifierOpen: false,
        isUpdatingActivation: false
      })
    })
    .catch(err=>{
      this.setState({
        snackbarOpen: true,
        snackMessage: err.response.data.message,
        isUpdatingActivation: false
      })
    })
  }

  handleClose = () => {
    this.setState((state) => ({ ...state, snackbarOpen: false }));
  };

  handlePaginate = (event, value) => {
    this.setState({
      isLoadingActiveActivations: true
    })
    this.getActiveActivations({page: value})
  }
  
  handlePaginateAffiliated = (event, value) => {
    this.setState({
      isLoadingAffiliatedActivations: true
    })
    this.getAffiliatedActivationsPaginated({page: value})
  }
 
  handlePaginateNonActive = (event, value) => {
    this.setState({
      isLoadingNonActiveActivations: true
    })
    this.getNonActiveActivations({page: value})
  }

  handleSearchActiveByIdentifier = (identifier_search_active) => {
    this.setState({identifier_search_active, isLoadingActiveActivations:true}, () => {
        this.getActiveActivations({})
    });
  }

  handleSearchAffiliatedByIdentifier = (identifier_search_affiliated) => {
    this.setState({identifier_search_affiliated, isLoadingAffiliatedActivations:true}, () => {
        this.getAffiliatedActivationsPaginated({})
    });
  }


  render(){
    const {instructionOpen, counter, showButton, activationToInform, partnerName, identifierNeeded, informIdentifierOpen, vertical, horizontal, snackbarOpen, snackMessage, isUpdatingActivation, isLoadingActiveActivations, isLoadingAffiliatedActivations} = this.state
    const { auth, nonActiveActivations, activeActivations } = this.props;
  
    let activations_benefits = []
    let nonactive_activations_benefits = []

    if(activeActivations.docs){
      activations_benefits = activeActivations.docs.map((a) => {
        if(!['INACTIVE', 'CANCELLED'].includes(a.status)) {
          return a.benefit._id
        }
        return false
      });
    }

    if(nonActiveActivations.docs){
      nonactive_activations_benefits = nonActiveActivations.docs.map((a) => {
        if(!['INACTIVE', 'CANCELLED'].includes(a.status)) {
          return a.benefit._id
        }
        return false
      });
    }

    return(
      <div id="benefits-section">
        
        <Grid container spacing={3}>
          {
            this.props.nonActiveActivations.docs && this.props.nonActiveActivations.docs.length > 0 && (
              <Grid item xs={12}>
                <Paper elevation={3} style={{ padding: 16 }}>
                  <BasicTitle margin='0 0 16px' color='#deaa0d'>Suas Pendências</BasicTitle>
                  <Grid container spacing={3} >
                    {
                      this.props.nonActiveActivations.docs.map(a=>{
                        return(
                          <Grid key={a._id} item xs={12} sm={6} md={3}>
                              <ActivationElement activation={a} handleOpenInformIdentifier={this.handleOpenInformIdentifier} />
                          </Grid> 
                        )  
                      })
                    }
                    <div style={{width:'100%'}}>
                      <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={this.state.currentPageNonActive} count={this.state.totalPagesNonActive} color="standard" onChange={this.handlePaginateNonActive} /> 
                    </div>
                  </Grid>
                </Paper>
              </Grid>  
            )
          }
          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: 16 }}>
              <BasicTitle margin='0 0 16px' color='#deaa0d'>Benefícios Disponíveis</BasicTitle>
                <Grid container spacing={3} >
                  {
                    this.props.benefits.map((b) => {
                      if(auth.extra_accounts === "1") {
                        return(
                          <Grid key={b._id} item xs={12} sm={6} md={3}>
                            <BenefitElement benefit={b} handleGetBenefit={this.handleOpenInstructions} />
                          </Grid>
                        )
                      } else {
                        if(!activations_benefits.includes(b._id) && !nonactive_activations_benefits.includes(b._id)) {
                          return(
                            <Grid key={b._id} item xs={12} sm={6} md={3}>
                              <BenefitElement benefit={b} handleGetBenefit={this.handleOpenInstructions} />
                            </Grid>
                          )
                        }
                        return null
                      }
                    })
                  }
              </Grid>
            </Paper>
          </Grid>  
          <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: 16 }}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={8} md={9}>
                <BasicTitle margin='0 0 16px'>Benefícios Ativos</BasicTitle>
              </Grid>
              <Grid style={{textAlign: 'right'}} item xs={6} sm={4} md={3}>
                {
                    this.state.activationsCSV && (
                      <CSVLink 
                        style={{textDecoration: 'none'}} 
                        data={this.state.activationsCSV}
                        filename={`report-rakeback-sensei.csv`}
                        >
                        <Button style={{background: "#e6e6e6", color: '#a2a2a2'}}>
                          <GetAppIcon/>
                        </Button>
                      </CSVLink>
                    )
                  }
              </Grid>
              <Grid item xs={12} sm={6}>
                <IdentifierSearch handleSearch={this.handleSearchActiveByIdentifier} />
              </Grid>
              <Grid item xs={12}>
                {
                  isLoadingActiveActivations ? (
                    <CircularProgress/>
                  ) : (
                    <div>
                      <ActivationsList docs={this.props.activeActivations.docs} totalResults={this.state.totalResults} />
                      <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={this.state.currentPageActive} count={this.state.totalPagesActive} color="standard" onChange={this.handlePaginate} />
                    </div>
                  )
                }
              </Grid>
              {
                this.props.activeActivations.length === 0 && !isLoadingActiveActivations && (
                  <div style={{background: '#dedede', padding: '16px', width:'100%'}}>
                    <Typography variant='body1' style={{color:'#444'}}>Ainda não há benefícios ativos. Ative o seu primeiro benefício.</Typography>
                  </div>
                  )
              }
            </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: 16 }}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={8} md={9}>
                <BasicTitle margin='0 0 16px' color='#b33e3e'>Benefícios Afiliados</BasicTitle>
              </Grid>
              <Grid style={{textAlign: 'right'}} item xs={6} sm={4} md={3}>
                {
                    this.state.affiliatedActivationsCSV && (
                      <CSVLink 
                        style={{textDecoration: 'none'}} 
                        data={this.state.affiliatedActivationsCSV}
                        filename={`report-affiliated-rakeback-sensei.csv`}
                        >
                        <Button style={{background: "#e6e6e6", color: '#a2a2a2'}}>
                          <GetAppIcon/>
                        </Button>
                      </CSVLink>
                    )
                  }
              </Grid>
              <Grid item xs={12} sm={6}>
                <IdentifierSearch handleSearch={this.handleSearchAffiliatedByIdentifier} />
              </Grid>
              <Grid item xs={12}>
                
                {
                  isLoadingAffiliatedActivations ? (
                    <CircularProgress/>
                  ) : (
                    <div>
                      <AffiliatedActivationsList docs={this.props.affiliatedPaginated.docs} totalAffiliatedResults={this.state.totalAffiliatedResults} />
                      <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={this.state.currentPageAffiliated} count={this.state.totalPagesAffiliated} color="standard" onChange={this.handlePaginateAffiliated} />
                    </div>
                  )
                }
              </Grid>
              {
                this.props.affiliatedPaginated.docs && this.props.affiliatedPaginated.docs.length === 0 && (
                  <div style={{background: '#dedede', padding: '16px', width:'100%'}}>
                    <Typography variant='body1' style={{color:'#444'}}>Você ainda não recebe dinheiro de nenhuma afiliação. <Link to='/account'>Clique aqui</Link> para pegar seu código e compartilhar com os amigos </Typography>
                  </div>
                  )
              }
            </Grid>
            </Paper>
          </Grid>
          
        </Grid>
        
        <BenefitActivationInstructions open={instructionOpen} showButton={showButton} partnerName={partnerName} identifierNeeded={identifierNeeded} counter={counter} handleCloseInstructions={this.handleCloseInstructions} />
        <ActivationInformIdentifier open={informIdentifierOpen} activation={activationToInform} counter={counter} isFetching={isUpdatingActivation} handleSubmitInformIdentifier={this.handleSubmitInformIdentifier} onClose={this.handleCloseInformIdentifier}/>
        <Snackbar
                  anchorOrigin={{ vertical, horizontal }}
                  key={`${vertical},${horizontal}`}
                  open={snackbarOpen}
                  onClose={this.handleClose}
                  ContentProps={{
                    'aria-describedby': 'message-id',
                  }}
                message={<span id="message-id">{snackMessage}</span>}
                />
      </div>
    )
  }
}

const mapStateToProps = ({ benefits, activations, activeActivations, affiliatedActivations, affiliatedPaginated, nonActiveActivations, signUpInfo, auth }) => {
  return {
    benefits,
    activations,
    activeActivations,
    nonActiveActivations,
    affiliatedActivations,
    affiliatedPaginated,
    signUpInfo,
    auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSignUpInfo: (info) => dispatch(setSignUpInfoData(info)),
    getAllBenefits: () => dispatch(getBenefits()),
    getNonActiveActivations: () => dispatch(getNonActiveActivations()),
    getActiveActivations: (query) => dispatch(getActiveActivations(query)),
    getAllAffiliatedActivations: () => dispatch(getAffiliatedActivations()),
    getAffiliatedPaginated: (query) => dispatch(getAffiliatedPaginated(query)),
    createActivation: (body) => dispatch(createActivation(body)),
    updateActivation: (activationId, identifierValue) => dispatch(updateActivation(activationId, identifierValue))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsList)