        
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import SenseiCoin from '../common/SenseiCoin'

class OrdersList extends Component {
  
  render() {
    const { docs = [], isLoading } = this.props;
   
    return (
        <div>
            <Grid container spacing={2} alignItems="center" style={{ marginTop: 16, marginBottom: 8, borderBottom: '1px solid #dedede' }}>
                      <Grid item xs={2}><Typography variant="caption">Mês/Ano</Typography></Grid>
                      <Grid item xs={2}><Typography variant="caption">Plataforma</Typography></Grid>
                      <Grid item xs={2}><Typography variant="caption">Identificador</Typography></Grid>
                      <Grid item xs={2}><Typography variant="caption">Gerado</Typography></Grid>
                      <Grid item xs={2} style={{ textAlign: 'right'}}><Typography variant="caption"><SenseiCoin /> Valor </Typography></Grid>
            </Grid>
             { docs.length ? docs.map(result => (
                  <div key={result._id}>
                      <Grid container spacing={1}  alignItems="center"  style={{ borderBottom: '1px solid #ededed' }}>
                      <Grid item xs={2}>
                          <Typography variant='caption'><b>{moment(result.result.registry_date).utc().format('MM/YYYY')}</b></Typography>
                      </Grid>
                      <Grid item xs={2}>
                          <Typography variant='caption'><b>{result.activation.benefit.partner.name}</b></Typography>
                      </Grid>
                      <Grid item xs={2}>
                          <Typography variant='caption'><b>{result.activation.identifier_needed_value}</b></Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="caption">
                            <b><NumberFormat 
                            value={ result.result.generated/100} 
                            displayType={'text'} 
                            decimalScale={2}
                            prefix="$"
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale="true"
                            style={{ marginLeft: 8 }}
                            /></b>
                        </Typography>
                      </Grid>
                      
                      <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <Typography variant="body1">
                            <SenseiCoin />
                            <b><NumberFormat 
                            value={ (result.value)/100} 
                            displayType={'text'} 
                            decimalScale={2}
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale="true"
                            style={{ marginLeft: 8 }}
                            /></b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
            )) : 
            <Grid container>  
              <Grid item>{isLoading ? 'Carregando...' : 'Não há registros.'}</Grid>
            </Grid> }
        </div>
    )
  }
}


export default withRouter(OrdersList)

      