import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';


const BasicTitle = ({margin='0', color='#3C9A68', children }) => {

    return (
      <Container style={{ paddingLeft:'0', paddingRight:'0', margin: margin }}>
        <Grid container alignItems="center">
          <Grid item style={{ height:'24px' }}>
            <div style={{height:'24px', width:'5px', background: color, display:'inline-block', marginRight:'10px', borderRadius:'3px'}}></div>
          </Grid>
          <Grid item style={{ height:'18px' }}>
            <Typography style={{color: "#444", fontWeight: "400", fontSize: '20px', fontFamily:'merriweather', lineHeight:'1'}} variant="h5">
            {children}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    )

}

export default BasicTitle;