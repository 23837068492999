import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  main: {
    backgroundColor: '#3C9A68',
    color: "white",
    fontWeight: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    width: '100%',
    height: 35,
    borderRadius: "0 0 5px 5px",
    fontSize: 12,
    "&:hover": {
      boxShadow: '0px 4px 9px #444',
      backgroundColor: '#3C9A68'
    }
  },
  darker: {
    backgroundColor: '#286645',
    color: "white",
    fontWeight: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    width: '100%',
    height: 35,
    borderRadius: "0 0 5px 5px",
    fontSize: 12,
    "&:hover": {
      boxShadow: '0px 4px 9px #444',
      backgroundColor: '#286645'
    }
  },
  yellow: {
    backgroundColor: '#a97f00',
    color: "white",
    fontWeight: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    width: '100%',
    height: 35,
    borderRadius: "0 0 5px 5px",
    fontSize: 12,
    "&:hover": {
      boxShadow: '0px 4px 9px #444',
      backgroundColor: '#286645'
    }
  }
})

const BottomButton = ({classes, children, type, styleType, handleClick, disabled}) => {

    return (
      <Button
        className={
          styleType ? classes[styleType] : classes.main
        }
        variant="contained"
        fullWidth
        disabled={disabled}
        onClick={handleClick}
        type={type ? type: "submit"}
        margin="normal">
          {children}
      </Button>
    )

}

export default withStyles(styles)(BottomButton);