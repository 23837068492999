class Auth {

    static storeInfoForValidation(sub, email) {
        localStorage.setItem('userSub', sub)
        localStorage.setItem('validationEmail', email)
    }

    static getValidationEmail() {
        return localStorage.getItem('validationEmail')
    }

  }
  
  export default Auth
  