import React, { Component } from 'react'
import BasicPageLayout from '../common/BasicPageLayout';
import BasicTitle from '../common/BasicTitle'
import { getStatementFetchData } from '../wallet/actions'
import { Grid, Card, CardContent } from '@material-ui/core';
import StatementList from '../wallet/StatementList'
import Pagination from '@material-ui/lab/Pagination';
import { connect } from 'react-redux'
import moment from 'moment'
import DateSearch from '../common/DateSearch';
import ReactGA from 'react-ga';

class Statement extends Component {

  state = {
    created_at: { startDate: moment().subtract(30, 'years').toISOString(), endDate: moment().toISOString() },
  }

  componentDidMount() { 
    if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } 
    const { created_at } = this.state
    const { startDate, endDate } = created_at
    this.props.getStatement({ startDate, endDate })
  }

  handlePaginate = (event, value) => {
    const { statement } = this.props
    const { page } = statement
    const { created_at } = this.state
    const { startDate, endDate } = created_at
    const query = {
      page: value || page,
      startDate,
      endDate
    }
    
    this.props.getStatement(query)
  }

  handleSearchByDate = (created_at) => {
    this.setState({created_at}, () => {
        this.handlePaginate();
    });
  }

  render() {

    const { statement } = this.props
    const { docs = [], totalPages = 1, page = 1 } = statement

    return (
      <BasicPageLayout selectedMenu='statement'>
        <BasicTitle>Extrato</BasicTitle>
        <Grid container spacing={3} style={{ marginTop: 16 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid>
                  <Grid>
                    <DateSearch handleSearch={this.handleSearchByDate}/>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <StatementList docs={docs}/>
                  </Grid>
                  <Grid item style={{ textAlign: 'center' }} xs={12}>
                    <div>
                      <Pagination style={{ margin: '16px auto', display: 'inline-block' }} page={page} count={totalPages} onChange={this.handlePaginate} />
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        
      </BasicPageLayout>
    )
  }
}


const mapStateToProps = ({ auth, statement, balance }) => {
  return {
    auth,
    statement,
    balance
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getStatement: (sale) => dispatch(getStatementFetchData(sale))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Statement)