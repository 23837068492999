import React, {Component} from 'react'
import BottomButton from '../common/BottomButton'
import SenseiCoin from '../common/SenseiCoin'
import GreenTitle from '../common/GreenTitle'
import GreenDivider from '../common/GreenDivider'
import StyledParagraph from '../common/StyledParagraph'
import ErrorIcon from '@material-ui/icons/Error'
import NumberFormat from 'react-number-format'
import {connect} from 'react-redux'

import moment from 'moment'
import { Paper, Typography, Grid, Box, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import BasicButton from '../common/BasicButton'
import {cancelActivation} from './actions'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import {getNonActiveActivations, getBenefits} from './actions'

const styles = {
  rootPending: {
    background:'white',
    color:'#286645'
  },
  rootMissingIdentifier: {
    background:'white',
    color:'#d47500',
    boxShadow: '0px 0px 40px #e4ac0c',
    animation: `$shakeAnimation 0.3s ease-in-out 0.1s alternate infinite`,
    transform: 'translate(-50%,-50%)'
  },
  rootDenied: {
    background:'white',
    color:'#f44336'
  },
  button: {
    background: '#286645'
  },
  titleDiv: {
    padding: '16px 16px 0',
    display: 'flex',
    alignItems: 'center'
  },
  activeTitleDiv: {
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    background: '#3C9A68',
    borderRadius: "4px 4px 0px 0px",
    color: "white"
  },
  activeTitle: {
    fontSize: '12px',
    fontFamily: 'merriweather',
    fontWeight: '600',
    color: '#4D4D4D'
  },
  activeValue: {
    fontSize: '22px',
    fontWeight: '600',
    color: '#4D4D4D'
  },
  title: {
    fontSize: '15px',
    fontFamily: 'merriweather',
    fontWeight: '600'
  },
  registryDate: {
    fontSize: '12px',
    marginBottom: '8px'
  },
  identifier: {
    fontSize: '12px',
  },
  status: {
    fontSize: '8px',
    padding: '2px 0',
    textAlign: 'center',
    background: '#dedede',
    borderRadius: '10px',
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  descriptionDiv: {
    padding:'16px 16px 0',
    textAlign: 'left'
  },
  description: {
    fontSize: '12px'
  },
  ninjaIcon: {
    marginRight: '16px',
    width: '46px'
  },
  "@keyframes shakeAnimation": {
    from: {
      '-webkit-transform': 'rotate(1deg)'
   },
   to: {
     '-webkit-transform-origin':'center center',
     '-webkit-transform': 'rotate(-1deg)'
   }
  }
}

class ActivationElement extends Component {
  state = {
    cancelActivationOpen: false,
    isCanceling: false
  }

  handleCancelActivation = (id) => {
    this.setState({isCanceling: true})
    cancelActivation(id)
    .then(r=>{
      this.setState({isCanceling: false})
      this.handleCloseCancelActivation()
      this.props.getNonActiveActivations({})
      this.props.getAllBenefits()
    })
    .catch(err=>{
      this.setState({
        isCanceling: false,
        snackBarOpen: true,
        snackMessage: 'Erro ao cancelar ativação'
      })
    })
  }

  handleOpenCancelActivation = () => {
    this.setState({cancelActivationOpen: true})
  }

  handleCloseCancelActivation = () => {
    this.setState({cancelActivationOpen: false})
  }

  render() {

    const {activation, handleOpenInformIdentifier, classes} = this.props
    const {cancelActivationOpen} = this.state

    let statusPortugese
    let rootClass
    let buttonText
    let buttonDisabled = false
    
    switch(activation.status){
      case 'ACTIVE':
        statusPortugese = 'ATIVO'
        rootClass = classes.rootPending
        buttonText='Ativo'
        buttonDisabled=true
        break;
      case 'PENDING':
        statusPortugese = 'EM ANÁLISE'
        rootClass = classes.rootPending
        buttonText='Em análise'
        buttonDisabled=true
        break;
      case 'VERIFING':
        statusPortugese = 'EM ANÁLISE'
        rootClass = classes.rootPending
        buttonText='Em análise'
        buttonDisabled=true
        break;
      case 'MISSING_IDENTIFIER':
        statusPortugese = `INFORME O ${activation.benefit.identifier_needed}`
        rootClass = classes.rootMissingIdentifier
        buttonText=`INFORMAR O ${activation.benefit.identifier_needed}`
      break;
      case 'INACTIVE':
        statusPortugese = `AÇÃO PENDENTE`
        rootClass = classes.rootDenied
        buttonText=`ATUALIZAR ${activation.benefit.identifier_needed}`
      break;
      default:
        statusPortugese = ''
        buttonDisabled=true
        buttonText='PEGAR O BENEFÍCIO'
    }
  
    return(
      <Paper elevation={3} className={rootClass}>
  
        { activation.status === 'ACTIVE' ? 
        <Box>
            <div className={classes.activeTitleDiv}>
              <img className={classes.ninjaIcon} alt='troféu' src={process.env.PUBLIC_URL + '/ic_cards.svg'}/>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant='body1' className={classes.title}>{activation.benefit.partner.name}</Typography>
                </Grid>
                {
                  activation.identifier_needed_value && (
                    <Grid item xs={12}>
                      <Typography variant='body1' className={classes.identifier}>({activation.identifier_needed_value})</Typography>
                    </Grid>
                  )
                }
              </Grid>
            </div>
            <div style={{ padding: 16 }}>
            {
              activation.last_result ? (
                <Grid item xs={12}>
                  <Typography variant='body1' className={classes.registryDate}> Último resultado ({moment(activation.last_result.registry_date).format('MM/YYYY')})</Typography>
                </Grid>
              ) : (
                <Grid item xs={12}>
                    <Typography variant='body1' className={classes.registryDate}> Nenhum resultado lançado</Typography>
                  </Grid>
              )
            } 
              <Typography className={classes.activeValue}><NumberFormat 
                              value={activation.last_result ? activation.last_result.rakeback_value/100 : 0} 
                              displayType={'text'} 
                              thousandSeparator="."
                              decimalSeparator=","
                              fixedDecimalScale="true"
                              /><SenseiCoin margin='0 0 0 8px'/></Typography>
            </div>
        </Box>
        :
        
        <Box>
          <div className={classes.titleDiv}>
            <img className={classes.ninjaIcon} alt='troféu' src={process.env.PUBLIC_URL + '/illustration_level_iniciante.svg'}/>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='body1' className={classes.title}>{activation.benefit.partner.name}</Typography>
              </Grid>
              {
                  activation.identifier_needed_value && (
                    <Grid item xs={12}>
                      <Typography variant='body1' className={classes.registryDate}>({activation.identifier_needed_value})</Typography>
                    </Grid>
                  )
                }
              <Grid item xs={12}>
                <Typography variant='body1' className={classes.status}>{statusPortugese}</Typography>
              </Grid>
            </Grid>
  
            <div style={{cursor:'pointer', marginLeft:'16px'}} onClick={this.handleOpenCancelActivation}>
              <CancelIcon style={{width:'16px'}} />
            </div>
          </div>
          <div className={classes.descriptionDiv}>
            <Typography className={classes.description} variant='body1'>{activation.benefit.description}</Typography>
            { ['PENDING', 'MISSING_IDENTIFIER', 'VERIFING'].includes(activation.status) && <Grid container style={{marginTop: 16}} alignItems='center'>
                {
                  activation.status === 'MISSING_IDENTIFIER' && (
                    <Grid item xs={12} style={{marginBottom:8}}>
                        <Grid container style={{color: "#444"}}>
                          <Grid item xs={2}>
                            <LinkOutlinedIcon style={{}}/>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography style={{overflowWrap:'break-word', fontSize:10}} variant='body1'>{activation.benefit.link}</Typography>
                          </Grid>
                        </Grid>
                    </Grid>
                  )
                }
                <Grid item xs={2}>
                  <ReportProblemOutlinedIcon style={{color:'#b57500'}}/>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.description} style={{ fontWeight: 'bold', color: '#b57500', fontSize: 10 }} variant="body1">Aguarde nossa confirmação antes de começar a jogar na {activation.benefit.partner.name}</Typography>
                </Grid>
                
                {
                  activation.admin_information && activation.admin_information !== '' && (
                    <Grid item xs={12}>
                      <hr></hr>
                      <Grid container>
                        <Grid item xs={2}>
                          <NotificationsActiveIcon style={{color:'#dc4646'}}/>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography className={classes.description} style={{ color: '#444', fontSize: 10 }} variant="body1"><b style={{fontWeight: 'bold',}} >update: </b>{activation.admin_information}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }

              </Grid>  
            }
          </div>
          {
            activation.status === "INACTIVE" && (
              <div style={{padding: '8px',background: '#bf1111', color:'white', borderRadius: '5px', margin: '8px 16px 0'}}>
                <Grid container>
                  <Grid item xs={2}>
                    <ErrorIcon style={{height: '100%'}}/>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant='body1' style={{fontSize: '12px'}}> <strong>{activation.deny_justification}</strong></Typography>
                  </Grid>
                </Grid>
              </div>
            )
          }
          <BottomButton 
            type="button" 
            handleClick={()=>{
              handleOpenInformIdentifier(activation)
            }} 
            styleType='darker' 
            disabled={buttonDisabled}
          >
            {buttonText}
          </BottomButton>
        </Box> 
      }
      <Dialog 
        PaperProps={{
          style: {
            padding: '32px'
          }
        }}
        open={cancelActivationOpen}
        onClose={this.handleCloseCancelActivation}>
        <GreenTitle padding="20px 20px 0 20px" textAlign='center'>
          CANCELAR ATIVAÇÃO - {activation.benefit.partner.name}
        </GreenTitle>
        <GreenDivider marginLeft='auto' marginRight='auto'/>
        <StyledParagraph textAlign="center" padding='0 20px'>Ao cancelar esta ativação, ela não aparecerá mais para você. </StyledParagraph>
        <Grid container spacing={3}>
          <Grid item xs={6}>
          <BasicButton handleClick={()=>this.handleCancelActivation(activation._id)} styleType='red'>{ this.state.isCanceling && <CircularProgress/>}CANCELAR ATIVAÇÃO</BasicButton>
          </Grid>
          <Grid item xs={6}>
            <BasicButton handleClick={this.handleCloseCancelActivation} styleType='gray'>NÃO CANCELAR</BasicButton>
          </Grid>
        </Grid>
      </Dialog>
      </Paper>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNonActiveActivations: () => dispatch(getNonActiveActivations()),
    getAllBenefits: () => dispatch(getBenefits()),
  }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ActivationElement))