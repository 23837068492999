import React from 'react'
import LocalActivityIcon from '@material-ui/icons/LocalActivity';


const TicketIcon = ({ margin = '0', width = '30px', height = '20px' }) => {
    return (
        <LocalActivityIcon style={{ width, height, margin, color: '#d48222' }} />
    )

}

export default TicketIcon;