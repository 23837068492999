import React, { Component } from 'react'
import BasicPageLayout from '../common/BasicPageLayout';
import BasicTitle from '../common/BasicTitle'
import { Typography } from '@material-ui/core';
import FrequentQuestions from '../common/FAQ';
import ReactGA from 'react-ga';


class Support extends Component {
  async componentDidMount() {
    if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } 
  }
  render() {
    localStorage.setItem('firstLogin', false)
    return (
      <BasicPageLayout selectedMenu='support'>
        <BasicTitle margin='0 0 16px'>Suporte</BasicTitle>
        <Typography variant='body1'>Procure pelo seu problema nas nossas perguntas frequentes ou <span><b>fale conosco pelo chat no canto inferior direito da página</b></span></Typography>
        <FrequentQuestions dashboard={true}/>
      </BasicPageLayout>
    )
  }
}


export default Support