import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import BarChartIcon from '@material-ui/icons/BarChart';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// import SenseiCoinBW from './SenseiCoinBW';
// import SportsEsportsOutlinedIcon from '@material-ui/icons/SportsEsportsOutlined';
import StorefrontIcon from '@material-ui/icons/Storefront';
import Collapse from '@material-ui/core/Collapse';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import SchoolIcon from '@material-ui/icons/School';
import { Divider } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({

  itemTitle: {
    fontSize: '12px',
    color: '#444',
    textTransform: 'uppercase'
  },
  nestedItemTitle: {
    fontSize: '12px',
    color: '#444',
    paddingLeft: 8
  },
  menuIcon: {
    width: '15px',
    position: 'absolute',
    top: '16px',
    right: '16px'
  }
}));

const MenuList = (props) => {

  const classes = useStyles();

  const router = useHistory();

  return (
    <List>
      <ListItem button selected={props.selected === 'dashboard'} onClick={() => router.push('/dashboard')} style={{ margin: '10px 0' }}>
        <ListItemIcon ><BarChartIcon /> </ListItemIcon>
        <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>DASHBOARD</Typography>} />
      </ListItem>

      <ListItem selected={props.selected === 'statement'} button onClick={() => router.push('/statement')} style={{ margin: '10px 0' }}>
        <ListItemIcon ><ReceiptOutlinedIcon /> </ListItemIcon>
        <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>EXTRATO</Typography>} />
      </ListItem>
      <ListItem selected={props.selected === 'results'} button onClick={() => router.push('/results')} style={{ margin: '10px 0' }}>
        <ListItemIcon ><TrendingUpIcon /> </ListItemIcon>
        <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>RESULTADOS</Typography>} />
      </ListItem>
      <ListItem selected={props.selected === 'account'} button onClick={() => router.push('/account')} style={{ margin: '10px 0' }}>
        <ListItemIcon ><SettingsOutlinedIcon /> </ListItemIcon>
        <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>MINHA CONTA</Typography>} />
      </ListItem>


      {/* <ListItem selected={props.selected === 'how-it-works'} button onClick={()=>router.push('/how-it-works')} style={localStorage.getItem('firstLogin')==='true' ? {background: '#5454de', color:'#fff', margin: '10px 0'} : {margin: '10px 0'}}>
        <ListItemIcon ><SportsEsportsOutlinedIcon style={localStorage.getItem('firstLogin')==='true' ? {color:'#fff'} : {}}/> </ListItemIcon>
        <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle} style={localStorage.getItem('firstLogin')==='true' ? {color:'#fff'} : {}}>COMO FUNCIONA?</Typography>} />
      </ListItem> */}
      <ListItem selected={props.selected === 'support'} button onClick={() => router.push('/support')} style={{ margin: '10px 0' }}>
        <ListItemIcon ><HelpOutlineIcon /> </ListItemIcon>
        <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>AJUDA</Typography>} />
      </ListItem>
      <ListItem button selected={props.selected === 'store' || props.selected === 'store_orders' || props.selected === 'product_categories'} onClick={() => router.push('/store')} style={{ margin: '10px 0 0' }}>
        <ListItemIcon ><StorefrontIcon /> </ListItemIcon>
        <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>Loja</Typography>} />
        {/* <ChevronRightIcon /> */}
      </ListItem>
      <Collapse style={{ background: props.selected === 'store' || props.selected === 'store_orders' ? 'rgba(0, 0, 0, 0.08)' : 'white' }} in={props.selected === 'store' || props.selected === 'store_orders'} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button>
            <ListItemText primary={<Typography className={classes.nestedItemTitle} style={{ fontWeight: props.selected === 'store_orders' ? 'bold' : 'normal' }} onClick={() => router.push('/store/my-orders')}>• Meus Pedidos</Typography>} />
          </ListItem>
        </List>
      </Collapse>
      <Divider />
      <Typography variant="caption" style={{ paddingTop: 8, display: 'block', textAlign: 'center', color: '#559573', fontWeight: '600' }}>Conteúdo exclusivo para membros</Typography>
      <ListItem selected={props.selected === 'school'} button onClick={() => router.push('/school')} style={{ margin: '10px 0' }}>
        <ListItemIcon ><SchoolIcon /> </ListItemIcon>
        <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>ESCOLA</Typography>} />
      </ListItem>
      <ListItem selected={props.selected === 'raffle'} button onClick={() => router.push('/raffle')} style={{ margin: '10px 0' }}>
        <ListItemIcon ><DataUsageIcon /> </ListItemIcon>
        <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>SORTEIO</Typography>} />
      </ListItem>
      <a href="https://discord.io/senseipoker" target="_blank"  rel="noopener noreferrer">
        <ListItem selected={props.selected === 'support'} button style={{ margin: '10px 0' }}>
          <ListItemIcon ><ChatBubbleIcon /> </ListItemIcon>
          <ListItemText className={classes.itemTitle} primary={<Typography className={classes.itemTitle}>DISCORD</Typography>} />
        </ListItem>
      </a>
      <Divider />
    </List>
  )
}

export default MenuList